import { AmplitudeEvent, Caption, IUser, UserID } from "byrdhouse-types";
import { useCallback, useState } from "react";
import { FiDownload } from "react-icons/fi";
import useLayout from "../../hooks/layout";
import { useLanguageLocaleDisplayNames } from "../../hooks/transcript";
import Button from "../Button";
import "./DownloadTranscriptModal.css";
import { Document as DocxDocument, Packer, Paragraph, TextRun } from "docx";
import amplitude from "../../amplitude";

const DownloadTranscriptModal = () => {
  const [layout] = useLayout();

  const languageLocalDisplayNames = useLanguageLocaleDisplayNames();

  const users = layout.modalParams.users as { [id: UserID]: IUser } | undefined;
  const languages = layout.modalParams.languages as string[] | undefined;
  const captions = layout.modalParams.captions as Caption[] | undefined;

  const [language, setLanguage] = useState<string | undefined>(
    languages?.length ? languages[0] : undefined
  );
  const onChangeLanguage = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setLanguage(event.target.value);
    },
    []
  );

  const getText = useCallback(() => {
    if (users && language && captions) {
      const text = captions
        // Filter for users
        .filter((caption) => !!users[caption.from])
        // Filter for selected language
        .filter((caption) => !!caption.translations[language])
        .sort(
          (c1, c2) =>
            new Date(c1.timestamp).getTime() - new Date(c2.timestamp).getTime()
        )
        .map(
          (caption) =>
            `${users[caption.from].displayName} (${new Date(
              caption.timestamp
            ).toLocaleDateString()} ${new Date(
              caption.timestamp
            ).toLocaleTimeString()}): ${caption.translations[language]}`
        )
        .join("\n");
      return text;
    }
  }, [users, language, captions]);

  const download = useCallback(async () => {
    amplitude?.logEvent(
      AmplitudeEvent.DOWNLOAD_MEETING_NOTES_SPECIFIC_RECORDING,
      { language }
    );
    const file = new DocxDocument({
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              children: (getText() || "")
                .split("\n")
                .map((text) => new TextRun({ text, break: 1 })),
            }),
          ],
        },
      ],
    });

    const blob = await Packer.toBlob(file);
    const el = document.createElement("a");
    el.hidden = true;
    el.href = URL.createObjectURL(blob);
    el.download = "transcript.docx";
    document.body.appendChild(el);
    el.click();
  }, [getText, language]);

  return (
    <div className="DownloadTranscriptModal">
      <div>Please select the language of your meeting notes</div>
      <select onChange={onChangeLanguage} value={language}>
        {languages &&
          languages.map((language) => (
            <option key={language} value={language}>
              {languageLocalDisplayNames.of(language)}
            </option>
          ))}
      </select>
      <Button
        text={
          <div>
            <FiDownload />
            <span>Download</span>
          </div>
        }
        onClick={download}
      />
    </div>
  );
};

export default DownloadTranscriptModal;
