import AgoraRTC, { ILocalVideoTrack } from "agora-rtc-sdk-ng";
import {
  AmplitudeEvent,
  MuteParticipantArgs,
  Participant,
  ReassignHostArgs,
  RemoveParticipantArgs,
  RoomID,
  SCREEN_SHARE_PREFIX,
  SendJoinRoomArgs,
  SessionID,
  StartRecordingArgs,
  StopRecordingArgs,
} from "byrdhouse-types";
import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import agora, { screenShare, virtualBackground } from "../agora";
import {
  GlobalContext,
  initialCallState,
  initialChatState,
  initialTranscriptState,
} from "../context";
import useSocket from "./socket";
import useUser, { useLocalParticipant } from "./user";
import amplitude from "../amplitude";
import api from "../api";
import useChat from "./chat";
import {
  useCloseModal,
  usePipSupported,
  useTogglePipOff,
  useTogglePipOn,
} from "./layout";
import useTranscript from "./transcript";
import { toast } from "react-toastify";
import useSound from "use-sound";
import recordingStartedSound from "../assets/recording-started.mp3";
import recordingStoppedSound from "../assets/recording-stopped.mp3";
import recordingInProgressSound from "../assets/recording-in-progress.mp3";
import { LocalStorageKeys } from "../types";

const useCall = () => {
  let context = useContext(GlobalContext);
  return [context.call, context.setCall] as const;
};

export const useUpdateRemotePeer = () => {
  const [, setCall] = useCall();
  return useCallback(
    (remotePeer: Participant) => {
      setCall((prevState) => {
        return {
          ...prevState,
          remotePeers: {
            ...prevState.remotePeers,
            [remotePeer.sessionId]: {
              ...(prevState.remotePeers[remotePeer.sessionId] || {}),
              ...remotePeer,
            },
          },
        };
      });
    },
    [setCall]
  );
};

export const useRemoveRemotePeer = () => {
  const [, setCall] = useCall();
  return useCallback(
    (remotePeerID: SessionID) => {
      // Get agora user
      let remoteAgoraUser = agora.remoteUsers.find(
        (u) => u.uid === (remotePeerID as string)
      );

      // Check if remote user is screen sharing
      if (remotePeerID.includes(SCREEN_SHARE_PREFIX)) {
        remotePeerID = remotePeerID.replace(
          SCREEN_SHARE_PREFIX,
          ""
        ) as SessionID;
      }
      setCall((prevState) => {
        const { [remotePeerID]: omitRemotePeer, ...restRemotePeers } =
          prevState.remotePeers;

        // Clear and remove tracks
        const { [remotePeerID]: omitRemoteTracks, ...restRemoteTracks } =
          prevState.remoteTracks;

        // Stop the tracks locally
        omitRemoteTracks?.audioTrack?.stop();
        omitRemoteTracks?.videoTrack?.stop();

        // Unsubscribe from tracks
        if (remoteAgoraUser) {
          agora.unsubscribe(remoteAgoraUser);
        }

        // Remove from last speakers (if applicable)
        const lastSpeakers = prevState.lastSpeakers.filter(
          (id) => id !== remotePeerID
        );

        return {
          ...prevState,
          remotePeers: restRemotePeers,
          remoteTracks: restRemoteTracks,
          lastSpeakers: lastSpeakers,
        };
      });
    },
    [setCall]
  );
};

export const useJoinRoom = () => {
  const params = useParams();
  const socket = useSocket();
  const localParticipant = useLocalParticipant();
  const [call, setCall] = useCall();
  return useCallback(async () => {
    if (!localParticipant || !params.room) {
      return;
    }

    console.debug("Join room with session id", localParticipant.sessionId);

    // If in room already, leave
    if (agora.channelName) {
      await agora.leave();
    }
    if (screenShare.channelName) {
      await screenShare.leave();
    }

    // Join socket room
    const joinArgs: SendJoinRoomArgs = {
      room: params.room as RoomID,
      participant: localParticipant,
    };
    socket?.emit("join", joinArgs);

    const agoraId = localParticipant.sessionId;
    const { encodedToken } = await api.issueRTCToken({
      roomID: params.room as RoomID,
      agoraId: agoraId,
    });

    // Join the room
    await agora.join(
      process.env.REACT_APP_AGORA_APP_ID!,
      params.room,
      encodedToken,
      agoraId
    );
    // Publish local tracks to the room
    let localTracks = [];
    if (call.localMicrophoneTrack) {
      console.debug("Local microphone track published in join room...");
      localTracks.push(call.localMicrophoneTrack);
    }
    if (call.localCameraTrack && call.localCameraTrack.enabled) {
      console.debug("Local camera track published in join room...");
      localTracks.push(call.localCameraTrack);
    }
    if (localTracks.length) {
      await agora.publish(localTracks);
    }

    // Join the room with screen share (if exists)
    if (call.localScreenShareTrack) {
      const agoraId = `${SCREEN_SHARE_PREFIX}${localParticipant.sessionId}`;
      const { encodedToken } = await api.issueRTCToken({
        roomID: params.room as RoomID,
        agoraId: agoraId,
      });
      await screenShare.join(
        process.env.REACT_APP_AGORA_APP_ID!,
        params.room,
        encodedToken,
        agoraId
      );
      await screenShare.publish(call.localScreenShareTrack);
    }

    setCall((prevState) => ({
      ...prevState,
      state: "connected",
    }));
  }, [
    params.room,
    localParticipant,
    socket,
    call.localCameraTrack,
    call.localMicrophoneTrack,
    call.localScreenShareTrack,
    setCall,
  ]);
};

// Host actions
export const useRenderHostControls = () => {
  const [call] = useCall();
  return useCallback(
    (user: Participant) => {
      return (
        user.userId === call.host && Object.values(call.remotePeers).length > 1
      );
    },
    [call.remotePeers, call.host]
  );
};

export const useOpenHostControls = () => {
  const [, setCall] = useCall();
  return useCallback(() => {
    amplitude?.logEvent(AmplitudeEvent.CLICK_ON_HOST);
    setCall((prevState) => ({
      ...prevState,
      showHostControls: "controls",
    }));
  }, [setCall]);
};

export const useCloseHostControls = () => {
  const [, setCall] = useCall();
  return useCallback(() => {
    setCall((prevState) => ({
      ...prevState,
      showHostControls: undefined,
    }));
  }, [setCall]);
};

export const useOpenConfirmHostAction = () => {
  const [, setCall] = useCall();
  return useCallback(
    (
      showHostControlsAfter: boolean,
      participant: Participant,
      action: string,
      callback: () => void
    ) => {
      setCall((prevState) => ({
        ...prevState,
        showHostControls: "confirm",
        confirmHostAction: {
          showHostControlsAfter: showHostControlsAfter,
          participant: participant,
          action: action,
          callback: callback,
        },
      }));
    },
    [setCall]
  );
};

export const useCloseConfirmHostAction = () => {
  const [, setCall] = useCall();
  return useCallback(() => {
    setCall((prevState) => {
      let showHostControls =
        !!prevState.confirmHostAction?.showHostControlsAfter;
      return {
        ...prevState,
        showHostControls: showHostControls ? "controls" : undefined,
        confirmHostAction: undefined,
      };
    });
  }, [setCall]);
};

export const useRemoveUser = () => {
  const [user] = useUser();
  const [call] = useCall();
  const socket = useSocket();
  const params = useParams();
  const removeRemotePeer = useRemoveRemotePeer();
  const openConfirmHostAction = useOpenConfirmHostAction();
  const closeConfirmHostAction = useCloseConfirmHostAction();

  return useCallback(
    (participant: Participant, showHostControlsAfter: boolean) => {
      if (user && user.id === call.host) {
        openConfirmHostAction(
          showHostControlsAfter,
          participant,
          "remove",
          () => {
            if (params.room) {
              let args: RemoveParticipantArgs = {
                room: params.room as RoomID,
                participant: participant,
              };
              socket?.emit("remove_user", args);
              closeConfirmHostAction();
              removeRemotePeer(participant.sessionId);
            }
          }
        );
      } else {
        removeRemotePeer(participant.sessionId);
      }
    },
    [
      user,
      call.host,
      socket,
      params.room,
      removeRemotePeer,
      openConfirmHostAction,
      closeConfirmHostAction,
    ]
  );
};

export const useMuteUser = () => {
  const socket = useSocket();
  const params = useParams();
  return useCallback(
    (participant: Participant) => {
      // Send socket message to mute to peer
      if (params.room) {
        let args: MuteParticipantArgs = {
          room: params.room as RoomID,
          participant: participant,
        };
        socket?.emit("mute_user", args);
      }
    },
    [socket, params.room]
  );
};

export const useMuteAllUsers = () => {
  const socket = useSocket();
  const params = useParams();
  return useCallback(() => {
    if (params.room) {
      let args: MuteParticipantArgs = {
        room: params.room as RoomID,
      };
      socket?.emit("mute_user", args);
    }
  }, [socket, params.room]);
};

export const useReassignHost = () => {
  const [user] = useUser();
  const [call, setCall] = useCall();
  const params = useParams();
  const socket = useSocket();
  const openConfirmHostAction = useOpenConfirmHostAction();
  const closeConfirmHostAction = useCloseConfirmHostAction();

  return useCallback(
    (newHost: Participant) => {
      if (user && user.id === call.host) {
        openConfirmHostAction(
          false,
          newHost,
          "reassign host privileges to",
          () => {
            if (params.room) {
              let args: ReassignHostArgs = {
                room: params.room as RoomID,
                participant: newHost,
              };
              socket?.emit("reassign_host", args);
            }
            setCall((prevState) => ({
              ...prevState,
              host: newHost.userId,
            }));
            closeConfirmHostAction();
          }
        );
      } else {
        setCall((prevState) => ({
          ...prevState,
          host: newHost.userId,
        }));
      }
    },
    [
      user,
      call.host,
      setCall,
      params.room,
      socket,
      openConfirmHostAction,
      closeConfirmHostAction,
    ]
  );
};

export const usePinSpeaker = (sessionId: SessionID) => {
  const [, setCall] = useCall();
  return useCallback(() => {
    setCall((prevState) => ({
      ...prevState,
      pinnedSpeaker:
        prevState.pinnedSpeaker !== sessionId ? sessionId : undefined,
    }));
  }, [setCall, sessionId]);
};

// Memoized call states

export const useNumberRemotePeers = () => {
  const [call] = useCall();
  return useMemo(() => {
    return Object.values(call.remotePeers).length;
  }, [call.remotePeers]);
};

export const useRemotePeerScreenSharing = () => {
  const [call] = useCall();
  const remotePeerScreenSharing = useMemo((): Participant | undefined => {
    let screenSharing = Object.values(call.remotePeers).filter(
      (peer) => peer.screenSharing
    );
    return screenSharing.length ? screenSharing[0] : undefined;
  }, [call.remotePeers]);

  return remotePeerScreenSharing;
};

export const useLocalOrRemoteScreenShareActive = () => {
  const [call] = useCall();
  const remotePeerScreenSharing = useRemotePeerScreenSharing();

  return useMemo(() => {
    if (call.localScreenShareTrack) {
      return !!call.localScreenShareTrack;
    }
    return !!remotePeerScreenSharing;
  }, [call.localScreenShareTrack, remotePeerScreenSharing]);
};

export const useMirrorTrack = () => {
  const [call] = useCall();
  return useMemo(() => {
    if (
      call.localCameraTrack &&
      (call.selectedVideoDeviceID || call.virtualBackground) &&
      !call.cameraMuted
    ) {
      return AgoraRTC.createCustomVideoTrack({
        mediaStreamTrack:
          call.processedVirtualTrack ||
          call.localCameraTrack.getMediaStreamTrack(),
      });
    }
  }, [
    call.localCameraTrack,
    call.selectedVideoDeviceID,
    call.virtualBackground,
    call.processedVirtualTrack,
    call.cameraMuted,
  ]);
};

// Subscribe to remote peer audio
export const useSubscribeToRemotePeerAudio = () => {
  const [, setCall] = useCall();

  return useCallback(
    async (remotePeerID: SessionID) => {
      let remoteAgoraUser = agora.remoteUsers.find(
        (u) => u.uid === (remotePeerID as string)
      );
      if (remoteAgoraUser && remoteAgoraUser.hasAudio) {
        let remoteTrack = await agora.subscribe(remoteAgoraUser, "audio");
        setCall((prevState) => {
          // If a tracks already exist, add the track to the object
          // If not create a new tracks object
          let remoteTracks = prevState.remoteTracks[remotePeerID] || {};
          remoteTracks.audioTrack = remoteTrack;

          return {
            ...prevState,
            remoteTracks: {
              ...prevState.remoteTracks,
              [remotePeerID]: remoteTracks,
            },
          };
        });
      }
    },
    [setCall]
  );
};

// Subscribe to remote peer and set their video track (show screen share if exists)
export const useSubscribeToRemotePeerVideo = () => {
  const [, setCall] = useCall();
  const remotePeerScreenSharing = useRemotePeerScreenSharing();

  return useCallback(
    async (remotePeerID: SessionID, screenShare: boolean) => {
      let agoraPeerID = remotePeerID as string;
      screenShare =
        screenShare || remotePeerScreenSharing?.sessionId === remotePeerID;
      if (screenShare) {
        agoraPeerID = `${SCREEN_SHARE_PREFIX}${remotePeerID}`;
      }

      let remoteAgoraUser = agora.remoteUsers.find(
        (u) => u.uid === (agoraPeerID as string)
      );

      console.debug(
        "Subscribe to video for remote agora user",
        remoteAgoraUser?.uid
      );

      if (
        remoteAgoraUser &&
        remoteAgoraUser.hasVideo &&
        !remoteAgoraUser.videoTrack
      ) {
        console.debug(
          "Loading video track for remote peer [screen share",
          screenShare,
          "]..."
        );
        let screenShareLoading = screenShare;
        // Set screen share loading before subscribing
        setCall((prevState) => ({
          ...prevState,
          screenShareLoading:
            screenShareLoading || prevState.screenShareLoading,
        }));
        let remoteTrack = await agora.subscribe(remoteAgoraUser, "video");
        setCall((prevState) => {
          // If a tracks already exist, add the track to the object
          // If not create a new tracks object
          let remoteTracks = prevState.remoteTracks[remotePeerID] || {};
          remoteTracks.videoTrack = remoteTrack;

          return {
            ...prevState,
            remoteTracks: {
              ...prevState.remoteTracks,
              [remotePeerID]: remoteTracks,
            },
            // Screen share finished loading (if it was set before)
            screenShareLoading: screenShareLoading
              ? false
              : prevState.screenShareLoading,
          };
        });
      }
    },
    [remotePeerScreenSharing, setCall]
  );
};

// Get the current speaker
export const useCurrentSpeaker = () => {
  const [call] = useCall();
  const remotePeerScreenSharing = useRemotePeerScreenSharing();
  const localParticipant = useLocalParticipant();

  return useMemo((): Participant => {
    // Default to local user
    let speaker: Participant = localParticipant as Participant;

    let localPin =
      call.pinnedSpeaker && localParticipant?.sessionId === call.pinnedSpeaker
        ? localParticipant
        : undefined;

    let remotePin =
      call.pinnedSpeaker && call.remotePeers[call.pinnedSpeaker]
        ? call.remotePeers[call.pinnedSpeaker]
        : undefined;

    let pinnedSpeaker = localPin || remotePin;

    let lastSpeaker =
      call.lastSpeakers[0] && call.remotePeers[call.lastSpeakers[0]]
        ? call.remotePeers[call.lastSpeakers[0]]
        : undefined;

    let remotePeer = Object.values(call.remotePeers)[0];

    // Priority of speaker streams
    // Local/remote screen share -> pinned remote speaker -> last speaker -> first remote peer -> local user camera

    if (call.localScreenShareTrack) {
      // Local user is screen sharing
      speaker = localParticipant as Participant;
    } else if (remotePeerScreenSharing) {
      // Remote peer is screen sharing
      speaker = remotePeerScreenSharing;
    } else if (pinnedSpeaker) {
      // Local user or remote peer pinned
      speaker = pinnedSpeaker;
    } else if (lastSpeaker) {
      // Last remote peer to speak
      speaker = lastSpeaker;
    } else if (remotePeer) {
      // First peer in remote peers array
      speaker = remotePeer;
    }

    return speaker;
  }, [
    call.localScreenShareTrack,
    remotePeerScreenSharing,
    call.pinnedSpeaker,
    call.lastSpeakers,
    call.remotePeers,
    localParticipant,
  ]);
};

export const useToggleMic = () => {
  const [, setCall] = useCall();
  const [, setUser] = useUser();
  return useCallback(async () => {
    setCall((prevState) => {
      const muted = !prevState.micMuted;
      prevState.localMicrophoneTrack?.setMuted(muted).then(() => {
        setUser((prevUserState) =>
          prevUserState ? { ...prevUserState, muted } : prevUserState
        );
        amplitude?.logEvent(AmplitudeEvent.TOGGLE_MIC, { muted: muted });
      });
      return { ...prevState, micMuted: muted };
    });
  }, [setCall, setUser]);
};

export const useToggleCamera = () => {
  const [, setCall] = useCall();
  return useCallback(async () => {
    setCall((prevState) => {
      const muted = !prevState.cameraMuted;
      // Wait for camera unmute to finish and send update
      prevState.localCameraTrack?.setEnabled(!muted).then(() => {
        amplitude?.logEvent(AmplitudeEvent.TOGGLE_CAMERA, { muted: muted });
        if (prevState.localCameraTrack?.enabled) {
          agora.publish(prevState.localCameraTrack);
        }
        setCall((prevState) => ({
          ...prevState,
          cameraMuted: muted,
          cameraUnMuting: false,
        }));
      });
      return { ...prevState, cameraUnMuting: !muted };
    });
  }, [setCall]);
};

export const useEndScreenShare = () => {
  const [call, setCall] = useCall();
  const [, setUser] = useUser();
  return useCallback(async () => {
    amplitude?.logEvent(AmplitudeEvent.STOP_SCREEN_SHARE);

    // Cleanup track
    call.localScreenShareTrack?.removeAllListeners();
    call.localScreenShareTrack?.close();

    // Update state
    setCall((prevState) => ({
      ...prevState,
      localScreenShareTrack: undefined,
    }));
    setUser((prevState) =>
      prevState ? { ...prevState, screenSharing: false } : prevState
    );

    // Cleanup agora
    screenShare.removeAllListeners();
    await screenShare.unpublish();
    await screenShare.leave();
  }, [call.localScreenShareTrack, setCall, setUser]);
};

export const useStartScreenShare = () => {
  const loadingScreenShare = useRef<boolean>(false);
  const [call, setCall] = useCall();
  const [, setUser] = useUser();
  const remotePeerScreenSharing = useRemotePeerScreenSharing();
  const endScreenShare = useEndScreenShare();
  const params = useParams();
  const localParticipant = useLocalParticipant();
  const togglePipOn = useTogglePipOn();

  return useCallback(async () => {
    if (remotePeerScreenSharing) {
      return;
    }

    if (!call.localScreenShareTrack && !loadingScreenShare.current) {
      loadingScreenShare.current = true;
      try {
        amplitude?.logEvent(AmplitudeEvent.START_SCREEN_SHARE);

        // Start screen share track
        const track = await AgoraRTC.createScreenVideoTrack(
          {
            optimizationMode: "detail",
            encoderConfig: "1080p_2",
          },
          "disable"
        );

        // Add ended listener to cleanup
        track.on("track-ended", () => {
          endScreenShare();
        });

        // Update state
        setCall((prevState) => ({
          ...prevState,
          localScreenShareTrack: track,
        }));
        setUser((prevState) =>
          prevState ? { ...prevState, screenSharing: true } : prevState
        );

        // Publish screen share track to agora
        if (!params.room || !localParticipant?.sessionId) {
          return;
        }
        const agoraId = `${SCREEN_SHARE_PREFIX}${localParticipant?.sessionId}`;
        const { encodedToken } = await api.issueRTCToken({
          roomID: params.room as RoomID,
          agoraId: agoraId,
        });
        console.debug("Agora screen share join and publish room");
        await screenShare.join(
          process.env.REACT_APP_AGORA_APP_ID!,
          params.room,
          encodedToken,
          agoraId
        );
        await screenShare.publish(track);

        togglePipOn();
      } catch (error) {
        throw error;
      } finally {
        loadingScreenShare.current = false;
      }
    } else if (call.localScreenShareTrack && !loadingScreenShare.current) {
      endScreenShare();
    }
  }, [
    remotePeerScreenSharing,
    call.localScreenShareTrack,
    endScreenShare,
    setCall,
    setUser,
    params.room,
    localParticipant?.sessionId,
    togglePipOn,
  ]);
};

export const useLeaveCall = () => {
  const socket = useSocket();
  const [call, setCall] = useCall();
  const [, setChat] = useChat();
  const navigate = useNavigate();
  const location = useLocation();
  const closeModal = useCloseModal();
  const [, setTranscript] = useTranscript();
  const pipSupported = usePipSupported();
  const togglePipOff = useTogglePipOff();

  return useCallback(
    (args: { intentional: boolean }) => {
      // Close any modals
      closeModal();
      // Close pip
      if (pipSupported) {
        togglePipOff();
        if (document.pictureInPictureElement) {
          document.exitPictureInPicture();
        }
      }

      socket?.emit("leave", { room: call.roomID });
      if (agora.channelName) {
        console.debug("Leave agora channel", agora.channelName);
        agora.leave();
      }
      if (screenShare.channelName) {
        console.debug(
          "Leave agora (screen share) channel",
          screenShare.channelName
        );
        screenShare.leave();
      }

      call.localScreenShareTrack?.close();
      call.localMicrophoneTrack?.close();
      call.localCameraTrack?.close();
      call.processedVirtualTrack?.stop();

      setCall(initialCallState);
      setChat(initialChatState);
      setTranscript(initialTranscriptState);

      if (args.intentional) {
        // Navigate to post meeting page
        navigate(location.pathname + "/post");
      }
    },
    [
      closeModal,
      socket,
      call,
      setCall,
      setChat,
      navigate,
      location.pathname,
      setTranscript,
      pipSupported,
      togglePipOff,
    ]
  );
};

export const useToggleSpeechTranslation = () => {
  const [, setCall] = useCall();
  return useCallback(() => {
    setCall((prevState) => ({
      ...prevState,
      enableTranslation: !prevState.enableTranslation,
    }));
  }, [setCall]);
};

const virtualBackgroundProcessor = virtualBackground.createProcessor();
virtualBackgroundProcessor.init("./assets/wasm");
virtualBackgroundProcessor.disable();

export const useToggleNoneFilter = () => {
  const [, setCall] = useCall();
  return useCallback(async () => {
    amplitude?.logEvent(AmplitudeEvent.TOGGLE_VIDEO_BACKGROUND, {
      background: "none",
    });
    await virtualBackgroundProcessor.disable();
    setCall((prevState) => ({
      ...prevState,
      processedVirtualTrack: undefined,
      virtualBackground: undefined,
    }));
    localStorage.setItem(LocalStorageKeys.VIRTUAL_BACKGROUND, "none");
  }, [setCall]);
};

export const useToggleBlurFilter = () => {
  const [, setCall] = useCall();
  return useCallback(
    async (localCameraTrack: ILocalVideoTrack) => {
      amplitude?.logEvent(AmplitudeEvent.TOGGLE_VIDEO_BACKGROUND, {
        background: "blur",
      });
      if (localCameraTrack) {
        localCameraTrack
          .pipe(virtualBackgroundProcessor)
          .pipe(localCameraTrack.processorDestination);
      }
      await virtualBackgroundProcessor.enable();
      const processedVirtualTrack =
        (await virtualBackgroundProcessor.getProcessedTrack()) || undefined;
      setCall((prevState) => {
        return {
          ...prevState,
          processedVirtualTrack,
          virtualBackground: "blur",
        };
      });
      localStorage.setItem(LocalStorageKeys.VIRTUAL_BACKGROUND, "blur");
    },
    [setCall]
  );
};

export const useStartRecording = () => {
  const socket = useSocket();
  const [call] = useCall();
  const [user] = useUser();
  const closeModal = useCloseModal();

  // Parse room id from location (since modal doesn't have params path)
  const location = useLocation();

  return useCallback(() => {
    amplitude?.logEvent(AmplitudeEvent.CONFIRM_START_RECORDING);
    const room = location.pathname.split("/")[2];
    if (room && user) {
      let args: StartRecordingArgs = {
        room: room as RoomID,
        users: [
          user.id,
          ...Object.values(call.remotePeers).map((peer) => peer.userId),
        ],
      };
      socket?.emit("start_recording", args);
      closeModal();
    }
  }, [location, user, call.remotePeers, socket, closeModal]);
};

export const useStopRecording = () => {
  const socket = useSocket();
  const closeModal = useCloseModal();

  // Parse room id from location (since modal doesn't have params path)
  const location = useLocation();

  return useCallback(() => {
    amplitude?.logEvent(AmplitudeEvent.CONFIRM_STOP_RECORDING);
    const room = location.pathname.split("/")[2];
    if (room) {
      let args: StopRecordingArgs = {
        room: room as RoomID,
      };
      socket?.emit("stop_recording", args);
      closeModal();
    }
  }, [location, socket, closeModal]);
};

// On remote recording start
export const useRecordingStarted = (toastContent: ReactNode) => {
  const socket = useSocket();
  const [, setCall] = useCall();
  const [playSound] = useSound(recordingStartedSound);

  return useEffect(() => {
    socket?.on("start_recording", (args: StartRecordingArgs) => {
      setCall((prevState) => ({
        ...prevState,
        recording: true,
      }));
      toast(toastContent);
      playSound();
    });

    return () => {
      socket?.off("start_recording");
    };
  }, [socket, setCall, playSound, toastContent]);
};

// On remote recording stop
export const useRecordingStopped = (toastContent: ReactNode) => {
  const socket = useSocket();
  const [, setCall] = useCall();
  const [playSound] = useSound(recordingStoppedSound);

  return useEffect(() => {
    socket?.on("stop_recording", (args: StopRecordingArgs) => {
      setCall((prevState) => ({
        ...prevState,
        recording: false,
      }));
      toast(toastContent);
      playSound();
    });

    return () => {
      socket?.off("stop_recording");
    };
  }, [socket, setCall, playSound, toastContent]);
};

// Play audio and show toast if recording in progress when joining
export const useRecordingInProgress = (toastContent: ReactNode) => {
  const reported = useRef(false);
  const [playSound] = useSound(recordingInProgressSound);
  const [call] = useCall();

  return useEffect(() => {
    if (!reported.current && call.state === "connecting" && call.recording) {
      reported.current = true;
      toast(toastContent);
      playSound();
    }
  }, [call.recording, playSound, call.state, toastContent]);
};

// export const useDecrementTrialTime = () => {
//   const [, setCall] = useCall();

//   return useCallback(
//     (seconds: number) => {
//       setCall((prevState) => ({
//         ...prevState,
//         trialTimeRemainingInSeconds:
//           prevState.trialTimeRemainingInSeconds - seconds,
//       }));
//     },
//     [setCall]
//   );
// };

export const useSortSpeakers = () => {
  // Sort last speakers list based on remote audio stream volume (only once every 5 seconds)
  const SORT_LAST_SPEAKERS_LIST_TIMEOUT_IN_SECONDS = 5;
  const [call, setCall] = useCall();
  return useEffect(() => {
    let sortLastSpeakersInterval = setInterval(() => {
      let lastSpeakers = Object.values(call.remotePeers)
        .sort((p1, p2) => {
          let p1Volume =
            call.remoteTracks[p1.sessionId]?.audioTrack?.getVolumeLevel() || 0;
          let p2Volume =
            call.remoteTracks[p2.sessionId]?.audioTrack?.getVolumeLevel() || 0;

          if (p1Volume <= 0 && p2Volume <= 0) {
            // TODO: Compare by time last spoken if both volumes are 0
            return 0;
          } else {
            // Return volume comparison
            return p2Volume - p1Volume;
          }
        })
        .map((peer) => peer.sessionId);
      setCall((prevState) => ({
        ...prevState,
        lastSpeakers,
      }));
    }, SORT_LAST_SPEAKERS_LIST_TIMEOUT_IN_SECONDS * 1000);

    return () => {
      clearInterval(sortLastSpeakersInterval);
    };
  }, [setCall, call.remotePeers, call.remoteTracks]);
};

export default useCall;
