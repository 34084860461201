import "./Recording.css";
import useUser from "../hooks/user";
import api from "../api";
import {} from "../utils";
import {
  ChangeEvent,
  createRef,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import {
  AmplitudeEvent,
  GetRecordingResponse,
  getRecordingTitle,
  StatusCode,
  CaptionID,
  speechLanguageLocaleToLanguageLocale,
  RecordingID,
} from "byrdhouse-types";
import { IoSearch } from "react-icons/io5";
import useLayout, {
  useToggleDownloadTranscriptModal,
  useToggleShareRecordingModal,
} from "../hooks/layout";
import Button from "../components/Button";
import React from "react";
import Loader from "../components/Loader";
import Breadcrumbs, { BreadcrumbsProps } from "../components/Breadcrumbs";
import { FiDownload } from "react-icons/fi";
import { useLanguageLocaleDisplayNames } from "../hooks/transcript";
import { AxiosError } from "axios";
import cupSticker from "../assets/stickers/cup.png";
import amplitude from "../amplitude";
import { useWindowSize } from "react-use";
import { AiFillLock, AiFillUnlock } from "react-icons/ai";
import RecordingSummary from "../components/RecordingSummary";

const Recording = () => {
  const params = useParams();
  useEffect(() => {
    if (params.id) {
      amplitude?.logEvent(AmplitudeEvent.OPEN_SPECIFIC_RECORDING, {
        id: params.id,
      });
    }
  }, [params.id]);

  const [loading, setLoading] = useState(true);
  const [transcript, setTranscript] = useState<GetRecordingResponse>();
  const [recordingLanguage, setRecordingLanguage] = useState<string>();
  const [user] = useUser();
  const [availableLanguageLocales, setAvailableLanguageLocales] =
    useState<string[]>();
  const [captionTimestamps, setCaptionTimestamps] =
    useState<{ timestamp: number; id: CaptionID }[]>();

  const [highlightedCaption, setHighlightedCaption] = useState<CaptionID>();
  const [searchedCaptions, setSearchedCaptions] = useState<CaptionID[]>();

  const [query, setQuery] = useState<string>("");
  const audioPlayer = createRef<HTMLAudioElement>();

  const toggleShareRecordingModal = useToggleShareRecordingModal();
  const clickOpenShareRecordingModal = useCallback(() => {
    if (!params.id || !transcript) {
      return;
    }
    toggleShareRecordingModal(params.id, Object.values(transcript.users));
  }, [params.id, transcript, toggleShareRecordingModal]);

  const toggleDownloadTranscriptModal = useToggleDownloadTranscriptModal(
    transcript?.users,
    availableLanguageLocales,
    transcript?.captions
  );

  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 1000;

  const [recordingInProgress, setRecordingInProgress] = useState(false);
  const [recordingForbidden, setRecordingForbidden] = useState(false);

  const handleTimeUpdate = (event: React.SyntheticEvent) => {
    const target = event.target as HTMLMediaElement;
    if (captionTimestamps) {
      const captionId = binarySearch(
        captionTimestamps,
        target.currentTime,
        0,
        captionTimestamps?.length
      ) as CaptionID;
      setHighlightedCaption(captionId);
    }
  };

  const handleCaptionClick = (id: CaptionID) => {
    let previousCaptionTimestamp = 0;
    if (captionTimestamps) {
      for (let index = 0; index < captionTimestamps.length; index++) {
        if (captionTimestamps[index].id === id) {
          if (index <= 0) {
            previousCaptionTimestamp = 0;
          } else {
            previousCaptionTimestamp = captionTimestamps[index - 1].timestamp;
          }
        }
      }
      if (audioPlayer.current) {
        audioPlayer.current.currentTime = previousCaptionTimestamp + 0.001;
      }
    }
  };

  let binarySearch = (
    arr: { timestamp: number; id: string }[],
    x: number,
    start: number,
    end: number
  ): string => {
    // Base Condition
    if (start > end) return arr[start].id;

    // Find the middle index
    let mid = Math.floor((start + end) / 2);

    // Compare mid with given key x
    if (arr[mid].timestamp === x) return arr[mid].id;

    // If element at mid is greater than x,
    // search in the left half of mid
    if (arr[mid].timestamp > x) return binarySearch(arr, x, start, mid - 1);
    // If element at mid is smaller than x,
    // search in the right half of mid
    else return binarySearch(arr, x, mid + 1, end);
  };

  const getTranscript = useCallback(async () => {
    try {
      if (params.id) {
        let transcript = await api.getRecording({ id: params.id });
        setTranscript(transcript);
        setSearchedCaptions([]);
        const captionTranslations = transcript.captions.map(
          (caption) => caption.translations
        );
        const translationLanguageLocales = [
          ...new Set(
            captionTranslations.flatMap((translation) =>
              Object.keys(translation)
            )
          ),
        ];
        setAvailableLanguageLocales(translationLanguageLocales);

        // Build timestamp array
        let timestampArray: {
          timestamp: number;
          id: CaptionID;
        }[] = [];
        const timestamp = new Date(transcript.startTimestamp);
        const startTime = timestamp.getTime();
        transcript.captions.forEach((caption) => {
          // Get the difference in seconds
          const timeDifference =
            (new Date(caption.timestamp).getTime() - startTime) / 1000;
          timestampArray.push({ timestamp: timeDifference, id: caption.id });
        });
        setCaptionTimestamps(timestampArray);
      }
    } catch (error) {
      // Recording still in progress
      if (
        error instanceof AxiosError &&
        error.response?.status === StatusCode.CONFLICT
      ) {
        setRecordingInProgress(true);
      }
      // User forbidden to view recording
      if (
        error instanceof AxiosError &&
        error.response?.status === StatusCode.FORBIDDEN
      ) {
        setRecordingForbidden(true);
      }
    } finally {
      setLoading(false);
    }
  }, [params.id]);

  const search = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    amplitude?.logEvent(AmplitudeEvent.SEARCH_SPECIFIC_RECORDING, {
      query: event.target.value,
    });
    setQuery(event.target.value);
  }, []);

  // User loads page
  useEffect(() => {
    if (user && availableLanguageLocales && availableLanguageLocales.length) {
      const userLanguage = speechLanguageLocaleToLanguageLocale(
        user.speechLanguageLocale
      );
      if (availableLanguageLocales.includes(userLanguage)) {
        setRecordingLanguage(userLanguage);
      } else {
        setRecordingLanguage(availableLanguageLocales[0]);
      }
    }
  }, [user, availableLanguageLocales]);

  const [layout] = useLayout();
  useEffect(() => {
    getTranscript();
  }, [params.id, getTranscript, layout.recordingsUpdate]);

  useEffect(() => {
    if (query && recordingLanguage) {
      setSearchedCaptions(
        transcript?.captions
          .filter((caption) => !!caption.translations[recordingLanguage])
          .filter((caption) =>
            caption.translations[recordingLanguage]
              .toLowerCase()
              .includes(query.toLowerCase())
          )
          .map((caption) => caption.id)
      );
    } else {
      setSearchedCaptions([]);
    }
  }, [query, recordingLanguage, transcript?.captions]);

  const onChangeLanguage = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      amplitude?.logEvent(AmplitudeEvent.CHANGE_LANGUAGE_SPECIFIC_RECORDING, {
        language: event.target.value,
      });
      setRecordingLanguage(event.target.value);
    },
    []
  );

  // const [playbackSpeed, setPlaybackSpeed] = useState(1);

  // useEffect(() => {
  //   if (audioPlayer.current) {
  //     audioPlayer.current.playbackRate = playbackSpeed;
  //   }
  // }, [audioPlayer, playbackSpeed]);

  const [enableAutoScroll, setEnableAutoScroll] = useState(true);

  useEffect(() => {
    setEnableAutoScroll(!query.length);
  }, [query.length]);

  const prevHighlightedCaption = useRef<string | undefined>(undefined);
  const recordingCaptionsRef = createRef<HTMLDivElement>();
  useEffect(() => {
    if (
      recordingCaptionsRef.current &&
      enableAutoScroll &&
      highlightedCaption &&
      highlightedCaption !== prevHighlightedCaption.current
    ) {
      const caption = document.getElementById(highlightedCaption);
      caption?.scrollIntoView({ behavior: "smooth" });

      prevHighlightedCaption.current = highlightedCaption;
    }
  }, [
    enableAutoScroll,
    highlightedCaption,
    prevHighlightedCaption,
    recordingCaptionsRef,
  ]);

  const [shownQueryMatch, setShownQueryMatch] = useState(0);
  const showNextQueryMatch = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter" && searchedCaptions?.length) {
        // Get the first in the list (if exists)
        const id = searchedCaptions[shownQueryMatch];
        document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
        setShownQueryMatch((prevState) =>
          prevState < searchedCaptions.length - 1 ? prevState + 1 : 0
        );
      }
    },
    [searchedCaptions, shownQueryMatch]
  );

  const escapeRegex = (text: string) => {
    return text.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
  };
  const renderHighlight = useCallback(
    (text: string): string | ReactNode[] => {
      if (query.length) {
        const regex = new RegExp(`(${escapeRegex(query.toLowerCase())})`, "g");
        const split = text.toLowerCase().split(regex);
        if (split.length > 1) {
          let index = 0;
          return (
            split
              // Restore case
              .map((chunk) => {
                const start = index;
                const end = index + chunk.length;
                index += chunk.length;
                return text.substring(start, end);
              })
              // Render highlight
              .map((chunk, index) => {
                if (chunk.toLowerCase() === query.toLowerCase()) {
                  return <mark key={index}>{chunk}</mark>;
                } else {
                  return <span key={index}>{chunk}</span>;
                }
              })
          );
        }
      }
      return text;
    },
    [query]
  );

  const languageLocalDisplayNames = useLanguageLocaleDisplayNames();

  const recordingTitle = useMemo(() => {
    if (transcript) {
      return getRecordingTitle(transcript.startTimestamp, transcript.host);
    }
    return "";
  }, [transcript]);

  if (loading) {
    return (
      <div className="Recording">
        <Loader size={40} fullscreen={true} />
      </div>
    );
  } else {
    if (recordingInProgress) {
      return (
        <div className="RecordingNotAvailable">
          <img src={cupSticker} alt="Coffee Cup" />
          <div>Recording not yet available</div>
          <div>we'll alert you via email once ready</div>
        </div>
      );
    } else if (recordingForbidden) {
      return (
        <div className="RecordingNotAvailable">
          <img src={cupSticker} alt="Coffee Cup" />
          <div>
            Sorry, you don't have the permission to view the recording. Please
            contact the meeting host for access.
          </div>
        </div>
      );
    } else {
      const crumbs: BreadcrumbsProps["crumbs"] = [
        { label: "Home", link: "/" },
        { label: "Recordings", link: "/recordings" },
        { label: recordingTitle, link: window.location.pathname },
      ];
      return (
        <div className="Recording">
          {transcript && (
            <React.Fragment>
              {!isMobile && <Breadcrumbs crumbs={crumbs} />}
              <div className="RecordingTitle">
                <h1>{recordingTitle}</h1>
                {!isMobile && (
                  <Button
                    text={
                      <div className="ShareRecording">
                        {transcript.public ? <AiFillUnlock /> : <AiFillLock />}
                        Share
                      </div>
                    }
                    background="gradient"
                    onClick={clickOpenShareRecordingModal}
                  />
                )}
              </div>
              <div>
                <div>
                  <div className="RecordingPlayback">
                    <div className="RecordingPanelTitle">Recording</div>
                    <div>
                      <audio
                        src={transcript.audioURL}
                        autoPlay={false}
                        controls={true}
                        onTimeUpdate={handleTimeUpdate}
                        ref={audioPlayer}
                      />
                      {/* <div className="PlaybackSpeed">
                        <div>Playback Speed</div>
                        <div>
                          {[0.5, 0.75, 1, 1.5, 2, 3].map((speed) => (
                            <div
                              className={
                                speed === playbackSpeed ? "Enabled" : undefined
                              }
                              onClick={() => setPlaybackSpeed(speed)}
                            >
                              {speed}x
                            </div>
                          ))}
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="RecordingDetails">
                    {/* Only en supported for summary right now */}
                    {recordingLanguage === "en" && (
                      <RecordingSummary
                        id={params.id as RecordingID}
                        language={recordingLanguage}
                        summary={transcript.summary}
                        actionItems={transcript.actionItems}
                      />
                    )}
                    <div className="RecordingAdditionalInfo">
                      <div className="RecordingPanelTitle">
                        Additional Information
                      </div>
                      <div>
                        <div>{`Date: ${new Date(
                          transcript.startTimestamp
                        ).toLocaleDateString()} ${new Date(
                          transcript.startTimestamp
                        ).toLocaleTimeString()}`}</div>
                        {transcript.host && (
                          <div>{`Host: ${transcript.host.displayName}`}</div>
                        )}
                        <div>{`Attendees: ${Object.values(transcript.users)
                          .map((user) => user.displayName)
                          .join(", ")}`}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="RecordingTranscript">
                  <div className="RecordingPanelTitle">
                    <div>Meeting Notes</div>
                    <div className="RecordingTranscriptTitleInputs">
                      {!!availableLanguageLocales?.length && !isMobile && (
                        <FiDownload
                          onClick={toggleDownloadTranscriptModal}
                          className="DownloadTranscript"
                        />
                      )}
                      <div className="SearchTranscript">
                        <IoSearch />
                        <input
                          type="text"
                          placeholder="Search"
                          value={query}
                          onChange={search}
                          onKeyDown={showNextQueryMatch}
                        />
                      </div>
                      {!!availableLanguageLocales?.length && (
                        <select
                          onChange={onChangeLanguage}
                          value={recordingLanguage}
                        >
                          {availableLanguageLocales &&
                            availableLanguageLocales.map((locale) => (
                              <option key={locale} value={locale}>
                                {languageLocalDisplayNames.of(locale)}
                              </option>
                            ))}
                        </select>
                      )}
                    </div>
                  </div>
                  <div
                    ref={recordingCaptionsRef}
                    className="RecordingTranscriptCaptions"
                  >
                    {searchedCaptions &&
                      recordingLanguage &&
                      transcript.captions
                        .filter(
                          (caption) => !!caption.translations[recordingLanguage]
                        )
                        .map((caption) => (
                          <div
                            id={caption.id}
                            key={caption.id}
                            className={`RecordingCaption ${
                              highlightedCaption === caption.id
                                ? "HighlightedCaption"
                                : ""
                            }`}
                            onClick={() => handleCaptionClick(caption.id)}
                          >
                            <div className="RecordingCaptionTitle">
                              <span>
                                {transcript.users[caption.from]?.displayName}
                              </span>
                              <span className="RecordingCaptionTimestamp">
                                {new Date(caption.timestamp).toLocaleTimeString(
                                  "en-US",
                                  {
                                    hour: "numeric",
                                    minute: "numeric",
                                  }
                                )}
                              </span>
                            </div>
                            <div>
                              {renderHighlight(
                                caption.translations[recordingLanguage]
                              )}
                            </div>
                          </div>
                        ))}
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      );
    }
  }
};

export default Recording;
