import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import amplitude from "../amplitude";
import api from "../api";

import useCall, { useToggleBlurFilter } from "../hooks/call";
import useUser, { useLocalParticipant, useSignOut } from "../hooks/user";
import LanguageSelect from "./LanguageSelect";
import "./PrecallSettings.css";
import Stream from "./Stream";
import AgoraRTC, {
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
} from "agora-rtc-sdk-ng";
import {
  AmplitudeEvent,
  JoinRoomResponse,
  MAX_DISPLAY_NAME_LEN,
  MIN_DISPLAY_NAME_LEN,
  RoomID,
  StatusCode,
} from "byrdhouse-types";
import { toast } from "react-toastify";
import { IoWarning } from "react-icons/io5";
import { AxiosError } from "axios";
import Filters from "./Filters";
import Button from "./Button";
import { useWindowSize } from "react-use";
import { LocalStorageKeys } from "../types";

export type PrecallSettingsProps = {};

const PrecallSettings = (props: PrecallSettingsProps) => {
  const [user, setUser] = useUser();
  const [call, setCall] = useCall();
  const params = useParams();
  const navigate = useNavigate();
  const signOut = useSignOut();
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 1000;
  const localParticipant = useLocalParticipant();

  const joiningNest = useRef(false);

  const [displayName, setDisplayName] = useState(user?.displayName || "");

  const toggleBlurFilter = useToggleBlurFilter();

  // Get camera/mic
  const haveLocalTracks = useRef<boolean>(false);
  const initLocalTracks = useCallback(async () => {
    if (haveLocalTracks.current) {
      return;
    }
    haveLocalTracks.current = true;
    try {
      let localMicrophoneTrack: IMicrophoneAudioTrack;
      let localCameraTrack: ICameraVideoTrack | undefined;

      if (!(await AgoraRTC.getCameras(true)).length) {
        localMicrophoneTrack = await AgoraRTC.createMicrophoneAudioTrack({
          AEC: true,
          ANS: true,
          AGC: true,
        });
      } else {
        [localMicrophoneTrack, localCameraTrack] =
          await AgoraRTC.createMicrophoneAndCameraTracks(
            {
              AEC: true,
              ANS: true,
              AGC: true,
            },
            {
              encoderConfig: {
                width: { max: 640 },
                height: { max: 480 },
                bitrateMax: 1000,
              },
            }
          );
      }

      // Find selected devices from local tracks
      let selectedAudioDeviceID = localMicrophoneTrack
        .getMediaStreamTrack()
        .getSettings().deviceId;
      let selectedVideoDeviceID = localCameraTrack
        ?.getMediaStreamTrack()
        .getSettings().deviceId;

      setCall((prevState) => ({
        ...prevState,
        localMicrophoneTrack: localMicrophoneTrack,
        localCameraTrack: localCameraTrack,
        selectedAudioDeviceID: selectedAudioDeviceID,
        selectedVideoDeviceID: selectedVideoDeviceID,
      }));
      amplitude?.logEvent(AmplitudeEvent.SET_PERMISSIONS, { allowed: true });

      const virtualBackground = localStorage.getItem(
        LocalStorageKeys.VIRTUAL_BACKGROUND
      );
      if (virtualBackground === "blur" && localCameraTrack) {
        toggleBlurFilter(localCameraTrack);
      }
    } catch {
      amplitude?.logEvent(AmplitudeEvent.SET_PERMISSIONS, { allowed: false });
      haveLocalTracks.current = false;
    }
  }, [setCall, toggleBlurFilter]);

  useEffect(() => {
    if (params.room) {
      amplitude?.logEvent(AmplitudeEvent.OPEN_JOIN_NEST, {
        roomID: params.room,
      });
    } else {
      amplitude?.logEvent(AmplitudeEvent.OPEN_BUILD_NEST);
    }
    initLocalTracks();

    return () => {};
  }, [initLocalTracks, params.room]);

  // Get location/weather
  const getLocationAndWeather = useCallback(async () => {
    const culturalContext = await api.getWeather();
    setUser((prevState) =>
      prevState ? { ...prevState, culturalContext } : prevState
    );
  }, [setUser]);

  useEffect(() => {
    getLocationAndWeather();
  }, [getLocationAndWeather]);

  const onDisplayNameChanged = (event: SyntheticEvent<HTMLInputElement>) => {
    setDisplayName(event.currentTarget.value.toString());
  };

  const onDisplayNameBlur = async () => {
    if (user?.displayName !== displayName) {
      // Save the display name set
      let response = await api.updateUser({ user: { displayName } });
      setUser((prevState) =>
        prevState ? { ...prevState, ...response.user } : prevState
      );
    }
  };

  const onJoinRoomClicked = useCallback(async () => {
    try {
      joiningNest.current = true;
      // FYI: Join and create room response are the same here
      let room: JoinRoomResponse = params.room
        ? await api.joinRoom({ room: params.room as RoomID })
        : await api.createRoom();
      // Set the room host
      setCall((prevState) => ({
        ...prevState,
        roomID: room.id as RoomID,
        host: room.host,
        pinnedSpeaker: room.pinnedSpeaker,
        translationProvider: room.translationProvider,
        recording: room.recordingInProgress,
        state: "connecting",
      }));
      if (!params.room) {
        navigate(`/meeting/${room.id}`);
      }
    } catch (error) {
      if (params.room) {
        if (
          error instanceof AxiosError &&
          error?.response?.status === StatusCode.FORBIDDEN
        ) {
          // Removed from room
          toast(
            <div className="ByrdhouseToast">
              <IoWarning />
              {
                "Sorry you have been removed from the host, please contact the host or build a new nest to continue."
              }
            </div>
          );
        } else if (
          error instanceof AxiosError &&
          error?.response?.status === StatusCode.UNAUTHORIZED
        ) {
          toast(
            <div className="ByrdhouseToast">
              <IoWarning />
              {
                "Oops. Sorry, the user who invited you has a free plan that doesn't support group calls. Please contact us to try the group call experience."
              }
            </div>
          );
        } else if (
          error instanceof AxiosError &&
          error?.response?.status === StatusCode.NOT_FOUND
        ) {
          // Room doesn't exist
          toast(
            <div className="ByrdhouseToast">
              <IoWarning />
              {"Sorry the nest doesn't exist anymore. Please build a new one."}
            </div>
          );
        } else {
          toast(<div className="ByrdhouseToast">{"Error joining nest"}</div>, {
            type: "error",
          });
        }
      } else {
        toast(<div className="ByrdhouseToast">{"Error building nest"}</div>, {
          type: "error",
        });
        throw error;
      }
    }
  }, [params.room, navigate, setCall]);

  // Disable mic/camera/screen when leaving component and didn't connect to call
  const cleanupMedia = useCallback(() => {
    call.localMicrophoneTrack?.close();
    call.localCameraTrack?.close();
    call.localScreenShareTrack?.close();
  }, [
    call.localMicrophoneTrack,
    call.localCameraTrack,
    call.localScreenShareTrack,
  ]);
  useEffect(() => {
    return () => {
      if (call.state === "disconnected" && !joiningNest.current) {
        console.debug("Cleanup local streams...");
        cleanupMedia();
      }
    };
  }, [call.state, cleanupMedia]);

  return (
    <div className="PrecallSettings">
      <div>
        <h2>{!params.room ? "Build a nest" : "Join a nest"}</h2>
        {params.room && (
          <a href={window.location.href}>
            {window.location.host + window.location.pathname}
          </a>
        )}
      </div>
      <div className="VideoSettings">
        <div className="VideoPreview">
          {localParticipant && (
            <Stream
              videoTrack={call.localCameraTrack}
              audioTrack={call.localMicrophoneTrack}
              local={true}
              user={localParticipant}
              precall={true}
              mobilePrecall={isMobile}
            />
          )}
        </div>
        <div className="Inputs">
          <div>
            <div className="InputTitle">Name</div>
            <div>
              <input
                type="text"
                placeholder="Display Name"
                minLength={MIN_DISPLAY_NAME_LEN}
                maxLength={MAX_DISPLAY_NAME_LEN}
                value={displayName}
                onChange={onDisplayNameChanged}
                onBlur={onDisplayNameBlur}
              />
            </div>
          </div>
          <div>
            <div className="InputTitle">I speak</div>
            <LanguageSelect />
          </div>
          {!isMobile && (
            <div>
              <div className="InputTitle">Video Background</div>
              <Filters />
            </div>
          )}
          <Button
            text={!params.room ? "Build nest" : "Join nest"}
            onClick={onJoinRoomClicked}
            background="gradient"
            disabled={!call.localMicrophoneTrack}
          />
          <div>
            <span className="SignOut" onClick={signOut}>
              Sign Out
            </span>
          </div>
        </div>
      </div>
      <div className="Disclaimer">
        <span>
          By continuing you acknowledge that you have read the{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://byrdhouse.notion.site/byrdhouse/Privacy-Policy-c71b7c579187429da97f8aec36b1585e"
          >
            Privacy Policy
          </a>{" "}
          and agree to the{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://byrdhouse.notion.site/byrdhouse/Terms-of-Service-7d7aa2e356ef4f22bec82a8d885fdc4d"
          >
            Terms of Service
          </a>
          .
        </span>
      </div>
    </div>
  );
};

export default PrecallSettings;
