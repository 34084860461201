import {
  Caption,
  CaptionID,
  GetUserAccountResponse,
  IPopularLanguage,
  IUser,
  Message,
  Participant,
  ProviderState,
  SessionID,
  UserID,
} from "byrdhouse-types";
import { createContext, Dispatch, SetStateAction } from "react";
import {
  DeviceID,
  DisplayCaption,
  HistoryCaption,
  LocalStorageKeys,
  Panel,
  PanelVisibilityState,
} from "./types";
import {
  ICameraVideoTrack,
  ILocalVideoTrack,
  IMicrophoneAudioTrack,
  IRemoteAudioTrack,
  IRemoteVideoTrack,
} from "agora-rtc-sdk-ng";
import { Socket } from "socket.io-client";

// State types

export type SocketState = {
  client?: Socket;
};

export const initialSocketState = {};

export type CallState = {
  state: "disconnected" | "connecting" | "connected";

  roomID?: string;

  // Local state
  localMicrophoneTrack?: IMicrophoneAudioTrack;
  localCameraTrack?: ICameraVideoTrack;
  localScreenShareTrack?: ILocalVideoTrack;

  micMuted: boolean;
  cameraMuted: boolean;
  cameraUnMuting: boolean;
  enableTranslation: boolean;
  selectedAudioDeviceID?: DeviceID;
  selectedVideoDeviceID?: DeviceID;

  screenShareLoading: boolean;

  virtualBackground?: "blur";
  processedVirtualTrack?: MediaStreamTrack;

  // Provider state
  translationProvider: ProviderState;

  // Speaker state
  pinnedSpeaker?: SessionID;
  lastSpeakers: SessionID[];

  // Remote peers
  remotePeers: { [key: SessionID]: Participant };
  remoteTracks: {
    [key: SessionID]:
      | {
          audioTrack?: IRemoteAudioTrack;
          videoTrack?: IRemoteVideoTrack;
        }
      | undefined;
  };

  // Recording state
  recording: boolean;

  // Dub
  dub: boolean;
  dubAudioTrack?: IRemoteAudioTrack;

  // Host state
  host?: UserID;
  showHostControls?: "controls" | "confirm";
  confirmHostAction?: {
    showHostControlsAfter: boolean;
    participant: Participant;
    action: string;
    callback: () => void;
  };
};

export const initialCallState: CallState = {
  state: "disconnected",

  micMuted: false,
  cameraMuted: false,
  cameraUnMuting: false,
  enableTranslation: true,

  screenShareLoading: false,

  lastSpeakers: [],

  remotePeers: {},
  remoteTracks: {},

  recording: false,

  dub: false,

  translationProvider: {
    translationEnabled: false,
    groupCallEnabled: false,
    recordingEnabled: false,
    voiceToVoiceEnabled: false,
    maxMembers: 2,
  },

  showHostControls: undefined,
};

export type UserState =
  | (IUser & Omit<Participant, "userId" | "sessionId">)
  | undefined;

export const initialUserState: UserState = undefined;

export type SessionState = { id?: SessionID };

export const initialSessionState: SessionState = {};

export type VoiceCaption = {
  captionId: string;
  speechLanguage: string;
  text: string;
};

export type TranscriptState = {
  fontSize: number;
  originalCaptions: {
    [captionId: CaptionID]: {
      caption: Caption;
      from: SessionID;
    };
  };
  history: {
    [captionId: CaptionID]: HistoryCaption;
  };
  captions: {
    [captionId: CaptionID]: DisplayCaption;
  };
  interimCaptions: {
    [captionID: CaptionID]: DisplayCaption;
  };
};

export const initialTranscriptState: TranscriptState = {
  fontSize: 22,
  originalCaptions: {},
  history: {},
  captions: {},
  interimCaptions: {},
};

export type ChatState = {
  messages: (Omit<Message, "from"> & {
    from: Pick<Participant, "userId" | "displayName">;
  })[];
  numUnreadMessages: number;
};

export const initialChatState: ChatState = {
  messages: [],
  numUnreadMessages: 0,
};

export type LayoutState = {
  attendeeStreamSizeInPixels: {
    width: number;
    height: number;
  };
  shownAttendees: number;
  modal?:
    | "login"
    | "invite"
    | "confirm-leave-meeting"
    | "buy-more"
    | "settings"
    | "share-recording"
    | "confirm-recording-action"
    | "download-transcript"
    | "dub"
    | "report-caption"
    | "invite-member"
    | "confirm-remove-member"
    | "join-nest"
    | "support";
  modalParams?: any;
  resize: {
    topHeightInPercent: number;
    leftWidthInPercent: number;
  };
  panels: {
    transcript: Panel & { summaryActive: boolean };
    speaker: Panel;
    chat: Panel;
  };

  // This is purely to track if the user resized the transcript to detect if they scrolled up
  floatingTranscriptSize: {
    width: number;
    height: number;
  };

  showMobileLanguageSelect: boolean;
  popularLanguages: IPopularLanguage[];

  recordingsUpdate: boolean;

  pipActive: boolean;

  textSpeed: number;
};

export const initialLayoutState: LayoutState = {
  attendeeStreamSizeInPixels: {
    width: 160,
    height: 120,
  },
  shownAttendees: 1,
  resize: {
    topHeightInPercent: 80,
    leftWidthInPercent: 65,
  },
  panels: {
    transcript: {
      visible: true,
      visibility: PanelVisibilityState.DOCKED,
      summaryActive: false,
    },
    speaker: { visible: true, visibility: PanelVisibilityState.DOCKED },
    chat: { visible: false, visibility: PanelVisibilityState.FLOATING },
  },

  floatingTranscriptSize: {
    width: 0,
    height: 0,
  },

  showMobileLanguageSelect: false,
  popularLanguages: [],

  recordingsUpdate: false,

  pipActive: false,

  textSpeed: Number(localStorage.getItem(LocalStorageKeys.TEXT_SPEED) || "0"),
};

export type SummarizerState = {
  summarizedCaptions: CaptionID[];
  summaries: { [summaryId: string]: string };
};

export const initialSummarizerState: SummarizerState = {
  summarizedCaptions: [],
  summaries: {},
};

export type AccountState = GetUserAccountResponse | undefined;
export const initialAccountState = undefined;

// Global state type

export interface IGlobalContext {
  socket: SocketState;
  setSocket: Dispatch<SetStateAction<SocketState>>;
  call: CallState;
  setCall: Dispatch<SetStateAction<CallState>>;
  user: UserState;
  setUser: Dispatch<SetStateAction<UserState>>;
  session: SessionState;
  setSession: Dispatch<SetStateAction<SessionState>>;
  transcript: TranscriptState;
  setTranscript: Dispatch<SetStateAction<TranscriptState>>;
  chat: ChatState;
  setChat: Dispatch<SetStateAction<ChatState>>;
  layout: LayoutState;
  setLayout: Dispatch<SetStateAction<LayoutState>>;
  summarizer: SummarizerState;
  setSummarizer: Dispatch<SetStateAction<SummarizerState>>;
  account: AccountState;
  setAccount: Dispatch<SetStateAction<AccountState>>;
}

export const GlobalContext = createContext<IGlobalContext>(
  {} as IGlobalContext
);
