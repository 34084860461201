import { useCallback, useState } from "react";
import { IoCopyOutline, IoLink } from "react-icons/io5";
import "./InviteModal.css";
import amplitude from "../../amplitude";
import { AmplitudeEvent } from "byrdhouse-types";

const InviteModal = () => {
  const [linkCoped, setLinkCopied] = useState<boolean>(false);
  const copyLink = useCallback(() => {
    const link = window.location.toString();
    amplitude?.logEvent(AmplitudeEvent.SHARE_NEST_COPY_LINK, { link });
    navigator.clipboard.writeText(link);
    setLinkCopied(true);
  }, []);

  return (
    <div className="InviteModal">
      <div className="Label">Share with link</div>
      <div className="Link ShareLink">
        <IoLink />
        {window.location.toString()}
      </div>
      <button className="CopyLink ShareLink" onClick={copyLink}>
        <IoCopyOutline />
        {linkCoped ? "Link copied!" : "Copy link"}
      </button>
    </div>
  );
};

export default InviteModal;
