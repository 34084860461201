import "./Summarizer.css";
import { createRef, useCallback, useEffect } from "react";
import useSocket from "../hooks/socket";
import { ReceivePartialSummaryArgs } from "byrdhouse-types";
import useTranscript from "../hooks/transcript";
import useSummarizer from "../hooks/summarizer";

const Summarizer = () => {
  const socket = useSocket();
  const [transcript] = useTranscript();
  const [summarizer, setSummarizer] = useSummarizer();

  const summariesElement = createRef<HTMLDivElement>();
  const scrollSummaries = useCallback(() => {
    summariesElement.current?.scrollTo({
      top: summariesElement.current.scrollHeight,
    });
    return () => {};
  }, [summariesElement]);

  // Listen for partial summary updates
  const onReceivePartialSummary = useCallback(
    (args: ReceivePartialSummaryArgs) => {
      setSummarizer((prevState) => ({
        ...prevState,
        summaries: {
          ...prevState.summaries,
          [args.summaryId]: prevState.summaries[args.summaryId]
            ? prevState.summaries[args.summaryId] + args.delta
            : args.delta,
        },
      }));
      // Auto scroll
      scrollSummaries();
    },
    [setSummarizer, scrollSummaries]
  );
  useEffect(() => {
    socket?.on("send_partial_summary", onReceivePartialSummary);
    return () => {
      socket?.off("send_partial_summary", onReceivePartialSummary);
    };
  }, [socket, onReceivePartialSummary]);

  return (
    <div
      className="Summarizer"
      ref={summariesElement}
      style={{ fontSize: transcript.fontSize }}
    >
      {Object.values(summarizer.summaries).map((summary, index) => (
        <div key={index}>{summary}</div>
      ))}
    </div>
  );
};

export default Summarizer;
