import {
  AmplitudeEvent,
  getAutoDetectSupportedSpeechLanguages,
} from "byrdhouse-types";
import { useCallback, useEffect, useState } from "react";
import "./App.css";
import {
  AccountState,
  CallState,
  ChatState,
  GlobalContext,
  initialAccountState,
  initialCallState,
  initialChatState,
  initialLayoutState,
  initialSessionState,
  initialSocketState,
  initialSummarizerState,
  initialTranscriptState,
  initialUserState,
  LayoutState,
  SessionState,
  SocketState,
  SummarizerState,
  TranscriptState,
  UserState,
} from "./context";
import { auth } from "./firebase";
import { LocalStorageKeys } from "./types";
import { getUserLocales } from "./utils";
import Modal from "react-modal";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import GlobalModal from "./components/modals/GlobalModal";
import Meeting from "./routes/Meeting";
import PostMeeting from "./routes/PostMeeting";
import PrecallSettings from "./components/PrecallSettings";
import AdminDashboard from "./routes/admin/AdminDashboard";
import api from "./api";
import amplitude from "./amplitude";
import { Unsubscribe } from "firebase/auth";
import Loader from "./components/Loader";
import NavHeader from "./components/NavHeader";
import Landing from "./routes/Landing";
import * as Sentry from "@sentry/react";

// Toastify css
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

// React Resize CSS
import "react-resizable/css/styles.css";

// React Tooltip CSS
import "react-tooltip/dist/react-tooltip.css";

// React Datepicker CSS
import "react-datepicker/dist/react-datepicker.css";

import Home from "./routes/home/Home";
import Account from "./routes/home/Account";
import Usage from "./routes/home/Usage";
import Recordings from "./routes/Recordings";
import Recording from "./routes/Recording";
import Subscription from "./routes/home/Subscription";
import Support from "./routes/home/Support";
import Signup from "./routes/Signup";
import Welcome from "./routes/Welcome";

const App = () => {
  Modal.setAppElement("#root");

  // Socket state
  const [socket, setSocket] = useState<SocketState>(initialSocketState);

  // Call state
  const [call, setCall] = useState<CallState>(initialCallState);

  // User state
  const [user, setUser] = useState<UserState>(initialUserState);

  // Session state
  const [session, setSession] = useState<SessionState>(initialSessionState);

  // Transcript state
  const [transcript, setTranscript] = useState<TranscriptState>(
    initialTranscriptState
  );

  // Chat state
  const [chat, setChat] = useState<ChatState>(initialChatState);

  // Layout state
  const [layout, setLayout] = useState<LayoutState>(initialLayoutState);

  // Summarizer state
  const [summarizer, setSummarizer] = useState<SummarizerState>(
    initialSummarizerState
  );

  // Account state
  const [account, setAccount] = useState<AccountState>(initialAccountState);

  // Login auth
  const [loginFailed, setLoginFailed] = useState(false);
  const authenticate = useCallback(async () => {
    try {
      setLoginFailed(false);
      const response = await api.login();

      if (response.token) {
        localStorage.setItem(LocalStorageKeys.BYRDHOUSE_TOKEN, response.token);
      }

      const autoDetectSupported =
        getAutoDetectSupportedSpeechLanguages().includes(
          response.user.speechLanguageLocale.code
        );
      const autoDetect =
        autoDetectSupported &&
        !!localStorage.getItem(LocalStorageKeys.AUTO_DETECT);

      const locales = getUserLocales();
      const responseUser: UserState = {
        ...response.user,
        timezone: locales.timezone,
        screenSharing: false,
        muted: false,
        autoDetect: autoDetect,
      };

      // Set local storage to expect login flag when user comes back
      localStorage.setItem(LocalStorageKeys.EXPECT_LOGIN, "true");

      // Set sentry user
      Sentry.setUser({
        id: responseUser.id,
        email: responseUser.email,
        username: responseUser.displayName,
      });

      // Report login event to amplitude
      amplitude?.logEvent(AmplitudeEvent.LOGIN);

      // Set user with response and locales to login
      const { sessionId } = await api.claimSessionId();
      setSession((prevState) => ({ ...prevState, id: sessionId }));

      // Set amplitude user
      amplitude?.setUserId(responseUser.id);
      amplitude?.setUserProperties({
        ...responseUser,
        lastSessionId: sessionId,
      });

      setUser(responseUser);
    } catch {
      // Log out if issues logging in
      auth.signOut();
      localStorage.clear();
      setLoginFailed(true);
    }
  }, []);

  // Login auth listeners
  const expectLogin = !!localStorage.getItem(LocalStorageKeys.EXPECT_LOGIN);
  useEffect(() => {
    let removeAuthListener: Unsubscribe;
    if (!user && expectLogin) {
      if (localStorage.getItem(LocalStorageKeys.BYRDHOUSE_TOKEN)) {
        authenticate();
      } else {
        removeAuthListener = auth.onAuthStateChanged((currentUser) => {
          if (currentUser) {
            authenticate();
          } else {
            auth.signOut();
            localStorage.clear();
          }
        });
      }
    }
    return () => {
      removeAuthListener && removeAuthListener();
    };
  }, [authenticate, user, expectLogin]);

  const loading = expectLogin && !user && !loginFailed;
  const showMeeting =
    call.state === "disconnected" ? <PrecallSettings /> : <Meeting />;
  return (
    <div className="App">
      <GlobalContext.Provider
        value={{
          socket,
          setSocket,
          call,
          setCall,
          user,
          setUser,
          session,
          setSession,
          transcript,
          setTranscript,
          chat,
          setChat,
          layout,
          setLayout,
          summarizer,
          setSummarizer,
          account,
          setAccount,
        }}
      >
        {loading ? (
          <BrowserRouter>
            <NavHeader />
            <Loader size={40} fullscreen={true} />
          </BrowserRouter>
        ) : !expectLogin ? (
          <BrowserRouter>
            <GlobalModal />
            <NavHeader />
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/signup" element={<Signup />} />
              <Route
                path="/meeting/:room"
                element={<Signup referrer="meeting" />}
              />
              <Route
                path="/recordings"
                element={<Signup referrer="recording" />}
              />
              <Route
                path="/recordings/:id"
                element={<Signup referrer="recording" />}
              />
              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
          </BrowserRouter>
        ) : (
          <BrowserRouter>
            <GlobalModal />
            <NavHeader />
            <Routes>
              <Route path="/welcome" element={<Welcome />} />
              {/* Home Routes */}
              <Route path="/" element={<Home selected={<Recordings />} />} />
              <Route
                path="/subscription"
                element={<Home selected={<Subscription />} />}
              />
              <Route path="/recordings/:id" element={<Recording />} />
              <Route
                path="/account"
                element={<Home selected={<Account />} />}
              />
              <Route path="/usage" element={<Home selected={<Usage />} />} />
              <Route
                path="/support"
                element={<Home selected={<Support />} />}
              />
              {/* End Home Routes */}
              <Route path="/meeting/:room" element={showMeeting} />
              <Route path="/meeting/:room/post" element={<PostMeeting />} />
              {/* Admin Routes */}
              {(user?.email === "snow.huo@byrdhouseapp.com" ||
                user?.email === "jacob.greenway@byrdhouseapp.com") && (
                <Route path="/admin/dashboard" element={<AdminDashboard />} />
              )}
              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
          </BrowserRouter>
        )}
      </GlobalContext.Provider>
      <ToastContainer
        position="top-center"
        theme="dark"
        hideProgressBar={true}
        autoClose={3000}
        toastStyle={{
          borderRadius: "5px",
          padding: "5px",
        }}
      />
    </div>
  );
};

export default App;
