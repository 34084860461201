import { ChangeEvent, useCallback, useEffect, useState } from "react";
import useCall, { useMirrorTrack } from "../../hooks/call";
import useUser, { useLocalParticipant } from "../../hooks/user";
import Filters from "../Filters";
import "./SettingsModal.css";
import Stream from "../Stream";
import api from "../../api";
import amplitude from "../../amplitude";
import { AmplitudeEvent } from "byrdhouse-types";
import useLayout from "../../hooks/layout";
import { LocalStorageKeys } from "../../types";

const categories = ["audio", "video", "dub", "filters", "text-speed"] as const;
type Category = (typeof categories)[number];

const DeviceSelector = (props: {
  kind: "audioinput" | "videoinput";
  devices: MediaDeviceInfo[];
}) => {
  const [call, setCall] = useCall();

  const onChangeDevice = useCallback(
    async (event: ChangeEvent<HTMLSelectElement>) => {
      const deviceId = event.target.value;
      if (props.kind === "audioinput") {
        await call.localMicrophoneTrack?.setDevice(deviceId);
        setCall((prevState) => ({
          ...prevState,
          selectedAudioDeviceID: deviceId,
        }));
      }
      if (props.kind === "videoinput") {
        await call.localCameraTrack?.setDevice(deviceId);
        setCall((prevState) => ({
          ...prevState,
          selectedVideoDeviceID: deviceId,
        }));
      }
    },
    [props.kind, call.localMicrophoneTrack, call.localCameraTrack, setCall]
  );

  return (
    <div className="DeviceSelector">
      <select onChange={onChangeDevice}>
        {props.devices.map((device) => (
          <option
            key={device.deviceId}
            selected={
              device.deviceId === call.selectedAudioDeviceID ||
              device.deviceId === call.selectedVideoDeviceID
            }
            value={device.deviceId}
          >
            {device.label}
          </option>
        ))}
      </select>
    </div>
  );
};

const SettingsModal = () => {
  const [selectedCategory, setSelectedCategory] = useState<Category>("audio");

  const selectCategory = useCallback((category: Category) => {
    setSelectedCategory(category);
  }, []);

  const [call] = useCall();
  const [layout, setLayout] = useLayout();
  const [, setUser] = useUser();
  const [audioDevices, setAudioDevices] = useState<MediaDeviceInfo[]>([]);
  const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>([]);
  const localParticipant = useLocalParticipant();
  const mirrorTrack = useMirrorTrack();

  useEffect(() => {
    const getDevices = async () => {
      if (call.localMicrophoneTrack) {
        // Get all devices
        let devices = await navigator.mediaDevices.enumerateDevices();
        setAudioDevices(
          devices.filter((device) => device.kind === "audioinput")
        );
        setVideoDevices(
          devices.filter((device) => device.kind === "videoinput")
        );
      }
    };
    getDevices();
    return () => {};
  }, [call.localMicrophoneTrack]);

  const getCategoryName = useCallback((category: Category): string => {
    switch (category) {
      case "audio":
        return "Audio";
      case "video":
        return "Video";
      case "filters":
        return "Video Background";
      case "text-speed":
        return "Text Speed";
      case "dub":
        return "AI Voice";
    }
  }, []);

  const updateDubVoiceGender = useCallback(
    async (voiceGender: "male" | "female" | "default") => {
      const response = await api.updateUser({ user: { voiceGender } });
      setUser((prevState) =>
        prevState ? { ...prevState, ...response.user } : prevState
      );
    },
    [setUser]
  );

  const getCategoryContent = useCallback(
    (category: Category) => {
      switch (category) {
        case "audio":
          return (
            <div className="CategoryContent">
              <div>{"Audio Input (Microphone)"}</div>
              <DeviceSelector kind="audioinput" devices={audioDevices} />
            </div>
          );
        case "video":
          return (
            <div className="CategoryContent">
              <div>{"Video Input (Camera)"}</div>
              <DeviceSelector kind="videoinput" devices={videoDevices} />
              <div className="CameraPreview">
                {localParticipant && (
                  <Stream
                    user={localParticipant}
                    videoTrack={mirrorTrack}
                    audioTrack={call.localMicrophoneTrack}
                    mirror={true}
                    local={true}
                  />
                )}
              </div>
            </div>
          );
        case "dub":
          return (
            <div className="CategoryContent">
              <div className="Dub">
                <div>AI Voice Gender</div>
                <div className="Subtitle">
                  Please note that this is the voice of the AI interpreter that
                  will represent you in the call
                </div>
                <div className="DubVoiceGender">
                  {["Default", "Male", "Female"].map((gender, index) => (
                    <div
                      key={index}
                      className={`${
                        localParticipant?.voiceGender === gender.toLowerCase()
                          ? "Selected"
                          : ""
                      }`}
                      onClick={() => {
                        switch (gender) {
                          case "Male":
                            updateDubVoiceGender("male");
                            break;
                          case "Female":
                            updateDubVoiceGender("female");
                            break;
                          default:
                            updateDubVoiceGender("default");
                            break;
                        }
                        amplitude?.logEvent(
                          AmplitudeEvent.CHANGE_AI_VOICE_GENDER,
                          { gender }
                        );
                      }}
                    >
                      {gender}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          );
        case "filters":
          return (
            <div className="CategoryContent">
              <div>Video Background</div>
              <div className="CameraPreview">
                {localParticipant && (
                  <Stream
                    user={localParticipant}
                    videoTrack={mirrorTrack}
                    audioTrack={call.localMicrophoneTrack}
                    mirror={true}
                    local={true}
                  />
                )}
              </div>
              <Filters />
            </div>
          );
        case "text-speed":
          return (
            <div className="CategoryContent">
              <div className="TextSpeed">
                Text Speed
                <div className="TextSpeedSlider">
                  Live
                  <input
                    type="range"
                    min={0}
                    max={5}
                    value={layout.textSpeed}
                    onChange={(e) => {
                      const speed = Number(e.target.value);
                      localStorage.setItem(
                        LocalStorageKeys.TEXT_SPEED,
                        speed.toString()
                      );
                      setLayout((prevState) => ({
                        ...prevState,
                        textSpeed: speed,
                      }));
                    }}
                  />
                  5s
                </div>
              </div>
            </div>
          );
      }
    },
    [
      audioDevices,
      videoDevices,
      localParticipant,
      mirrorTrack,
      call.localMicrophoneTrack,
      updateDubVoiceGender,
      setLayout,
      layout.textSpeed,
    ]
  );

  return (
    <div className="SettingsModal">
      <div className="Categories">
        {categories.map((category) => (
          <div
            key={category}
            onClick={() => selectCategory(category)}
            className={
              category === selectedCategory ? "SelectedCategory" : undefined
            }
          >
            {getCategoryName(category)}
          </div>
        ))}
      </div>
      {getCategoryContent(selectedCategory)}
    </div>
  );
};

export default SettingsModal;
