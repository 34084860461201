import {
  Caption,
  ReceiveCaptionArgs,
  SetTranslationProviderArgs,
  speechLanguageLocaleToLanguageLocale,
  Participant,
  ReceiveCorrectedCaptionArgs,
  ProviderState,
} from "byrdhouse-types";
import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { GlobalContext } from "../context";
import { DisplayCaption, HistoryCaption } from "../types";
import useCall from "./call";
import useSocket from "./socket";
import {
  useCurrentLanguage,
  useGetParticipantCurrentLanguage,
  useLocalParticipant,
} from "./user";
import useLayout from "./layout";

const useTranscript = () => {
  let context = useContext(GlobalContext);
  return [context.transcript, context.setTranscript] as const;
};

export const useAddCaption = () => {
  const [, setTranscript] = useTranscript();
  const localParticipant = useLocalParticipant();
  const currentLanguage = useCurrentLanguage();
  const getParticipantCurrentLanguage = useGetParticipantCurrentLanguage();

  // const prevLanguage = useRef<SpeechLanguageLocale | undefined>(undefined);
  // const numSkipsToLength = useRef(0);

  return useCallback(
    (caption: Caption, from: Participant | undefined) => {
      if (!localParticipant || !currentLanguage) {
        return;
      }

      setTranscript((prevState) => {
        if (caption.state === "recognizing") {
          const oc = prevState.originalCaptions[caption.id]?.caption;
          // If caption already exists as recognized or is late, ignore
          if (
            oc &&
            (oc.state === "recognized" ||
              new Date(oc.timestamp).getTime() >=
                new Date(caption.timestamp).getTime())
          ) {
            // console.debug(
            //   "Ignoring recognizing update for caption id",
            //   caption.id
            // );
            return prevState;
          }
        }

        const localParticipantLanguage =
          speechLanguageLocaleToLanguageLocale(currentLanguage);

        const remoteParticipantSpeechLanguage =
          from && getParticipantCurrentLanguage(from);
        const remoteParticipantLanguage =
          prevState.history[caption.id]?.sourceLanguage ||
          (remoteParticipantSpeechLanguage
            ? speechLanguageLocaleToLanguageLocale(
                remoteParticipantSpeechLanguage
              )
            : undefined);

        // Only update recognizing caption display text when new event text is longer
        // if (
        //   caption.state === "recognizing" &&
        //   prevLanguage.current === currentLanguage &&
        //   numSkipsToLength.current < 1 &&
        //   (prevState.interimCaptions[caption.id]?.text.length || 0) >
        //     (caption.translations[localParticipantLanguage]?.length || 0)
        // ) {
        //   numSkipsToLength.current++;
        //   // console.debug(
        //   //   "Ignoring shorter translation for caption id",
        //   //   caption.id
        //   // );
        //   return prevState;
        // }
        // // Handle too many skips to length
        // numSkipsToLength.current = 0;
        // // Handle language switching mid caption
        // prevLanguage.current = currentLanguage;

        // When from is undefined here it means the user left during caption in progress
        const displayCaption: DisplayCaption | undefined = from
          ? {
              ...caption,
              from,
              text: caption.translations[localParticipantLanguage],
              language: localParticipantLanguage,
            }
          : prevState.interimCaptions[caption.id]
          ? {
              ...prevState.interimCaptions[caption.id],
              text: caption.translations[localParticipantLanguage],
            }
          : undefined;

        const historyCaption: HistoryCaption | undefined =
          from && remoteParticipantLanguage
            ? {
                id: caption.id,
                from: from,
                sourceLanguage: remoteParticipantLanguage,
                sourceLanguageText:
                  caption.translations[remoteParticipantLanguage],
                targetLanguage: localParticipantLanguage,
                targetLanguageText:
                  caption.translations[localParticipantLanguage],
              }
            : prevState.history[caption.id]
            ? {
                ...prevState.history[caption.id],
                sourceLanguageText:
                  caption.translations[
                    prevState.history[caption.id].sourceLanguage
                  ],
                targetLanguageText:
                  caption.translations[
                    prevState.history[caption.id].targetLanguageText
                  ],
              }
            : undefined;

        const originalCaption = from
          ? {
              caption,
              from: from.sessionId,
            }
          : prevState.originalCaptions[caption.id]
          ? {
              caption,
              from: prevState.originalCaptions[caption.id].from,
            }
          : undefined;

        const shouldUpdateCaption =
          !!originalCaption &&
          !!historyCaption &&
          !!displayCaption &&
          !!displayCaption.text &&
          !!displayCaption.text.length;
        if (!shouldUpdateCaption) {
          return prevState;
        }

        if (caption.state === "recognizing") {
          // Update the interim caption
          return {
            ...prevState,
            originalCaptions: {
              ...prevState.originalCaptions,
              [caption.id]: originalCaption,
            },
            history: {
              ...prevState.history,
              [caption.id]: historyCaption,
            },
            interimCaptions: {
              ...prevState.interimCaptions,
              [displayCaption.id]: displayCaption,
            },
          };
        } else {
          // Remove any interim captions and replace with full caption
          const { [caption.id]: omit, ...rest } = prevState.interimCaptions;
          return {
            ...prevState,
            originalCaptions: {
              ...prevState.originalCaptions,
              [caption.id]: originalCaption,
            },
            history: {
              ...prevState.history,
              [caption.id]: historyCaption,
            },
            interimCaptions: rest,
            captions: {
              ...prevState.captions,
              [displayCaption.id]: displayCaption,
            },
          };
        }
      });
    },
    [
      setTranscript,
      localParticipant,
      currentLanguage,
      getParticipantCurrentLanguage,
    ]
  );
};

// On remote user sent caption
export const useListenForRemoteCaptions = () => {
  const socket = useSocket();
  const [call] = useCall();
  const localParticipant = useLocalParticipant();
  const addCaption = useAddCaption();
  const [, setTranscript] = useTranscript();
  const currentLanguage = useCurrentLanguage();
  const [layout] = useLayout();
  const lastCaptionAdded = useRef(Date.now());

  return useEffect(() => {
    socket?.on("send_caption", (args: ReceiveCaptionArgs) => {
      if (!localParticipant) {
        return;
      }
      // Get self/remote peer that sent the caption
      const caption = args.caption;
      const from: Participant | undefined =
        localParticipant.sessionId === args.from
          ? localParticipant
          : call.remotePeers[args.from];

      if (
        Math.abs(Date.now() - lastCaptionAdded.current) <
          layout.textSpeed * 1000 &&
        caption.state !== "recognized"
      ) {
        return;
      }
      lastCaptionAdded.current = Date.now();

      addCaption(caption, from);

      // Get text in current language
      if (!currentLanguage) {
        return;
      }
      const language = speechLanguageLocaleToLanguageLocale(currentLanguage);
      const text = caption.translations[language];

      if (!text) {
        return;
      }

      // Log caption confidence to debug
      // if (args.confidence) {
      //   console.debug(
      //     `Translator is ${(args.confidence * 100).toFixed(
      //       2
      //     )}% confident "${text}" is correct.`
      //   );
      // }
    });

    return () => {
      socket?.off("send_caption");
    };
  }, [
    addCaption,
    call.remotePeers,
    socket,
    localParticipant,
    currentLanguage,
    setTranscript,
    layout.textSpeed,
  ]);
};

export const useListenForCaptionCorrection = () => {
  const socket = useSocket();
  const [, setTranscript] = useTranscript();
  const localParticipant = useLocalParticipant();
  const [call] = useCall();
  return useEffect(() => {
    socket?.on("correct_caption", (args: ReceiveCorrectedCaptionArgs) => {
      const caption = args.caption;
      if (!localParticipant) {
        return;
      }

      setTranscript((prevState) => {
        if (!prevState.captions[caption.id] || !prevState.history[caption.id]) {
          return prevState;
        }

        return {
          ...prevState,
          captions: {
            ...prevState.captions,
            [caption.id]: {
              ...prevState.captions[caption.id],
              text: caption.translations[
                prevState.captions[caption.id].language
              ],
              corrected: true,
            },
          },
          history: {
            ...prevState.history,
            [caption.id]: {
              ...prevState.history[caption.id],
              sourceLanguageText:
                caption.translations[
                  prevState.history[caption.id].sourceLanguage
                ],
              targetLanguageText:
                caption.translations[
                  prevState.history[caption.id].targetLanguage
                ],
            },
          },
        };
      });
    });
    return () => {
      socket?.off("correct_caption");
    };
  }, [socket, localParticipant, call.remotePeers, setTranscript]);
};

export const useListenForTranslationProviderUpdate = () => {
  const socket = useSocket();
  const [, setCall] = useCall();

  const setCallHasTranslationProvider = useCallback(
    (translationProvider: ProviderState) => {
      setCall((prevState) => ({
        ...prevState,
        translationProvider,
      }));
    },
    [setCall]
  );

  return useEffect(() => {
    socket?.on(
      "set_translation_provider",
      (args: SetTranslationProviderArgs) => {
        setCallHasTranslationProvider(args.providerState);
      }
    );

    return () => {
      socket?.off("set_translation_provider");
    };
  }, [socket, setCallHasTranslationProvider, setCall]);
};

export const useLanguageLocaleDisplayNames = () => {
  return useMemo(() => {
    return new Intl.DisplayNames(["en"], { type: "language" });
  }, []);
};

export const useGetEditedLocalizedText = () => {
  const localParticipant = useLocalParticipant();
  return useCallback(() => {
    if (!localParticipant) {
      return "Edited";
    }
    const locale = speechLanguageLocaleToLanguageLocale(
      localParticipant.speechLanguageLocale
    );
    switch (locale) {
      case "af":
        return "Redizgeer";
      case "sq":
        return "Përpunuar";
      case "am":
        return "ማሻሻያ፣ ተመርጧል";
      case "ar":
        return "تم تحريره";
      case "hy":
        return "Խմբագրվել";
      case "az":
        return "Redaktə edilib";
      case "eu":
        return "Aldatuta";
      case "bn":
        return "সম্পাদিত";
      case "bs":
        return "Uređeno";
      case "bg":
        return "Редактирано";
      case "my":
        return "တပ်ထားတာ";
      case "ca":
        return "Editat";
      case "zh-Hans":
        return "编辑";
      case "zh-Hant":
        return "編輯";
      case "hr":
        return "Uređeno";
      case "cs":
        return "Upraveno";
      case "da":
        return "Redigeret";
      case "nl":
        return "Bewerkt";
      case "en":
        return "Edited";
      case "et":
        return "Redigeeritud";
      case "fil":
        return "Binago";
      case "fi":
        return "Muokattu";
      case "fr":
        return "Modifié";
      case "fr-CA":
        return "Modifié";
      case "gl":
        return "Editado";
      case "ka":
        return "შეცვლილი";
      case "de":
        return "Bearbeitet";
      case "el":
        return "Τροποποιήθηκε";
      case "gu":
        return "સંપાદિત";
      case "he":
        return "ערוך";
      case "hi":
        return "संपादित";
      case "hu":
        return "Szerkesztve";
      case "is":
        return "Breytt";
      case "id":
        return "Diedit";
      case "ga":
        return "Eagraithe";
      case "it":
        return "Modificato";
      case "ja":
        return "編集済み";
      case "jv":
        return "Ditambah";
      case "kn":
        return "ಸಂಪಾದಿಸಲಾಗಿದೆ";
      case "kk":
        return "Өзгертілді";
      case "km":
        return "កែប្រែ";
      case "ko":
        return "편집됨";
      case "lo":
        return "ແກ້ໄຂ";
      case "lv":
        return "Rediģēts";
      case "lt":
        return "Redaguota";
      case "mk":
        return "Уредено";
      case "ms":
        return "Diedit";
      case "mt":
        return "Editat";
      case "mr":
        return "संपादित";
      case "mn-Mong":
        return "Засварласан";
      case "ne":
        return "सम्पादित";
      case "nb":
        return "Redigert";
      case "fa":
        return "ویرایش شده";
      case "pl":
        return "Edytowane";
      case "pt":
        return "Editado";
      case "pt-PT":
        return "Editado";
      default:
        return "Edited";
    }
  }, [localParticipant]);
};

export default useTranscript;
