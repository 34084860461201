import useCall, {
  useCurrentSpeaker,
  useEndScreenShare,
  useRemotePeerScreenSharing,
  useSubscribeToRemotePeerVideo,
} from "../hooks/call";
import "./CurrentStream.css";
import Stream from "./Stream";
import { useEffect, useMemo, useState } from "react";
import { useLocalParticipant } from "../hooks/user";
import { ILocalVideoTrack, IRemoteVideoTrack } from "agora-rtc-sdk-ng";
import { FiShare } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { ReactComponent as RecordStatus } from "../assets/record-red.svg";
import { useToggleConfirmRecordingActionModal } from "../hooks/layout";
import { useWindowSize } from "react-use";

const CurrentStream = () => {
  const remotePeerScreenSharing = useRemotePeerScreenSharing();
  const currentSpeaker = useCurrentSpeaker();
  const subscribeToRemotePeerVideo = useSubscribeToRemotePeerVideo();
  const [call] = useCall();
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 1000;
  const localParticipant = useLocalParticipant();

  // Always subscribe to the current speaker
  useEffect(() => {
    if (call.remotePeers[currentSpeaker.sessionId]) {
      if (remotePeerScreenSharing?.sessionId === currentSpeaker.sessionId) {
        console.debug("Subscribe to current speaker screen share...");
        subscribeToRemotePeerVideo(currentSpeaker.sessionId, true);
      } else {
        subscribeToRemotePeerVideo(currentSpeaker.sessionId, false);
      }
    }

    return () => {};
  }, [
    call.remotePeers,
    currentSpeaker,
    subscribeToRemotePeerVideo,
    remotePeerScreenSharing,
  ]);

  // Get current speaker audio track
  const audioTrack = useMemo(() => {
    return call.remotePeers[currentSpeaker.sessionId]
      ? call.remoteTracks[currentSpeaker.sessionId]?.audioTrack
      : call.localMicrophoneTrack;
  }, [
    call.remotePeers,
    currentSpeaker.sessionId,
    call.remoteTracks,
    call.localMicrophoneTrack,
  ]);

  // Get current speaker video track
  const [videoTrack, setVideoTrack] = useState<
    ILocalVideoTrack | IRemoteVideoTrack | undefined
  >();
  useEffect(() => {
    let track = call.remotePeers[currentSpeaker.sessionId]
      ? call.remoteTracks[currentSpeaker.sessionId]?.videoTrack
      : call.localScreenShareTrack || call.localCameraTrack;

    setVideoTrack(track);

    return () => {};
  }, [
    call.remotePeers,
    currentSpeaker.sessionId,
    call.remoteTracks,
    call.localScreenShareTrack,
    call.localCameraTrack,
  ]);

  const endScreenShare = useEndScreenShare();
  const toggleConfirmRecordingActionModal =
    useToggleConfirmRecordingActionModal();
  if (call.localScreenShareTrack) {
    return (
      <div className="CurrentStream">
        <div className="LocalScreenShare">
          <FiShare />
          <span>You are sharing your screen</span>
          <button className="StopScreenShare" onClick={endScreenShare}>
            <IoClose />
            Stop Sharing
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="CurrentStream">
        <Stream
          audioTrack={audioTrack}
          videoTrack={videoTrack}
          user={currentSpeaker}
          mirror={
            currentSpeaker.sessionId === localParticipant?.sessionId &&
            !call.localScreenShareTrack
          }
          fit="contain"
          screenShareLoading={call.screenShareLoading}
          local={currentSpeaker.sessionId === localParticipant?.sessionId}
          isSpeaker={true}
        />
        {call.recording && (
          <div className="RecordingStatus">
            <RecordStatus />
            Recording in progress
            {!isMobile && (
              <span onClick={toggleConfirmRecordingActionModal}>Stop</span>
            )}
          </div>
        )}
      </div>
    );
  }
};

export default CurrentStream;
