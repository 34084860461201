import React from "react";
import { Link } from "react-router-dom";
import "./Breadcrumbs.css";

export type BreadcrumbsProps = {
  crumbs: {
    label: string;
    link: string;
  }[];
};

const Breadcrumbs = (props: BreadcrumbsProps) => {
  return (
    <div className="Breadcrumbs">
      {props.crumbs.map((crumb, index) => {
        const link = (
          <Link key={index} to={crumb.link}>
            {crumb.label}
          </Link>
        );
        if (index % 1 === 0 && index !== props.crumbs.length - 1) {
          return (
            <React.Fragment key={index + "-slashed"}>
              {link}
              <div>{"/"}</div>
            </React.Fragment>
          );
        }
        return link;
      })}
    </div>
  );
};

export default Breadcrumbs;
