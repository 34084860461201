import { useCallback, useContext, useMemo } from "react";
import { GlobalContext, initialSessionState } from "../context";
import amplitude from "../amplitude";
import { AmplitudeEvent, Participant } from "byrdhouse-types";
import { auth } from "../firebase";
import { initialCallState, initialUserState } from "../context";
import useCall from "./call";
import useSession from "./session";

const useUser = () => {
  let context = useContext(GlobalContext);
  return [context.user, context.setUser] as const;
};

export const useSignOut = () => {
  const [, setUser] = useUser();
  const [, setSession] = useSession();
  const [call, setCall] = useCall();

  return useCallback(async () => {
    // Log in amplitude
    amplitude?.logEvent(AmplitudeEvent.CLICK_ON_SIGN_OUT);
    // Sign out with firebase
    await auth.signOut();
    // Clear local state
    localStorage.clear();
    setUser(initialUserState);
    setSession(initialSessionState);

    console.debug(call.localMicrophoneTrack, call.localCameraTrack);

    call.localMicrophoneTrack?.close();
    call.localCameraTrack?.close();
    call.processedVirtualTrack?.stop();

    setCall(initialCallState);
  }, [
    setUser,
    setSession,
    call.localMicrophoneTrack,
    call.localCameraTrack,
    call.processedVirtualTrack,
    setCall,
  ]);
};

export const useLocalParticipant = () => {
  const [user] = useUser();
  const [session] = useSession();
  return useMemo((): Participant | undefined => {
    if (!user || !session.id) {
      return undefined;
    }
    return {
      userId: user.id,
      sessionId: session.id,
      displayName: user.displayName,
      speechLanguageLocale: user.speechLanguageLocale,
      timezone: user.timezone,
      muted: user.muted,
      screenSharing: user.screenSharing,
      culturalContext: user.culturalContext,
      voiceGender: user.voiceGender,
      autoDetect: user.autoDetect,
      detectedLanguage: user.detectedLanguage,
      providerId: user.providerId,

      // Translation minutes
      prepaidTranslationSecondsRemaining:
        user.prepaidTranslationSecondsRemaining,
      monthlyTranslationSecondsRemaining:
        user.monthlyTranslationSecondsRemaining,
      monthlyTranslationSeconds: user.monthlyTranslationSeconds,

      // Feature flags
      groupCallEnabled: user.groupCallEnabled,
      recordingEnabled: user.recordingEnabled,
      voiceToVoiceEnabled: user.voiceToVoiceEnabled,
      maxMembers: user.maxMembers,

      pendingMembers: user.pendingMembers,

      plan: user.plan,

      profanityFilter: user.profanityFilter,
    };
  }, [user, session]);
};

export const useCurrentLanguage = () => {
  const localParticipant = useLocalParticipant();
  return useMemo(() => {
    if (!localParticipant) {
      return;
    }
    if (!localParticipant?.autoDetect) {
      return localParticipant.speechLanguageLocale;
    }
    if (!localParticipant.detectedLanguage) {
      return localParticipant.speechLanguageLocale;
    }
    return localParticipant.detectedLanguage;
  }, [localParticipant]);
};

export const useGetParticipantCurrentLanguage = () => {
  return useCallback((participant: Participant) => {
    if (!participant) {
      return;
    }
    if (!participant?.autoDetect) {
      return participant.speechLanguageLocale;
    }
    if (!participant.detectedLanguage) {
      return participant.speechLanguageLocale;
    }
    return participant.detectedLanguage;
  }, []);
};

export default useUser;
