import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";

// Import sentry
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://3ae56b31da124e1eacb02888bc9cf6a5@o4503929032998912.ingest.sentry.io/4503929036996608",
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
    integrations: [
      new Sentry.Replay({
        networkDetailAllowUrls: [process.env.REACT_APP_BYRDHOUSE_API_URL!],
      }),
      new Sentry.BrowserTracing({
        tracePropagationTargets: [process.env.REACT_APP_BYRDHOUSE_API_URL!],
      }),
    ],
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
