import { useCallback, useEffect, useRef } from "react";
import { initialLayoutState } from "../context";
import useLayout, {
  useAtLeastTwoPanelsEnabled,
  useToggleCaptions,
} from "../hooks/layout";
import { PanelVisibilityState } from "../types";
import Dropdown, { DropdownHandle } from "./Dropdown";
import "./PanelManager.css";
import amplitude from "../amplitude";
import { AmplitudeEvent } from "byrdhouse-types";

const PanelManager = () => {
  useEffect(() => {
    amplitude?.logEvent(AmplitudeEvent.CLICK_ON_LAYOUT);
  }, []);

  const dropdownRef = useRef<DropdownHandle>(null);
  const Content = () => {
    const [layout, setLayout] = useLayout();

    const atLeastTwoPanelsEnabled = useAtLeastTwoPanelsEnabled();

    const toggleCaptions = useToggleCaptions();
    const toggleTranscript = useCallback(() => {
      toggleCaptions();
      dropdownRef.current?.toggleContent();
    }, [toggleCaptions]);

    const toggleSpeaker = useCallback(() => {
      setLayout((prevState) => ({
        ...prevState,
        panels: {
          ...prevState.panels,
          speaker: {
            ...prevState.panels.speaker,
            visible: atLeastTwoPanelsEnabled
              ? !prevState.panels.speaker.visible
              : true,
          },
          transcript: {
            ...prevState.panels.transcript,
            visibility: prevState.panels.speaker.visible
              ? PanelVisibilityState.DOCKED
              : prevState.panels.transcript.visibility,
          },
        },
      }));
      dropdownRef.current?.toggleContent();
    }, [setLayout, atLeastTwoPanelsEnabled]);

    const resetDisplay = useCallback(() => {
      setLayout((prevState) => ({
        ...initialLayoutState,
        attendeeStreamSizeInPixels: prevState.attendeeStreamSizeInPixels,
        shownAttendees: prevState.shownAttendees,
      }));
      dropdownRef.current?.toggleContent();
    }, [setLayout]);

    return (
      <div className="PanelManagerContent">
        <div className="PanelManagerSubtitle">Panel Display</div>
        <div onClick={toggleSpeaker} className="PanelToggle">
          <input type="checkbox" checked={layout.panels.speaker.visible} />
          Participants
        </div>
        <div onClick={toggleTranscript} className="PanelToggle">
          <input type="checkbox" checked={layout.panels.transcript.visible} />
          Captions
        </div>
        <div className="ResetPanels" onClick={resetDisplay}>
          Reset Panel Layout
        </div>
      </div>
    );
  };

  return (
    <Dropdown
      ref={dropdownRef}
      title="Layout"
      toggleStyle={{ border: "1px solid #323232" }}
    >
      <Content />
    </Dropdown>
  );
};

export default PanelManager;
