import "./Toolbar.css";

import { IoAdd, IoLanguage, IoPeople } from "react-icons/io5";
import { ImPhoneHangUp } from "react-icons/im";
import { MdOutlineSubtitles, MdOutlineSubtitlesOff } from "react-icons/md";
import { TbScreenShare, TbScreenShareOff } from "react-icons/tb";
import useCall, {
  useOpenHostControls,
  useRemotePeerScreenSharing,
  useRenderHostControls,
  useStartScreenShare,
} from "../hooks/call";
import useSocket from "../hooks/socket";
import LanguageSelect from "./LanguageSelect";
import { useLocalParticipant } from "../hooks/user";
import { Participant, ReceiveMessageArgs } from "byrdhouse-types";
import useChat from "../hooks/chat";
import { useEffect } from "react";
import Modal from "react-modal";
import HostControls from "./HostControls";
import PanelManager from "./PanelManager";
import useLayout, {
  useOpenConfirmLeaveCall,
  useToggleCaptions,
  useToggleChat,
  useToggleConfirmRecordingActionModal,
  useToggleInviteModal,
  useToggleMobileLanguageSelect,
} from "../hooks/layout";
import ToolbarOverflow from "./ToolbarOverflow";
import MediaSelector from "./MediaSelector";
import { ReactComponent as StartRecord } from "../assets/record-gray.svg";
import { ReactComponent as StopRecord } from "../assets/record-red.svg";
import { BsChatLeftDots, BsChatLeftDotsFill } from "react-icons/bs";
import { useWindowSize } from "react-use";
import { ReactComponent as DubOn } from "../assets/dub-on.svg";
import { ReactComponent as DubOff } from "../assets/dub-off.svg";
import { useToggleDub } from "../hooks/dub";

const Toolbar = () => {
  const socket = useSocket();
  const [call] = useCall();
  const [chat, setChat] = useChat();
  const localParticipant = useLocalParticipant();
  const [layout] = useLayout();
  const windowSize = useWindowSize();

  // On receive chat message
  useEffect(() => {
    socket?.on("send_message", (args: ReceiveMessageArgs) => {
      // Get the user from remote peers dict
      const from: Participant | undefined =
        args.from === localParticipant?.sessionId
          ? localParticipant
          : call.remotePeers[args.from];

      if (!from) {
        return;
      }

      setChat((prevState) => ({
        ...prevState,
        messages: [
          ...prevState.messages,
          {
            ...args.message,
            from,
          },
        ],
        numUnreadMessages: !layout.panels.chat.visible
          ? prevState.numUnreadMessages + 1
          : 0,
      }));
    });

    return () => {
      socket?.off("send_message");
    };
  }, [
    socket,
    setChat,
    localParticipant,
    call.remotePeers,
    layout.panels.chat.visible,
  ]);

  const remotePeerScreenSharing = useRemotePeerScreenSharing();

  const startScreenShare = useStartScreenShare();
  const toggleCaptions = useToggleCaptions();

  const toggleDub = useToggleDub();

  const toggleConfirmRecordingActionModal =
    useToggleConfirmRecordingActionModal();

  const renderHostControls = useRenderHostControls();
  const openHostControls = useOpenHostControls();

  const toggleChat = useToggleChat();

  const toggleInviteModal = useToggleInviteModal();
  const openConfirmLeaveCall = useOpenConfirmLeaveCall();

  const toggleMobileLanguageSelect = useToggleMobileLanguageSelect();

  const showMobileToolbar = windowSize.width <= 1000;

  if (showMobileToolbar) {
    return (
      <div className="MobileToolbar">
        {layout.showMobileLanguageSelect ? (
          <div className="MobileLanguageSelect">
            <LanguageSelect />
          </div>
        ) : null}
        <div className="Controls">
          <MediaSelector precall={false} mobile={true} />
          <div className="Icon" onClick={toggleMobileLanguageSelect}>
            <IoLanguage />
          </div>
          {call.translationProvider.voiceToVoiceEnabled && (
            <div className="Icon" onClick={toggleDub}>
              {call.dub ? <DubOn /> : <DubOff />}
            </div>
          )}
          <div className="Icon" onClick={openConfirmLeaveCall}>
            <ImPhoneHangUp />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="Toolbar">
        <Modal
          isOpen={!!call.showHostControls}
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              borderRadius: "10px",
              backgroundColor: "#282828",
              overflow: "visible",
            },
            overlay: {
              zIndex: 999,
            },
          }}
        >
          <HostControls />
        </Modal>

        <div className="LeftControls">
          <PanelManager />
          <LanguageSelect />
        </div>

        <div className="Controls">
          <MediaSelector precall={false} />
          <div
            className={`Icon ScreenShare ${
              remotePeerScreenSharing ? "Disabled" : ""
            }`}
            onClick={startScreenShare}
          >
            {call.localScreenShareTrack ? (
              <TbScreenShareOff />
            ) : (
              <TbScreenShare />
            )}
            <div>Share</div>
          </div>
          {/* Enable recordings for pro users and orgs */}
          {call.translationProvider.recordingEnabled &&
            !!Object.values(call.remotePeers).length && (
              <div className="Icon" onClick={toggleConfirmRecordingActionModal}>
                {call.recording ? <StopRecord /> : <StartRecord />}
                <div>Record</div>
              </div>
            )}
          <div className="Icon" onClick={toggleCaptions}>
            {layout.panels.transcript.visible ? (
              <MdOutlineSubtitles />
            ) : (
              <MdOutlineSubtitlesOff />
            )}
            <div>Captions</div>
          </div>
          {call.translationProvider.voiceToVoiceEnabled && (
            <div className="Icon" onClick={toggleDub}>
              {call.dub ? <DubOn /> : <DubOff />}
              <div>AI Voice</div>
            </div>
          )}
          {localParticipant && renderHostControls(localParticipant) && (
            <div className="Icon" onClick={openHostControls}>
              <IoPeople />
              <div>Host</div>
            </div>
          )}
          <div className="Icon" onClick={toggleChat}>
            {layout.panels.chat.visible ? (
              <BsChatLeftDotsFill color="#e76399" />
            ) : (
              <BsChatLeftDots />
            )}
            {chat.numUnreadMessages > 0 ? (
              <div className="UnreadChat">{chat.numUnreadMessages}</div>
            ) : null}
            <div>Chat</div>
          </div>
          <ToolbarOverflow precall={false} />
        </div>

        <div className="RightControls">
          <button className="Invite" onClick={toggleInviteModal}>
            <IoAdd /> Invite
          </button>
          <button className="LeaveCall" onClick={openConfirmLeaveCall}>
            <ImPhoneHangUp />
            Leave Meeting
          </button>
        </div>
      </div>
    );
  }
};

export default Toolbar;
