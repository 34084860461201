import amplitude, { AmplitudeClient } from "amplitude-js";

let client: AmplitudeClient | undefined;
if (
  process.env.REACT_APP_AMPLITUDE_API_KEY &&
  process.env.REACT_APP_AMPLITUDE_API_KEY.length &&
  process.env.NODE_ENV === "production"
) {
  client = amplitude.getInstance();
  client.init(process.env.REACT_APP_AMPLITUDE_API_KEY!);
}

export default client;
