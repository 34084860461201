import { useCallback } from "react";
import { useLeaveCall } from "../../hooks/call";
import { useCloseModal } from "../../hooks/layout";
import Button from "../Button";
import "./ConfirmLeaveMeetingModal.css";

const ConfirmLeaveMeetingModal = () => {
  const closeModal = useCloseModal();
  const leaveCall = useLeaveCall();
  const clickLeaveCall = useCallback(() => {
    leaveCall({ intentional: true });
  }, [leaveCall]);
  return (
    <div className="ConfirmLeaveMeetingModal">
      <div className="ModalSubtext">
        Others will continue after you leave. You can join the nest again.
      </div>
      <div className="Buttons">
        <Button text="Cancel" onClick={closeModal} />
        <Button text="Confirm" background="gradient" onClick={clickLeaveCall} />
      </div>
    </div>
  );
};

export default ConfirmLeaveMeetingModal;
