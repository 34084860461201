import { useCallback } from "react";

export const useGetInitials = () => {
  return useCallback((displayName: string) => {
    return displayName
      .split(" ")
      .slice(0, 2)
      .filter((name) => name && name.length)
      .map((name) => name[0].toUpperCase())
      .join("");
  }, []);
};
