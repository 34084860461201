import "./ToolbarOverflow.css";
import { IoEllipsisHorizontal } from "react-icons/io5";
import { useCallback, useState } from "react";
import {
  usePipSupported,
  useTogglePipOn,
  useToggleSettingsModal,
} from "../hooks/layout";

export type ToolbarOverflowProps = {
  precall: boolean;
  mobile?: boolean;
};

const ToolbarOverflow = (props: ToolbarOverflowProps) => {
  const [showContent, setShowContent] = useState<boolean>(false);
  const toggle = useCallback(() => {
    setShowContent((prevState) => !prevState);
  }, []);
  const toggleSettingsModal = useToggleSettingsModal();
  const pipSupported = usePipSupported();
  const togglePipOn = useTogglePipOn();

  return (
    <div className="ToolbarOverflow Icon" onClick={toggle}>
      {showContent && (
        <div className="ToolbarOverflowContent">
          {pipSupported && <span onClick={togglePipOn}>Popup View</span>}
          <span onClick={toggleSettingsModal}>Settings</span>
        </div>
      )}
      <IoEllipsisHorizontal />
      {!props.precall && !props.mobile ? <div>More</div> : null}
    </div>
  );
};

export default ToolbarOverflow;
