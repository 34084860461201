import { Participant } from "byrdhouse-types";
import { useCallback, useState } from "react";
// import { AiFillPushpin } from "react-icons/ai";
import {
  IoChevronDown,
  IoMic,
  IoMicOff,
  IoPeople,
  IoTrash,
} from "react-icons/io5";
import useCall, {
  useMuteAllUsers,
  useMuteUser,
  // usePinSpeaker,
  useReassignHost,
  useRemoveUser,
} from "../hooks/call";

const HostControlsButtons = (props: { user?: Participant }) => {
  const [call] = useCall();

  const muteAllUsers = useMuteAllUsers();

  // const pinSpeakerHook = usePinSpeaker();
  // const pinSpeaker = useCallback(() => {
  //   if (props.user) {
  //     if (call.pinnedSpeaker === props.user.id) {
  //       // Unpin the user
  //       pinSpeakerHook(undefined);
  //     } else {
  //       // Pin the user
  //       pinSpeakerHook(props.user);
  //     }
  //   }
  // }, [props.user, call, pinSpeakerHook]);

  const muteUserHook = useMuteUser();
  const muteUser = useCallback(() => {
    if (props.user) {
      muteUserHook(props.user);
    }
  }, [props.user, muteUserHook]);

  const reassignHostHook = useReassignHost();
  const reassignHost = useCallback(() => {
    if (props.user) {
      reassignHostHook(props.user);
    }
  }, [props.user, reassignHostHook]);

  const removeUserHook = useRemoveUser();
  const removeUser = useCallback(() => {
    if (props.user) {
      removeUserHook(props.user, true);
    }
  }, [props.user, removeUserHook]);

  const [showMoreDropdown, setShowMoreDropdown] = useState<boolean>(false);

  const toggleMoreDropdown = useCallback(() => {
    setShowMoreDropdown((prevState) => !prevState);
  }, []);

  if (props.user) {
    // Buttons for specific user
    return (
      <div className="HostControlsButtons">
        {/* <span
          className={`HostControlButton PinSpeaker ${
            call.pinnedSpeaker === props.user.id ? "ShowPin" : "HidePin"
          }`}
          onClick={pinSpeaker}
        >
          <AiFillPushpin />
        </span> */}
        <span className="HostControlButton" onClick={muteUser}>
          {props.user.muted ? <IoMicOff /> : <IoMic />}
        </span>
        <span>
          <span className="HostControlButton" onClick={toggleMoreDropdown}>
            More
            <IoChevronDown />
          </span>

          {showMoreDropdown && (
            <div className="HostControlsMoreDropdown">
              <div onClick={reassignHost}>
                <IoPeople />
                Reassign Host
              </div>
              <div onClick={removeUser}>
                <IoTrash />
                Remove
              </div>
            </div>
          )}
        </span>
      </div>
    );
  } else {
    // Buttons for all users
    const allRemotePeersMuted: boolean = Object.values(call.remotePeers).reduce(
      (prev, curr) => prev && curr.muted,
      true
    );
    return (
      <div className="HostControlsButtons">
        <span className="HostControlButton" onClick={muteAllUsers}>
          {allRemotePeersMuted ? <IoMicOff /> : <IoMic />}
        </span>
      </div>
    );
  }
};

export default HostControlsButtons;
