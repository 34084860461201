import "./Welcome.css";
import createMeetingImg from "../assets/welcome/create-meeting.svg";
import joinMeetingImg from "../assets/welcome/join-meeting.svg";
import seeHowWorksImg from "../assets/welcome/see-how-works.svg";
import Button from "../components/Button";
import { useToggleJoinNestModal } from "../hooks/layout";
import { useLeaveCall } from "../hooks/call";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

type WelcomeProps = {
  isMobile?: boolean;
};

function Welcome(props: WelcomeProps) {
  const toggleCreateNest = useToggleJoinNestModal(true);
  const toggleJoinNest = useToggleJoinNestModal(false);
  const openSeeHowWorks = useCallback(() => {
    window.open(
      "https://byrdhouse.notion.site/Byrdhouse-Onboarding-4b2712d0cf124e9c95fd37893592d3e7",
      "_blank"
    );
  }, []);
  const navigate = useNavigate();
  const goBackHome = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const leaveCall = useLeaveCall();
  useEffect(() => {
    // Hack for meeting component back button
    // Dismount happens too often to end call (precall and meeting should be separate)
    leaveCall({ intentional: false });
  }, [leaveCall]);

  return (
    <div className="Welcome">
      <h1>Welcome to Byrdhouse!</h1>
      <h2>What would you like to do?</h2>
      <div className="WelcomeActions">
        <div onClick={toggleCreateNest}>
          <img src={createMeetingImg} alt="Create a meeting" />
          <h3>{"Create a meeting"}</h3>
        </div>
        <div onClick={toggleJoinNest}>
          <img src={joinMeetingImg} alt="Join a meeting" />
          <h3>{"Join a meeting"}</h3>
        </div>
        <div onClick={openSeeHowWorks}>
          <img src={seeHowWorksImg} alt="See how it works" />
          <h3>{"See How it works"}</h3>
        </div>
      </div>
      {!props.isMobile && <Button text="Go to home" onClick={goBackHome} />}
    </div>
  );
}

export default Welcome;
