import { IoLogoGoogle, IoLogoMicrosoft } from "react-icons/io5";
import "./LoginModal.css";
import Button from "../Button";
import {
  useCloseModal,
  useToggleLoginModal,
  useToggleSupportModal,
} from "../../hooks/layout";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import amplitude from "../../amplitude";
import { auth } from "../../firebase";
import {
  GoogleAuthProvider,
  OAuthProvider,
  browserLocalPersistence,
  signInWithPopup,
} from "firebase/auth";
import jwtDecode from "jwt-decode";
import { getUserLocales, wait } from "../../utils";
import {
  AmplitudeEvent,
  FirebaseAuthToken,
  MIN_DISPLAY_NAME_LEN,
} from "byrdhouse-types";
import api from "../../api";
import { LocalStorageKeys } from "../../types";
import useUser from "../../hooks/user";
import { FirebaseError } from "firebase/app";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import Loader from "../Loader";
import useSession from "../../hooks/session";
import { useLocation, useNavigate } from "react-router-dom";

type VerifyEmailProps = {
  email: string;
  resendCode: () => void;
};
const VerifyEmail = (props: VerifyEmailProps) => {
  const [loading, setLoading] = useState(false);

  // Verify input code
  const [verificationCode, setVerificationCode] = useState("");
  const [verifyCodeDisabled, setVerifyCodeDisabled] = useState(true);

  // Check we can verify code
  useEffect(() => {
    const enabled = verificationCode.length >= 6;
    setVerifyCodeDisabled(!enabled);
    return () => {};
  }, [verificationCode]);

  const [, setUser] = useUser();
  const [, setSession] = useSession();
  const closeModal = useCloseModal();

  const navigate = useNavigate();
  const location = useLocation();

  const verifyCode = useCallback(async () => {
    try {
      setLoading(true);
      // Verify code with api
      let displayName = "Guest";
      try {
        displayName = props.email
          .split("@")[0]
          .replace(/[^\p{Letter}\p{Mark}\s]/gu, "");
        if (displayName.length < MIN_DISPLAY_NAME_LEN) {
          displayName = "Guest";
        }
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);
      }
      const verifyCodeResponse = await api.verifyCode({
        email: props.email,
        verificationCode,
        displayName,
      });

      localStorage.setItem(
        LocalStorageKeys.BYRDHOUSE_TOKEN,
        verifyCodeResponse.encodedToken
      );
      // Force storage event on same tab
      window.dispatchEvent(new Event("storage"));

      // Small hack to make sure local storage sets token
      await wait(100);

      // Create user with jwt
      const locales = getUserLocales();
      const createUserResponse = await api.createUser({
        user: {
          displayName,
          speechLanguageLocale: locales.speechLanguageLocale,
          voiceGender: "default",
        },
      });
      const user = {
        ...createUserResponse.data.user,
        timezone: locales.timezone,
        screenSharing: false,
        muted: false,
        autoDetect: false,
      };

      localStorage.setItem(LocalStorageKeys.EXPECT_LOGIN, "true");

      // Set sentry user
      Sentry.setUser({
        id: user.id,
        email: user.email,
        username: user.displayName,
      });

      // Set user state after successful create/login
      const { sessionId } = await api.claimSessionId();
      setSession((prevState) => ({ ...prevState, id: sessionId }));

      // Set amplitude user
      amplitude?.setUserId(user.id);
      amplitude?.setUserProperties({
        ...user,
        lastSessionId: sessionId,
      });

      setUser(user);

      if (
        (location.pathname === "/" || location.pathname === "/signup") &&
        createUserResponse.status === 201
      ) {
        navigate("/welcome");
      }

      closeModal();
    } catch (error) {
      // Render error
      toast(
        <div className="ByrdhouseToast">
          {"Something went wrong, please try again."}
        </div>,
        {
          type: "error",
        }
      );
      console.error(error);
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  }, [
    props.email,
    setUser,
    verificationCode,
    closeModal,
    setSession,
    navigate,
    location,
  ]);

  const [showResendCode, setShowResendCode] = useState(false);
  useEffect(() => {
    const resendCodeTimeout = setTimeout(() => {
      setShowResendCode(true);
    }, 45 * 1000);

    return () => {
      clearTimeout(resendCodeTimeout);
    };
  }, []);

  const toggleSupportModal = useToggleSupportModal();

  return (
    <div className="LoginModal">
      <div className="VerifyEmailHelp">
        Please check your inbox at{" "}
        <span className="SentToEmail">{props.email}</span> for the verification
        code and enter it below.
      </div>
      <input
        type="text"
        placeholder="Verification Code"
        value={verificationCode}
        maxLength={6}
        onChange={(e) => setVerificationCode(e.currentTarget.value)}
      />
      <Button
        text={!loading ? "Continue" : <Loader size={18} noMargin={true} />}
        background="gradient"
        disabled={verifyCodeDisabled}
        onClick={verifyCode}
      />
      {showResendCode && (
        <div className="VerifyEmailResendCode">
          Didn't receive the code? Check your spam folder or click{" "}
          <span className="ResendCode" onClick={props.resendCode}>
            Resend Code
          </span>
          . Need more help? Contact support{" "}
          <span
            className="ContactSupport"
            onClick={() => {
              closeModal();
              toggleSupportModal();
            }}
          >
            here
          </span>
          .
        </div>
      )}
    </div>
  );
};

type LoginModalProps = {
  signup?: boolean;
};

const LoginModal = (props: LoginModalProps) => {
  const closeModal = useCloseModal();
  const navigate = useNavigate();
  const location = useLocation();

  const [, setUser] = useUser();
  const [, setSession] = useSession();
  const signInWithFirebaseProvider = useCallback(
    async (providerID: "google" | "microsoft") => {
      const IGNORED_FIREBASE_ERROR_CODES = [
        "auth/popup-closed-by-user",
        "auth/cancelled-popup-request",
        "auth/popup-blocked",
      ];

      try {
        auth.useDeviceLanguage();
        auth.setPersistence(browserLocalPersistence);

        let provider;
        switch (providerID) {
          case "microsoft":
            provider = new OAuthProvider("microsoft.com");
            break;
          case "google":
            provider = new GoogleAuthProvider();
            provider.setCustomParameters({ prompt: "select_account" });
            break;
        }

        const credentials = await signInWithPopup(auth, provider);

        const encodedToken = await credentials.user.getIdToken();
        // Create or get the user (if already created)
        const decodedToken = jwtDecode(encodedToken) as FirebaseAuthToken;
        const displayName = decodedToken.name;
        const locales = getUserLocales();
        amplitude?.logEvent(AmplitudeEvent.GET_USER_LOCALES, locales);
        const response = await api.createUser({
          user: {
            displayName,
            speechLanguageLocale: locales.speechLanguageLocale,
            voiceGender: "default",
          },
        });

        // Set sentry user
        Sentry.setUser({
          id: response.data.user.id,
          email: response.data.user.email,
          username: response.data.user.displayName,
        });

        // Set local storage to expect login flag when user comes back
        localStorage.setItem(LocalStorageKeys.EXPECT_LOGIN, "true");

        // Login with user response
        const { sessionId } = await api.claimSessionId();
        setSession((prevState) => ({ ...prevState, id: sessionId }));

        // Set amplitude user
        amplitude?.setUserId(response.data.user.id);
        amplitude?.setUserProperties({
          ...response.data.user,
          lastSessionId: sessionId,
        });

        setUser({
          ...response.data.user,
          timezone: locales.timezone,
          screenSharing: false,
          muted: false,
          autoDetect: false,
        });

        // If user newly created, redirect to welcome page
        if (
          (location.pathname === "/" || location.pathname === "/signup") &&
          response.status === 201
        ) {
          navigate("/welcome");
        }

        closeModal();
      } catch (error) {
        if (error instanceof FirebaseError) {
          if (IGNORED_FIREBASE_ERROR_CODES.includes(error.code)) {
            console.log(
              "User closed firebase popup or browser blocked it, taking no action."
            );
          } else if (
            error.code === "auth/account-exists-with-different-credential"
          ) {
            amplitude?.logEvent(
              AmplitudeEvent.LOGIN_ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL_ERROR,
              { providerID }
            );
            const oppositeProvider =
              providerID === "google" ? "Microsoft" : "Google";
            toast(
              <div className="ByrdhouseToast ErrorToast">
                <div>{`You already have a Byrdhouse account under ${oppositeProvider}, please sign in with ${oppositeProvider} instead.`}</div>
              </div>
            );
          }
        } else {
          // Render error
          amplitude?.logEvent(AmplitudeEvent.LOGIN_UNKNOWN_ERROR);
          toast(
            <div className="ByrdhouseToast ErrorToast">
              {"Something went wrong, please try again or contact support."}
            </div>,
            {
              type: "error",
            }
          );
          console.error(error);
          Sentry.captureException(error);
        }
      }
    },
    [setUser, closeModal, setSession, navigate, location]
  );

  const signInWithGoogle = useCallback(() => {
    amplitude?.logEvent(AmplitudeEvent.CLICK_ON_CONTINUE_WITH_GOOGLE);
    signInWithFirebaseProvider("google");
  }, [signInWithFirebaseProvider]);

  const signInWithMicrosoft = useCallback(() => {
    amplitude?.logEvent(AmplitudeEvent.CLICK_ON_CONTINUE_WITH_OUTLOOK);
    signInWithFirebaseProvider("microsoft");
  }, [signInWithFirebaseProvider]);

  const [email, setEmail] = useState("");
  const changeEmail = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  }, []);

  const [loading, setLoading] = useState(false);
  const [showVerifyEmail, setShowVerifyEmail] = useState(false);
  const [clickedResendCode, setClickedResendCode] = useState(false);
  const signInWithEmail = useCallback(async () => {
    amplitude?.logEvent(AmplitudeEvent.CLICK_ON_CONTINUE_WITH_EMAIL);
    try {
      setLoading(true);
      // Send verification code to email from api
      await api.sendVerificationCode({
        email,
      });
      // Render verification code input on success
      setShowVerifyEmail(true);
    } catch (error) {
      // Render error
      amplitude?.logEvent(AmplitudeEvent.LOGIN_UNKNOWN_ERROR);
      // Render error
      toast(
        <div className="ByrdhouseToast ErrorToast">
          {"Something went wrong, please try again or contact support."}
        </div>,
        {
          type: "error",
        }
      );
      console.error(error);
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  }, [email]);

  const toggleLoginModal = useToggleLoginModal();

  if (!showVerifyEmail) {
    return (
      <div className="LoginModal">
        <Button
          text={
            <span className="LoginButton">
              <IoLogoGoogle />
              Continue with Google
            </span>
          }
          onClick={signInWithGoogle}
        />
        <Button
          text={
            <span className="LoginButton">
              <IoLogoMicrosoft />
              Continue with Outlook
            </span>
          }
          onClick={signInWithMicrosoft}
        />
        <div className="OrLine">
          <div />
          OR
          <div />
        </div>
        <div className="LoginEmail">
          <div style={{ fontWeight: "bold" }}>
            {clickedResendCode ? "Verify Email Address" : "Email Address"}
          </div>
          <input
            value={email}
            onChange={changeEmail}
            id="email"
            type="email"
            autoComplete="on"
            placeholder="hal@email.com"
          />
        </div>
        <div className="Buttons">
          {!props.signup ? (
            <Button text="Cancel" onClick={closeModal} />
          ) : (
            <Button text="Login" onClick={toggleLoginModal} />
          )}
          <Button
            disabled={!email.length}
            text={
              !loading ? (
                clickedResendCode ? (
                  "Resend Code"
                ) : (
                  "Continue"
                )
              ) : (
                <Loader size={18} noMargin={true} />
              )
            }
            background="gradient"
            onClick={signInWithEmail}
          />
        </div>
      </div>
    );
  } else {
    return (
      <VerifyEmail
        email={email}
        resendCode={() => {
          setShowVerifyEmail(false);
          setClickedResendCode(true);
        }}
      />
    );
  }
};

export default LoginModal;
