import * as BCP47 from "bcp-47";
import { getSpeechLanguageLocale, SpeechLanguageLocale } from "byrdhouse-types";
import emojiFlags from "emoji-flags";

export const getUserLocales = (): {
  timezone: string;
  speechLanguageLocale: SpeechLanguageLocale;
} => {
  return {
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    speechLanguageLocale: getSpeechLanguageLocale(navigator.language),
  };
};

export const speechLanguageLocaleToFlag = (
  speechLanguageLocale: SpeechLanguageLocale
): string => {
  // Parse language code to get country
  let schema = BCP47.parse(speechLanguageLocale.code);

  if (schema.region) {
    return emojiFlags.countryCode(schema.region).emoji;
  }

  // Default return space string
  return " ";
};

export const hexToRGB = (hex: string) => {
  var arrBuff = new ArrayBuffer(4);
  var vw = new DataView(arrBuff);
  vw.setUint32(0, parseInt(hex, 16), false);
  var arrByte = new Uint8Array(arrBuff);

  return arrByte[1] + "," + arrByte[2] + "," + arrByte[3];
};

export const wait = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const retryOperation = (
  operation: Function,
  delay: number,
  retries: number
) =>
  new Promise((resolve, reject) => {
    return operation()
      .then(resolve)
      .catch((reason: any) => {
        if (retries > 0) {
          return wait(delay)
            .then(retryOperation.bind(null, operation, delay, retries - 1))
            .then(resolve)
            .catch(reject);
        }
        return reject(reason);
      });
  });
