"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AmplitudeEvent = void 0;
var AmplitudeEvent;
(function (AmplitudeEvent) {
    // User
    AmplitudeEvent["LOGIN"] = "login";
    AmplitudeEvent["GET_USER_LOCALES"] = "get_user_locales";
    // Login
    AmplitudeEvent["CLICK_ON_CONTINUE_WITH_GOOGLE"] = "click_on_continue_with_google";
    AmplitudeEvent["CLICK_ON_CONTINUE_WITH_OUTLOOK"] = "click_on_continue_with_outlook";
    AmplitudeEvent["CLICK_ON_CONTINUE_WITH_EMAIL"] = "click_on_continue_with_email";
    AmplitudeEvent["LOGIN_ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL_ERROR"] = "login_account_exists_with_different_credential_error";
    AmplitudeEvent["LOGIN_UNKNOWN_ERROR"] = "login_unknown_error";
    // Page views
    AmplitudeEvent["OPEN_LANDING"] = "open_landing";
    AmplitudeEvent["OPEN_HOME"] = "open_home";
    AmplitudeEvent["OPEN_BUILD_NEST"] = "open_build_nest";
    AmplitudeEvent["OPEN_JOIN_NEST"] = "open_join_nest";
    AmplitudeEvent["OPEN_SPECIFIC_RECORDING"] = "open_specific_recording";
    AmplitudeEvent["OPEN_SUPPORT"] = "open_support";
    // Landing
    AmplitudeEvent["CLICK_ON_HOW_IT_WORKS_LANDING_HEADER"] = "click_on_how_it_works_landing_header";
    AmplitudeEvent["CLICK_ON_ABOUT_LANDING_HEADER"] = "click_on_about_landing_header";
    AmplitudeEvent["CLICK_ON_PRICING_LANDING_HEADER"] = "click_on_pricing_landing_header";
    AmplitudeEvent["CLICK_ON_GET_STARTED_LANDING"] = "click_on_get_started_landing";
    AmplitudeEvent["CLICK_ON_TRY_FOR_FREE_LANDING_TITLE"] = "click_on_try_for_free_landing_title";
    AmplitudeEvent["CLICK_ON_LEARN_MORE_LANDING_1"] = "click_on_learn_more_landing_1";
    AmplitudeEvent["CLICK_ON_LEARN_MORE_LANDING_2"] = "click_on_learn_more_landing_2";
    AmplitudeEvent["CLICK_ON_FAQ_LANDING"] = "click_on_faq_landing";
    AmplitudeEvent["CLICK_ON_WHY_STARTED_BYRDHOUSE_LANDING"] = "click_on_why_started_byrdhouse_landing";
    AmplitudeEvent["CLICK_ON_PRICING_LANDING_FOOTER"] = "click_on_pricing_landing_footer";
    AmplitudeEvent["CLICK_ON_TRY_FOR_FREE_LANDING_FOOTER"] = "click_on_try_for_free_landing_footer";
    AmplitudeEvent["CLICK_ON_BLOG_HEADER"] = "click_on_blog_header";
    AmplitudeEvent["CLICK_ON_BLOG_FOOTER"] = "click_on_blog_footer";
    // Landing pricing
    AmplitudeEvent["CLICK_ON_TRY_FOR_FREE_LANDING_PRICING"] = "click_on_try_for_free_landing_pricing";
    AmplitudeEvent["CLICK_ON_GET_A_DEMO_LANDING_PRICING"] = "click_on_get_a_demo_landing_pricing";
    AmplitudeEvent["CLICK_ON_CONTACT_SALES_LANDING_PRICING"] = "click_on_contact_sales_landing_pricing";
    // Signup
    AmplitudeEvent["CLICK_ON_SIGNUP_BACK"] = "click_on_signup_back";
    // Home
    AmplitudeEvent["CLICK_ON_ACCOUNT"] = "click_on_account";
    AmplitudeEvent["CLICK_ON_USAGE"] = "click_on_usage";
    // Subscription (user logged in)
    AmplitudeEvent["CLICK_ON_GET_A_DEMO"] = "click_on_get_a_demo";
    AmplitudeEvent["CLICK_ON_CONTACT_SALES"] = "click_on_contact_sales";
    AmplitudeEvent["CLICK_ON_ADD_MINUTES"] = "click_on_add_minutes";
    // Meeting
    AmplitudeEvent["SHARE_NEST_COPY_LINK"] = "share_nest_copy_link";
    AmplitudeEvent["OPEN_CREATE_NEST_MODAL"] = "open_create_nest_modal";
    AmplitudeEvent["OPEN_JOIN_NEST_MODAL"] = "open_join_nest_modal";
    AmplitudeEvent["CLICK_ON_CREATE_NEST"] = "click_on_create_nest";
    AmplitudeEvent["CLICK_ON_JOIN_NEST"] = "click_on_join_nest";
    AmplitudeEvent["CLICK_ON_SIGN_OUT"] = "click_on_sign_out";
    AmplitudeEvent["CHANGE_SPEECH_LANGUAGE"] = "change_speech_language";
    // Support
    AmplitudeEvent["CLICK_ON_MAILTO_HYPERLINK"] = "click_on_mailto_hyperlink";
    // Speech translation limitations
    AmplitudeEvent["CLICK_ON_UPGRADE"] = "click_on_upgrade";
    // Toolbar
    AmplitudeEvent["CLICK_ON_HOST"] = "click_on_host";
    AmplitudeEvent["CLICK_ON_LAYOUT"] = "click_on_layout";
    AmplitudeEvent["TOGGLE_CAPTIONS_VISIBLE"] = "toggle_captions_visible";
    AmplitudeEvent["TOGGLE_CAPTIONS_FLOATING"] = "toggle_captions_floating";
    AmplitudeEvent["TOGGLE_CAPTIONS_DOCK"] = "toggle_captions_dock";
    AmplitudeEvent["TOGGLE_TEXT_CHAT"] = "toggle_text_chat";
    // Media
    AmplitudeEvent["SET_PERMISSIONS"] = "set_permissions";
    AmplitudeEvent["TOGGLE_VIDEO_BACKGROUND"] = "toggle_video_background";
    AmplitudeEvent["TOGGLE_MIC"] = "toggle_mic";
    AmplitudeEvent["TOGGLE_CAMERA"] = "toggle_camera";
    AmplitudeEvent["START_SCREEN_SHARE"] = "start_screen_share";
    AmplitudeEvent["STOP_SCREEN_SHARE"] = "stop_screen_share";
    // Feedback
    AmplitudeEvent["CLICK_ON_SEND_FEEDBACK"] = "click_on_send_feedback";
    AmplitudeEvent["CLICK_ON_SEND_REFERRAL"] = "click_on_send_referral";
    // Recordings
    AmplitudeEvent["CONFIRM_START_RECORDING"] = "confirm_start_recording";
    AmplitudeEvent["CONFIRM_STOP_RECORDING"] = "confirm_stop_recording";
    AmplitudeEvent["SEARCH_RECORDINGS"] = "search_recordings";
    AmplitudeEvent["SEARCH_SPECIFIC_RECORDING"] = "search_specific_recording";
    AmplitudeEvent["SHARE_SPECIFIC_RECORDING_COPY_LINK"] = "share_specific_recording_copy_link";
    AmplitudeEvent["CHANGE_LANGUAGE_SPECIFIC_RECORDING"] = "change_language_specific_recording";
    AmplitudeEvent["DOWNLOAD_MEETING_NOTES_SPECIFIC_RECORDING"] = "download_meeting_notes_specific_recording";
    // AI Voice
    AmplitudeEvent["ENABLE_AI_VOICE"] = "enable_ai_voice";
    AmplitudeEvent["DISABLE_AI_VOICE"] = "disable_ai_voice";
    AmplitudeEvent["CHANGE_AI_VOICE_GENDER"] = "change_ai_voice_gender";
    // Call state (handled by socket server)
    AmplitudeEvent["WAITING_PERIOD_START"] = "waiting_period_start";
    AmplitudeEvent["WAITING_PERIOD_END"] = "waiting_period_end";
    AmplitudeEvent["CALL_START"] = "call_start";
    AmplitudeEvent["CALL_END"] = "call_end";
    AmplitudeEvent["PARTICIPANT_JOIN_CALL"] = "participant_join_call";
    AmplitudeEvent["PARTICIPANT_LEAVE_CALL"] = "participant_leave_call";
    // Auto-detect
    AmplitudeEvent["CLICK_ON_AUTO_DETECT"] = "click_on_auto_detect";
    // AI Magician
    AmplitudeEvent["CLICK_ON_TOGGLE_AI_MAGICIAN"] = "click_on_toggle_ai_magician";
    AmplitudeEvent["CLICK_ON_GENERATE_MAGIC"] = "click_on_generate_magic";
})(AmplitudeEvent || (exports.AmplitudeEvent = AmplitudeEvent = {}));
