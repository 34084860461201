import Support from "../../routes/home/Support";
import "./SupportModal.css";

type SupportModalProps = {
  managePlan?: boolean;
};

function SupportModal(props: SupportModalProps) {
  return (
    <div className="SupportModal">
      <Support managePlan={props.managePlan} />
    </div>
  );
}

export default SupportModal;
