import "./RecordingSummary.css";
import { useCallback, useEffect, useMemo, useState } from "react";
import Button from "./Button";
import api from "../api";
import {
  GetRecordingSummaryStatusResponse,
  RecordingID,
} from "byrdhouse-types";
import useUser from "../hooks/user";
import React from "react";
import Loader from "./Loader";

export type RecordingSummaryProps = {
  id: RecordingID;
  language: string;
  summary?: string;
  actionItems?: string[];
};

const RecordingSummary = (props: RecordingSummaryProps) => {
  const [user] = useUser();

  const [generating, setGenerating] = useState<boolean>(false);

  const [summaryStatus, setSummaryStatus] =
    useState<GetRecordingSummaryStatusResponse>();

  const getRecordingSummaryStatus = useCallback(async () => {
    const response = await api.getRecordingSummaryStatus({ id: props.id });
    if (response.status === "done") {
      setGenerating(false);
    }
    console.debug("summary status response", response);
    setSummaryStatus(response);
  }, [props.id, setSummaryStatus, setGenerating]);

  // Check summary status every 1s when in progress
  useEffect(() => {
    if (!props.summary && !props.actionItems) {
      const intervalId = setInterval(async () => {
        if (
          !summaryStatus ||
          summaryStatus.status === "in-progress" ||
          generating
        ) {
          getRecordingSummaryStatus();
        }
      }, 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [
    props.summary,
    props.actionItems,
    summaryStatus,
    generating,
    getRecordingSummaryStatus,
  ]);

  const generateSummary = useCallback(async () => {
    if (!user) {
      return;
    }
    setGenerating(true);
    await api.generateRecordingSummary({
      id: props.id,
      language: props.language,
      timezone: user.timezone,
    });
  }, [props.id, props.language, user]);

  const summary = useMemo(() => {
    return (
      props.summary ||
      (summaryStatus?.status === "done" ? summaryStatus.summary : undefined)
    );
  }, [props.summary, summaryStatus]);

  const actionItems = useMemo(() => {
    return (
      props.actionItems ||
      (summaryStatus?.status === "done" ? summaryStatus.actionItems : undefined)
    );
  }, [props.actionItems, summaryStatus]);

  return (
    <React.Fragment>
      {summary && (
        <div>
          <div className="RecordingPanelTitle">Meeting Summary</div>
          <div>{summary}</div>
        </div>
      )}
      {actionItems && actionItems.length > 0 && (
        <div>
          <div className="RecordingPanelTitle">Action Items</div>
          <ul>
            {actionItems.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      )}
      {!summary && !actionItems && (
        <div>
          <div className="RecordingPanelTitle">Meeting Summary</div>
          {summaryStatus &&
          summaryStatus.status === "in-progress" &&
          summaryStatus.totalChunks > 1 ? (
            <div className="RecordingSummaryProgress">
              <div>
                Summary Generating...
                {true
                  ? ` (${summaryStatus.currentChunk}/${summaryStatus.totalChunks})`
                  : ""}
              </div>
              <div className="ProgressBar">
                <div
                  className="Progress"
                  style={{
                    width: `${
                      (summaryStatus.currentChunk / summaryStatus.totalChunks) *
                      100
                    }%`,
                  }}
                />
              </div>
            </div>
          ) : generating ? (
            <div className="RecordingSummaryNoStatus">
              <Loader size={18} noMargin={true} />
              <div>Summary Generating...</div>
            </div>
          ) : (
            <Button
              text="Generate Summary"
              border="pink"
              onClick={generateSummary}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default RecordingSummary;
