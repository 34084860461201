import { useRef } from "react";
import { IoClose, IoPeople } from "react-icons/io5";
import useCall, { useCloseHostControls } from "../hooks/call";
import "./HostControls.css";
import HostControlsButtons from "./HostControlsButtons";

const HostControls = () => {
  const [call] = useCall();

  // TODO: change to callback
  const onConfirmHostAction = () => {
    call.confirmHostAction?.callback();
  };

  let display = null;
  const showHostControlsAfter = useRef(true);
  if (call.showHostControls === "confirm" && call.confirmHostAction) {
    showHostControlsAfter.current =
      call.confirmHostAction.showHostControlsAfter;
    // Confirming a host action
    display = (
      <div className="ConfirmHostAction">
        <div>
          {`Are you sure you want to ${call.confirmHostAction.action} user ${call.confirmHostAction.participant.displayName}?`}
        </div>
        <div>
          <button
            className="Button InvertedButton"
            onClick={onConfirmHostAction}
          >
            Yes
          </button>
        </div>
      </div>
    );
  } else if (call.showHostControls === "controls") {
    // Show available host actions
    let remotePeers = Object.values(call.remotePeers);
    if (remotePeers.length) {
      display = (
        <div>
          <div className="HostControlsParticipantList">
            {/* All Participants actions */}
            <div className="HostControlsParticipant HostControlsAllParticipants">
              <div>All attendees</div>

              <HostControlsButtons />
            </div>
            {/* Participants List */}
            {remotePeers.map((peer, index) => {
              return (
                <div className="HostControlsParticipant" key={index}>
                  <div className="User">{peer.displayName}</div>

                  <HostControlsButtons user={peer} />
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  }

  const closeHostControls = useCloseHostControls();
  if (call.showHostControls) {
    return (
      <div className="HostControls">
        {/* Title */}
        <div className="HostControlsTitle">
          {call.confirmHostAction ? (
            <div className="HostControlsTitleName">
              <div>Confirm</div>
            </div>
          ) : (
            <div className="HostControlsTitleName">
              <IoPeople />
              <div>You are the host</div>
            </div>
          )}
          <IoClose className="HostControlsClose" onClick={closeHostControls} />
        </div>
        {display}
      </div>
    );
  } else {
    return null;
  }
};

export default HostControls;
