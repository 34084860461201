"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.speechLanguageLocaleToLanguageLocale = exports.getAutoDetectSupportedSpeechLanguages = exports.getSupportedSpeechLanguageLocales = void 0;
var locales = [
    { display: "Afrikaans (South Africa)", code: "af-ZA" },
    { display: "Albanian (Albania)", code: "sq-AL" },
    { display: "Amharic (Ethiopia)", code: "am-ET" },
    { display: "Arabic (Algeria)", code: "ar-DZ" },
    { display: "Arabic (Bahrain), modern standard", code: "ar-BH" },
    { display: "Arabic (Egypt)", code: "ar-EG" },
    { display: "Arabic (Iraq)", code: "ar-IQ" },
    { display: "Arabic (Israel)", code: "ar-IL" },
    { display: "Arabic (Jordan)", code: "ar-JO" },
    { display: "Arabic (Kuwait)", code: "ar-KW" },
    { display: "Arabic (Lebanon)", code: "ar-LB" },
    { display: "Arabic (Libya)", code: "ar-LY" },
    { display: "Arabic (Morocco)", code: "ar-MA" },
    { display: "Arabic (Oman)", code: "ar-OM" },
    { display: "Arabic (Palestinian Authority)", code: "ar-PS" },
    { display: "Arabic (Qatar)", code: "ar-QA" },
    { display: "Arabic (Saudi Arabia)", code: "ar-SA" },
    { display: "Arabic (Syria)", code: "ar-SY" },
    { display: "Arabic (Tunisia)", code: "ar-TN" },
    { display: "Arabic (United Arab Emirates)", code: "ar-AE" },
    { display: "Arabic (Yemen)", code: "ar-YE" },
    { display: "Armenian (Armenia)", code: "hy-AM" },
    { display: "Azerbaijani (Azerbaijan)", code: "az-AZ" },
    { display: "Basque (Spain)", code: "eu-ES" },
    { display: "Bengali (India)", code: "bn-IN" },
    { display: "Bosnian (Bosnia and Herzegovina)", code: "bs-BA" },
    { display: "Bulgarian (Bulgaria)", code: "bg-BG" },
    { display: "Burmese (Myanmar)", code: "my-MM" },
    { display: "Catalan (Spain)", code: "ca-ES" },
    { display: "Chinese (Cantonese, Simplified)", code: "yue-CN" },
    { display: "Chinese (Cantonese, Traditional)", code: "zh-HK" },
    { display: "Chinese (Mandarin, Simplified)", code: "zh-CN" },
    {
        display: "Chinese (Southwestern Mandarin, Simplified)",
        code: "zh-CN-sichuan",
    },
    { display: "Chinese (Taiwanese Mandarin, Traditional)", code: "zh-TW" },
    // { display: "Chinese (Wu, Simplified)", code: "wuu-CN" },
    { display: "Croatian (Croatia)", code: "hr-HR" },
    { display: "Czech (Czech)", code: "cs-CZ" },
    { display: "Danish (Denmark)", code: "da-DK" },
    { display: "Dutch (Belgium)", code: "nl-BE" },
    { display: "Dutch (Netherlands)", code: "nl-NL" },
    { display: "English (Australia)", code: "en-AU" },
    { display: "English (Canada)", code: "en-CA" },
    { display: "English (Ghana)", code: "en-GH" },
    { display: "English (Hong Kong)", code: "en-HK" },
    { display: "English (India)", code: "en-IN" },
    { display: "English (Ireland)", code: "en-IE" },
    { display: "English (Kenya)", code: "en-KE" },
    { display: "English (New Zealand)", code: "en-NZ" },
    { display: "English (Nigeria)", code: "en-NG" },
    { display: "English (Philippines)", code: "en-PH" },
    { display: "English (Singapore)", code: "en-SG" },
    { display: "English (South Africa)", code: "en-ZA" },
    { display: "English (Tanzania)", code: "en-TZ" },
    { display: "English (United Kingdom)", code: "en-GB" },
    { display: "English (United States)", code: "en-US" },
    { display: "Estonian (Estonia)", code: "et-EE" },
    { display: "Filipino (Philippines)", code: "fil-PH" },
    { display: "Finnish (Finland)", code: "fi-FI" },
    { display: "French (Belgium)", code: "fr-BE" },
    { display: "French (Canada)", code: "fr-CA" },
    { display: "French (France)", code: "fr-FR" },
    { display: "French (Switzerland)", code: "fr-CH" },
    { display: "Galician (Spain)", code: "gl-ES" },
    { display: "Georgian (Georgia)", code: "ka-GE" },
    { display: "German (Austria)", code: "de-AT" },
    { display: "German (Germany)", code: "de-DE" },
    { display: "German (Switzerland)", code: "de-CH" },
    { display: "Greek (Greece)", code: "el-GR" },
    { display: "Gujarati (Indian)", code: "gu-IN" },
    { display: "Hebrew (Israel)", code: "he-IL" },
    { display: "Hindi (India)", code: "hi-IN" },
    { display: "Hungarian (Hungary)", code: "hu-HU" },
    { display: "Icelandic (Iceland)", code: "is-IS" },
    { display: "Indonesian (Indonesia)", code: "id-ID" },
    { display: "Irish (Ireland)", code: "ga-IE" },
    { display: "Italian (Italy)", code: "it-IT" },
    { display: "Italian (Switzerland)", code: "it-CH" },
    { display: "Japanese (Japan)", code: "ja-JP" },
    { display: "Javanese (Indonesia)", code: "jv-ID" },
    { display: "Kannada (India)", code: "kn-IN" },
    { display: "Kazakh (Kazakhstan)", code: "kk-KZ" },
    { display: "Khmer (Cambodia)", code: "km-KH" },
    { display: "Korean (Korea)", code: "ko-KR" },
    { display: "Lao (Laos)", code: "lo-LA" },
    { display: "Latvian (Latvia)", code: "lv-LV" },
    { display: "Lithuanian (Lithuania)", code: "lt-LT" },
    { display: "Macedonian (North Macedonia)", code: "mk-MK" },
    { display: "Malay (Malaysia)", code: "ms-MY" },
    { display: "Maltese (Malta)", code: "mt-MT" },
    { display: "Marathi (India)", code: "mr-IN" },
    { display: "Mongolian (Mongolia)", code: "mn-MN" },
    { display: "Nepali (Nepal)", code: "ne-NP" },
    { display: "Norwegian (Bokmål, Norway)", code: "nb-NO" },
    // { display: "Pashto (Afghanistan)", code: "ps-AF" },
    { display: "Persian (Iran)", code: "fa-IR" },
    { display: "Polish (Poland)", code: "pl-PL" },
    { display: "Portuguese (Brazil)", code: "pt-BR" },
    { display: "Portuguese (Portugal)", code: "pt-PT" },
    { display: "Romanian (Romania)", code: "ro-RO" },
    { display: "Russian (Russia)", code: "ru-RU" },
    { display: "Serbian (Serbia)", code: "sr-RS" },
    { display: "Sinhala (Sri Lanka)", code: "si-LK" },
    { display: "Slovak (Slovakia)", code: "sk-SK" },
    { display: "Slovenian (Slovenia)", code: "sl-SI" },
    { display: "Somali (Somalia)", code: "so-SO" },
    { display: "Spanish (Argentina)", code: "es-AR" },
    { display: "Spanish (Bolivia)", code: "es-BO" },
    { display: "Spanish (Chile)", code: "es-CL" },
    { display: "Spanish (Colombia)", code: "es-CO" },
    { display: "Spanish (Costa Rica)", code: "es-CR" },
    { display: "Spanish (Cuba)", code: "es-CU" },
    { display: "Spanish (Dominican Republic)", code: "es-DO" },
    { display: "Spanish (Ecuador)", code: "es-EC" },
    { display: "Spanish (El Salvador)", code: "es-SV" },
    { display: "Spanish (Equatorial Guinea)", code: "es-GQ" },
    { display: "Spanish (Guatemala)", code: "es-GT" },
    { display: "Spanish (Honduras)", code: "es-HN" },
    { display: "Spanish (Mexico)", code: "es-MX" },
    { display: "Spanish (Nicaragua)", code: "es-NI" },
    { display: "Spanish (Panama)", code: "es-PA" },
    { display: "Spanish (Paraguay)", code: "es-PY" },
    { display: "Spanish (Peru)", code: "es-PE" },
    { display: "Spanish (Puerto Rico)", code: "es-PR" },
    { display: "Spanish (Spain)", code: "es-ES" },
    { display: "Spanish (Uruguay)", code: "es-UY" },
    { display: "Spanish (USA)", code: "es-US" },
    { display: "Spanish (Venezuela)", code: "es-VE" },
    { display: "Swahili (Kenya)", code: "sw-KE" },
    { display: "Swahili (Tanzania)", code: "sw-TZ" },
    { display: "Swedish (Sweden)", code: "sv-SE" },
    { display: "Tamil (India)", code: "ta-IN" },
    { display: "Telugu (India)", code: "te-IN" },
    { display: "Thai (Thailand)", code: "th-TH" },
    { display: "Turkish (Turkey)", code: "tr-TR" },
    { display: "Ukrainian (Ukraine)", code: "uk-UA" },
    { display: "Uzbek (Uzbekistan)", code: "uz-UZ" },
    { display: "Vietnamese (Vietnam)", code: "vi-VN" },
    { display: "Welsh (United Kingdom)", code: "cy-GB" },
    { display: "Zulu (South Africa)", code: "zu-ZA" },
];
var getSupportedSpeechLanguageLocales = function () {
    return locales;
};
exports.getSupportedSpeechLanguageLocales = getSupportedSpeechLanguageLocales;
var getAutoDetectSupportedSpeechLanguages = function () {
    return [
        "ar-AE",
        "ar-BH",
        "ar-DZ",
        "ar-EG",
        "ar-IQ",
        "ar-JO",
        "ar-KW",
        "ar-LY",
        "ar-MA",
        "ar-OM",
        "ar-QA",
        "ar-SA",
        "ar-SY",
        "ar-YE",
        "bn-IN",
        "bg-BG",
        "ca-ES",
        "zh-CN",
        "zh-HK",
        "zh-TW",
        "hr-HR",
        "cs-CZ",
        "da-DK",
        "nl-NL",
        "en-AU",
        "en-CA",
        "en-GH",
        "en-HK",
        "en-IN",
        "en-IE",
        "en-KE",
        "en-NZ",
        "en-NG",
        "en-PH",
        "en-SG",
        "en-ZA",
        "en-TZ",
        "en-GB",
        "en-US",
        "et-EE",
        "fi-FI",
        "fr-CA",
        "fr-FR",
        "de-DE",
        "el-GR",
        "gu-IN",
        "he-IL",
        "hi-IN",
        "hu-HU",
        "id-ID",
        "ga-IE",
        "it-IT",
        "ja-JP",
        "kn-IN",
        "ml-IN",
        "ko-KR",
        "lv-LV",
        "lt-LT",
        "mt-MT",
        "mr-IN",
        "nb-NO",
        "pl-PL",
        "pt-BR",
        "pt-PT",
        "ro-RO",
        "ru-RU",
        "sk-SK",
        "sl-SI",
        "es-AR",
        "es-BO",
        "es-CL",
        "es-CO",
        "es-CR",
        "es-CU",
        "es-DO",
        "es-EC",
        "es-SV",
        "es-GQ",
        "es-GT",
        "es-HN",
        "es-MX",
        "es-NI",
        "es-PA",
        "es-PY",
        "es-PE",
        "es-PR",
        "es-ES",
        "es-UY",
        "es-US",
        "es-VE",
        "sv-SE",
        "ta-IN",
        "te-IN",
        "th-TH",
        "tr-TR",
        "uk-UA",
        "vi-VN",
    ];
};
exports.getAutoDetectSupportedSpeechLanguages = getAutoDetectSupportedSpeechLanguages;
var speechLanguageLocaleToLanguageLocale = function (speechLanguageLocale) {
    // Handle edge cases where the language locale is not a simple two letter code
    switch (speechLanguageLocale.code) {
        // Chinese (Simplified)
        case "yue-CN":
        case "zh-CN":
        case "zh-CN-sichuan":
        case "wuu-CN":
            return "zh-Hans";
        // Chinese (Traditional)
        case "zh-HK":
        case "zh-TW":
            return "zh-Hant";
        // French (Canadian)
        case "fr-CA":
            return "fr-CA";
        // Mongolian (Traditional)
        case "mn-MN":
            return "mn-Mong";
        // Portuguese (Portugal)
        case "pt-PT":
            return "pt-PT";
        // Serbian (Serbia)
        case "sr-RS":
            return "sr-Cyrl";
        // Handle all other normal cases
        default:
            return speechLanguageLocale.code.split("-")[0];
    }
};
exports.speechLanguageLocaleToLanguageLocale = speechLanguageLocaleToLanguageLocale;
