import "./BuyMoreModal.css";
import Button from "../Button";

const BuyMoreModal = () => {
  return (
    <div className="BuyMoreModal">
      To continue real-time translation
      <Button
        text={"See Plans & Pricing"}
        background="gradient"
        onClick={() => window.open("/subscription", "_blank")?.focus()}
      />
    </div>
  );
};

export default BuyMoreModal;
