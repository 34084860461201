import "./NavHeader.css";
import logo from "../assets/logo.svg";
import useUser from "../hooks/user";
import useCall from "../hooks/call";
import { Link, useLocation } from "react-router-dom";
import { useWindowSize } from "react-use";
import {
  useOpenSignup,
  useToggleLoginModal,
  useToggleSupportModal,
} from "../hooks/layout";
import Button from "./Button";
import amplitude from "../amplitude";
import { AmplitudeEvent } from "byrdhouse-types";

const NavHeader = () => {
  const [user] = useUser();
  const [call] = useCall();
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 1000;

  const toggleSupportModal = useToggleSupportModal();
  const toggleLoginModal = useToggleLoginModal();
  const openSignup = useOpenSignup();

  const location = useLocation();

  const navLogo = (
    <Link to="/" className="NavLogo">
      <img className="NavLogoImg" src={logo} alt="Byrdhouse" />
      <div className="NavLogoText">BYRDHOUSE</div>
    </Link>
  );

  if (call.state !== "disconnected") {
    // In-call header
    return null;
  } else if (user) {
    // Logged-in header
    return (
      <div className="NavHeader">
        <div>{navLogo}</div>
        <div className="NavPages">
          <div onClick={toggleSupportModal}>Support</div>
          {!isMobile && <span>Hi, {user.displayName.split(" ")[0]}</span>}
        </div>
      </div>
    );
  } else {
    // Landing page header
    return (
      <div className="NavHeader LandingHeader">
        <div>
          {navLogo}
          {!isMobile && (
            <div className="NavPages">
              {location.pathname === "/" && (
                <>
                  <a
                    href="https://byrdhouse.notion.site/Byrdhouse-Onboarding-4b2712d0cf124e9c95fd37893592d3e7"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() =>
                      amplitude?.logEvent(
                        AmplitudeEvent.CLICK_ON_HOW_IT_WORKS_LANDING_HEADER
                      )
                    }
                  >
                    How it works
                  </a>
                  <a
                    onClick={() =>
                      amplitude?.logEvent(
                        AmplitudeEvent.CLICK_ON_ABOUT_LANDING_HEADER
                      )
                    }
                    href="#OurStory"
                  >
                    About
                  </a>
                  <a
                    onClick={() =>
                      amplitude?.logEvent(
                        AmplitudeEvent.CLICK_ON_PRICING_LANDING_HEADER
                      )
                    }
                    href="#Pricing"
                  >
                    Pricing
                  </a>
                </>
              )}
              <div onClick={toggleSupportModal}>Support</div>
              <a
                href="https://byrdhouseapp.com/blog/"
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  amplitude?.logEvent(AmplitudeEvent.CLICK_ON_BLOG_HEADER);
                }}
              >
                Blog
              </a>
            </div>
          )}
        </div>

        <div className="NavPages">
          <Button text="Login" border="pink" onClick={toggleLoginModal} />
          {!isMobile && location.pathname !== "/signup" && (
            <Button
              text="Get Started"
              background="gradient"
              onClick={() => {
                amplitude?.logEvent(
                  AmplitudeEvent.CLICK_ON_GET_STARTED_LANDING
                );
                openSignup();
              }}
            />
          )}
        </div>
      </div>
    );
  }
};

export default NavHeader;
