import { useCallback, useEffect } from "react";
import useCall, { useNumberRemotePeers } from "./call";
import useSocket from "./socket";
import { useParams } from "react-router-dom";
import { useCurrentLanguage, useLocalParticipant } from "./user";
import { AmplitudeEvent, RoomID, StartDubArgs } from "byrdhouse-types";
import { StopDubArgs } from "byrdhouse-types";
import agora from "../agora";
import { IAgoraRTCRemoteUser } from "agora-rtc-sdk-ng";
import amplitude from "../amplitude";

export const useToggleDub = () => {
  const socket = useSocket();
  const params = useParams();
  const localParticipant = useLocalParticipant();
  const [call, setCall] = useCall();
  const numberRemotePeers = useNumberRemotePeers();
  const currentLanguage = useCurrentLanguage();

  const unsubscribeDubAudio = useCallback(async () => {
    const agoraDubUsers = agora.remoteUsers.filter((u) =>
      u.uid.toString().includes("dub-")
    );
    await Promise.all(
      agoraDubUsers.map(async (agoraDubUser) => {
        if (agoraDubUser) {
          await agora.unsubscribe(agoraDubUser, "audio");
          setCall((prevState) => {
            prevState.dubAudioTrack?.stop();
            return {
              ...prevState,
              dubAudioTrack: undefined,
            };
          });
          console.debug("Unsubscribe and stop dub audio", agoraDubUser.uid);
        }
      })
    );
  }, [setCall]);

  const subscribeDubAudio = useCallback(
    async (agoraDubUser?: IAgoraRTCRemoteUser) => {
      if (!currentLanguage) {
        return;
      }
      // No user provided, find them
      if (!agoraDubUser) {
        agoraDubUser = agora.remoteUsers.find(
          (u) => u.uid === "dub-" + currentLanguage.code
        );
      }
      // Unsubscribe from all other dub users
      const allOtherDubUsers = agora.remoteUsers.filter(
        (u) =>
          u.uid.toString().includes("dub-") &&
          u.uid !== "dub-" + currentLanguage.code
      );
      allOtherDubUsers.forEach((otherDubUser) => {
        if (otherDubUser.audioTrack && otherDubUser.hasAudio) {
          otherDubUser.audioTrack.isPlaying && otherDubUser.audioTrack.stop();
          agora.unsubscribe(otherDubUser, "audio");
        }
      });
      if (agoraDubUser && agoraDubUser.hasAudio) {
        const audioTrack = await agora.subscribe(agoraDubUser, "audio");
        if (!audioTrack.isPlaying) {
          audioTrack.play();
        }
        setCall((prevState) => ({
          ...prevState,
          dubAudioTrack: audioTrack,
        }));
        console.debug("Subscribe and play dub audio", agoraDubUser.uid);
      }
    },
    [currentLanguage, setCall]
  );

  const onDubChannelPublished = useCallback(
    async (agoraUser: IAgoraRTCRemoteUser, mediaType: "video" | "audio") => {
      if (
        mediaType === "audio" &&
        currentLanguage &&
        agoraUser.uid.toString() === "dub-" + currentLanguage.code
      ) {
        subscribeDubAudio(agoraUser);
      }
    },
    [currentLanguage, subscribeDubAudio]
  );

  const stopDub = useCallback(() => {
    if (!params.room || !localParticipant) {
      return;
    }

    const roomId = params.room as RoomID;
    const args: StopDubArgs = {
      roomId: roomId,
      participant: localParticipant,
    };
    socket?.emit("stop_dub", args);
  }, [params.room, localParticipant, socket]);

  const startDub = useCallback(() => {
    if (!params.room || !localParticipant) {
      return;
    }

    const roomId = params.room as RoomID;
    const args: StartDubArgs = {
      roomId: roomId,
      participant: localParticipant,
    };
    socket?.emit("start_dub", args);
  }, [params.room, localParticipant, socket]);

  // Subscribe to dub channel publish
  useEffect(() => {
    if (
      call.dub &&
      numberRemotePeers > 0 &&
      call.translationProvider.voiceToVoiceEnabled
    ) {
      startDub();
      subscribeDubAudio();
      agora.on("user-published", onDubChannelPublished);
    } else {
      stopDub();
      // Unsubscribe all other dub channels
      unsubscribeDubAudio();
    }
    return () => {
      agora.off("user-published", onDubChannelPublished);
    };
  }, [
    call.dub,
    currentLanguage?.code,
    numberRemotePeers,
    call.translationProvider,
    startDub,
    stopDub,
    onDubChannelPublished,
    subscribeDubAudio,
    unsubscribeDubAudio,
  ]);

  useEffect(() => {
    if (
      call.dub &&
      numberRemotePeers > 0 &&
      call.translationProvider.voiceToVoiceEnabled
    ) {
      subscribeDubAudio();
    } else {
      unsubscribeDubAudio();
    }
  }, [
    call.dub,
    numberRemotePeers,
    call.translationProvider.voiceToVoiceEnabled,
    subscribeDubAudio,
    unsubscribeDubAudio,
  ]);

  return useCallback(() => {
    setCall((prevState) => {
      if (!prevState.dub) {
        amplitude?.logEvent(AmplitudeEvent.ENABLE_AI_VOICE);
        startDub();
      } else {
        amplitude?.logEvent(AmplitudeEvent.DISABLE_AI_VOICE);
        stopDub();
      }
      // Set local state
      return {
        ...prevState,
        dub: !prevState.dub,
      };
    });
  }, [setCall, startDub, stopDub]);
};
