import { useEffect } from "react";
import SubscriptionPlans from "../../components/SubscriptionPlans";
import "./Subscription.css";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useToggleSupportModal } from "../../hooks/layout";

function Subscription() {
  const [params] = useSearchParams();

  useEffect(() => {
    console.log(params);
    if (params.get("purchased")) {
      toast(
        <div className="ByrdhouseToast SuccessToast">
          <div>{`Thank you for purchasing Byrdhouse ${params.get(
            "purchased"
          )}!`}</div>
        </div>
      );
    }
  }, [params]);

  const toggleSupportModal = useToggleSupportModal(true);

  return (
    <div className="Subscription">
      <SubscriptionPlans />
      <div className="ManageBilling" onClick={toggleSupportModal}>
        Manage Plan
      </div>
    </div>
  );
}

export default Subscription;
