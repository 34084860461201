import {
  CSSProperties,
  forwardRef,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import { IoChevronDown } from "react-icons/io5";
import { useWindowSize } from "react-use";
import "./Dropdown.css";

export type DropdownHandle = {
  toggleContent: () => void;
};

export type DropdownProps = {
  title: string;
  children: ReactNode;
  toggleStyle?: CSSProperties;
  contentStyle?: CSSProperties;
};

const Dropdown = forwardRef<DropdownHandle, DropdownProps>((props, ref) => {
  const [showContent, setShowContent] = useState<boolean>(false);
  const toggleContent = useCallback(() => {
    setShowContent((prevState) => !prevState);
  }, [setShowContent]);
  useImperativeHandle(ref, () => ({
    toggleContent,
  }));

  const windowSize = useWindowSize();
  const dropdownContentOffset = windowSize.width < 1700 ? 40 : 46;
  const borderWidth = props.toggleStyle?.border
    ? parseInt(props.toggleStyle.border.toString())
    : 0;

  return (
    <div className="Dropdown">
      {showContent && (
        <div
          className="DropdownContent"
          style={{
            bottom: `calc(${dropdownContentOffset}px + ${borderWidth}px`,
          }}
        >
          {props.children}
        </div>
      )}
      <div
        className="DropdownToggle"
        style={props.toggleStyle}
        onClick={toggleContent}
      >
        {props.title} <IoChevronDown />
      </div>
    </div>
  );
});

export default Dropdown;
