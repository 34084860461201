import "./Loader.css";
import { IoSync } from "react-icons/io5";

export type LoaderProps = {
  fullscreen?: boolean;
  size: number;
  noMargin?: boolean;
};

const Loader = (props: LoaderProps) => {
  return (
    <div className={`Loader ${props.fullscreen ? "Fullscreen" : ""}`}>
      <IoSync
        className="Loading"
        style={{
          width: props.size,
          height: props.size,
          margin: props.noMargin ? 0 : undefined,
        }}
      />
    </div>
  );
};

export default Loader;
