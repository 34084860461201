import { useCallback, useContext, useMemo } from "react";
import { GlobalContext } from "../context";
import { useLocalParticipant } from "./user";
import useTranscript from "./transcript";
import amplitude from "../amplitude";
import {
  AmplitudeEvent,
  RoomID,
  SendSummaryRequestArgs,
} from "byrdhouse-types";
import { useParams } from "react-router-dom";
import useSocket from "./socket";

const useSummarizer = () => {
  let context = useContext(GlobalContext);
  return [context.summarizer, context.setSummarizer] as const;
};

const MAX_CAPTIONS = 8;
const MIN_CAPTIONS = 2;

export const useCaptionsNotSummarized = () => {
  const localParticipant = useLocalParticipant();
  const [transcript] = useTranscript();
  const [summarizer] = useSummarizer();

  return useMemo(() => {
    if (!localParticipant) {
      return [];
    }
    return Object.values(transcript.history)
      .filter(
        (caption) => caption.from.sessionId !== localParticipant.sessionId
      )
      .slice(-MAX_CAPTIONS)
      .filter((caption) => !summarizer.summarizedCaptions.includes(caption.id));
  }, [localParticipant, transcript.history, summarizer.summarizedCaptions]);
};

export const useRequestSummary = () => {
  const params = useParams();
  const captionsNotSummarized = useCaptionsNotSummarized();
  const socket = useSocket();
  const [, setSummarizer] = useSummarizer();

  return useCallback(() => {
    amplitude?.logEvent(AmplitudeEvent.CLICK_ON_GENERATE_MAGIC);
    const roomId = params.room as RoomID;
    if (!roomId) {
      return;
    }

    const args: SendSummaryRequestArgs = {
      roomId: roomId,
      captions: captionsNotSummarized,
    };
    socket?.emit("send_request_summary", args);

    // Save the caption ids we summarized
    const captionIds = captionsNotSummarized.map((caption) => caption.id);

    setSummarizer((prevState) => ({
      ...prevState,
      summarizedCaptions: [...prevState.summarizedCaptions, ...captionIds],
    }));
  }, [params.room, socket, captionsNotSummarized, setSummarizer]);
};

export const useDisableSummary = () => {
  const captionsNotSummarized = useCaptionsNotSummarized();
  return useMemo(() => {
    return captionsNotSummarized.length < MIN_CAPTIONS;
  }, [captionsNotSummarized]);
};

export default useSummarizer;
