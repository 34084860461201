import { useCallback, useEffect, useMemo, useState } from "react";
import "./AdminDashboard.css";
import api from "../../api";
import { GetUserAccountResponse, GetUserListResponse } from "byrdhouse-types";
import { UserID } from "byrdhouse-types";
import TranslationUsage from "../../components/TranslationUsage";
import { useSearchParams } from "react-router-dom";

type AccountProps = {
  userId: UserID;
};

function Account(props: AccountProps) {
  const [account, setAccount] = useState<GetUserAccountResponse | undefined>(
    undefined
  );

  const getAccount = useCallback(async () => {
    const response = await api.getAccount({ id: props.userId });
    setAccount(response);
  }, [setAccount, props.userId]);
  useEffect(() => {
    getAccount();
  }, [getAccount]);

  return (
    <div className="Account">
      <p>{`User Id: ${props.userId}`}</p>
      <p>{`Account Owner Id: ${
        account?.owner ? account?.owner : props.userId
      }`}</p>
      {account?.members && account?.featureFlags && (
        <>
          <details>
            <summary>Account Details</summary>
            <h4>{`Members (Max ${account.featureFlags.maxMembers}):`}</h4>
            {account.members.map((m, index) => (
              <p key={index}>{`${m.email}${
                m.userId === account?.owner ? " (Owner)" : ""
              }`}</p>
            ))}
            <h4>Feature Flags:</h4>
            <p>{`Group Call: ${account.featureFlags.groupCallEnabled}`}</p>
            <p>{`Recording: ${account.featureFlags.recordingEnabled}`}</p>
            <p>{`Voice-To-Voice: ${account.featureFlags.voiceToVoiceEnabled}`}</p>
          </details>
        </>
      )}
    </div>
  );
}

function AdminDashboard() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [users, setUsers] = useState<GetUserListResponse["users"]>([]);
  const [selectedUserId, setSelectedUserId] = useState<UserID | undefined>(
    (searchParams.get("userId") as UserID) || undefined
  );

  const getUsersList = useCallback(async () => {
    const response = await api.getUsersList();
    setUsers(response.users);
    if (response.users.length) {
      setSelectedUserId((prevState) => {
        if (!prevState) {
          return response.users[0].id;
        }
        return prevState;
      });
    }
  }, [setUsers]);
  useEffect(() => {
    getUsersList();
  }, [getUsersList]);

  const onSelectUser = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedUserId(event.target.value as UserID);
    },
    [setSelectedUserId]
  );

  useEffect(() => {
    if (selectedUserId) {
      const searchParams = new URLSearchParams();
      searchParams.set("userId", selectedUserId);
      setSearchParams(searchParams);
    }
  }, [setSearchParams, selectedUserId]);

  const [query, setQuery] = useState("");
  const search = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(event.currentTarget.value);
    },
    [setQuery]
  );

  const [filterHasPrepaidMinutes, setFilterHasPrepaidMinutes] = useState(false);
  const [filterHasPro, setFilterHasPro] = useState(false);
  const [filterHasBusiness, setFilterHasBusiness] = useState(false);

  const filteredUsers = useMemo(() => {
    let filtered = users?.filter((u) => u.email.includes(query));
    if (filterHasPrepaidMinutes) {
      filtered = filtered.filter(
        (u) => u.prepaidTranslationSecondsRemaining > 600
      );
    }
    if (filterHasBusiness) {
      filtered = filtered.filter(
        (u) => u.groupCallEnabled && u.recordingEnabled && u.voiceToVoiceEnabled
      );
    } else if (filterHasPro) {
      filtered = filtered.filter(
        (u) =>
          u.groupCallEnabled && u.recordingEnabled && !u.voiceToVoiceEnabled
      );
    }
    setSelectedUserId((prevState) => {
      if (filtered.length && !filtered.find((u) => u.id === prevState)) {
        return filtered[0].id;
      }
      return prevState;
    });
    return filtered;
  }, [query, users, filterHasPrepaidMinutes, filterHasPro, filterHasBusiness]);

  const selectedUser = useMemo(() => {
    return users.find((u) => u.id === selectedUserId);
  }, [users, selectedUserId]);

  return (
    <div className="AdminDashboard">
      <div>Search:</div>
      <input
        type="text"
        placeholder="Search user by email"
        value={query}
        onChange={search}
      />
      <div>Filters:</div>
      <div id="Filters">
        <div>
          <span>{"Has Prepaid Minutes (More than 10):"}</span>
          <input
            type="checkbox"
            checked={filterHasPrepaidMinutes}
            onChange={() => setFilterHasPrepaidMinutes((p) => !p)}
          />
        </div>
        <div>
          <span>Has Pro:</span>
          <input
            type="checkbox"
            checked={filterHasPro}
            onChange={() => setFilterHasPro((p) => !p)}
          />
        </div>
        <div>
          <span>Has Business:</span>
          <input
            type="checkbox"
            checked={filterHasBusiness}
            onChange={() => setFilterHasBusiness((p) => !p)}
          />
        </div>
      </div>
      <select value={selectedUserId} onChange={onSelectUser}>
        {filteredUsers.map((user, index) => (
          <option key={index} value={user.id}>
            {user.email}
          </option>
        ))}
      </select>

      {/* Translation usage */}
      {selectedUserId && <TranslationUsage providerId={selectedUserId} />}

      {/* Account */}
      {selectedUserId && <Account userId={selectedUserId} />}

      {/* Translation Minutes */}
      {selectedUser && (
        <div>
          <div>{`Prepaid Minutes Remaining: ${Math.floor(
            selectedUser.prepaidTranslationSecondsRemaining / 60
          )}`}</div>
          <div>{`Monthly Minutes Remaining: ${Math.floor(
            selectedUser.monthlyTranslationSecondsRemaining / 60
          )}`}</div>
          <div>{`Monthly Minutes: ${Math.floor(
            selectedUser.monthlyTranslationSeconds / 60
          )}`}</div>
        </div>
      )}
    </div>
  );
}

export default AdminDashboard;
