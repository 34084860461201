import {
  IoCheckmark,
  IoChevronDown,
  IoChevronUp,
  IoClose,
} from "react-icons/io5";
import "./SubscriptionPlans.css";
import Button from "./Button";
import {
  useOpenAddMinutes,
  useOpenGetADemo,
  useOpenSignup,
  useToggleJoinNestModal,
} from "../hooks/layout";
import { useCallback, useState } from "react";
import { AmplitudeEvent } from "byrdhouse-types";
import amplitude from "../amplitude";
import useUser from "../hooks/user";

type Feature = {
  title: string;
  enabled: boolean;
};

type PricingPlanProps = {
  title: string;
  yourPlan: boolean;
  recommended?: boolean;
  description: string[];
  details: string[];
  features: Feature[];
};

const PricingPlan = (props: PricingPlanProps) => {
  const [user] = useUser();
  const openSignup = useOpenSignup();
  const openGetADemo = useOpenGetADemo();
  const addMinutes = useOpenAddMinutes();

  const [showFeatures, setShowFeatures] = useState(false);
  const toggleShowFeatures = useCallback(() => {
    setShowFeatures((prevState) => !prevState);
  }, []);

  const clickTryForFree = useCallback(() => {
    amplitude?.logEvent(AmplitudeEvent.CLICK_ON_TRY_FOR_FREE_LANDING_PRICING);
    openSignup();
  }, [openSignup]);

  const toggleBuildNest = useToggleJoinNestModal(true);

  const clickGetADemo = useCallback(() => {
    amplitude?.logEvent(
      !user
        ? AmplitudeEvent.CLICK_ON_GET_A_DEMO_LANDING_PRICING
        : AmplitudeEvent.CLICK_ON_GET_A_DEMO
    );
    openGetADemo();
  }, [user, openGetADemo]);

  const clickContactSales = useCallback(() => {
    amplitude?.logEvent(
      !user
        ? AmplitudeEvent.CLICK_ON_CONTACT_SALES_LANDING_PRICING
        : AmplitudeEvent.CLICK_ON_CONTACT_SALES
    );
    openGetADemo();
  }, [user, openGetADemo]);

  const clickAddMinutes = useCallback(() => {
    amplitude?.logEvent(AmplitudeEvent.CLICK_ON_ADD_MINUTES);
    addMinutes();
  }, [addMinutes]);

  let cta = null;
  const addMinutesButton = (
    <Button
      text={"Add more minutes"}
      background={"gradient"}
      onClick={clickAddMinutes}
    />
  );
  switch (props.title) {
    case "Starter":
      if (user?.plan === "Starter") {
        cta = addMinutesButton;
      } else {
        cta = (
          <Button
            text={"Try for FREE"}
            border={"pink"}
            onClick={!user ? clickTryForFree : toggleBuildNest}
          />
        );
      }
      break;
    case "Pro":
      if (user?.plan === "Pro") {
        cta = addMinutesButton;
      } else {
        cta = (
          <Button
            text={"Get a Demo"}
            background={"gradient"}
            onClick={clickGetADemo}
          />
        );
      }
      break;
    case "Enterprise":
      cta = (
        <Button
          text="Contact Sales"
          background="gradient"
          onClick={clickContactSales}
        />
      );
      break;
  }

  let outline = null;
  if (user && props.yourPlan) {
    outline = <div className="YourPlanLabel">Your Plan</div>;
  }
  if (!user && props.recommended) {
    outline = <div className="YourPlanLabel">Recommended</div>;
  }

  return (
    <div
      className={`PricingPlan ${outline ? "YourPlan" : ""}`}
      style={{
        height: showFeatures ? "100%" : "fit-content",
      }}
    >
      {outline}
      <div className="PlanDescription">
        {props.description.map((i) => (
          <div>{i}</div>
        ))}
      </div>
      <h2>{props.title}</h2>
      <div className="PlanDetails">
        {props.details.map((i) => (
          <div>{i}</div>
        ))}
      </div>
      <div className="CTAWrapper">{cta}</div>
      <div className="FeaturesDropdown">
        <div className="FeaturesDropdownToggle" onClick={toggleShowFeatures}>
          Included Features{" "}
          {!showFeatures ? <IoChevronDown /> : <IoChevronUp />}
        </div>
        <div
          className="Features"
          style={{
            visibility: showFeatures ? "visible" : "hidden",
            overflow: showFeatures ? "auto" : "hidden",
            height: showFeatures ? "fit-content" : "0px",
          }}
        >
          {props.features.map((feature, index) => (
            <div key={index}>
              {feature.enabled ? (
                <IoCheckmark style={{ color: "#36b37e" }} />
              ) : (
                <IoClose style={{ color: "#cc525f" }} />
              )}
              {feature.title}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

function SubscriptionPlans() {
  const [user] = useUser();
  const pricingPlans: PricingPlanProps[] = [
    {
      title: "Starter",
      description: ["FOR 1:1 MULTILINGUAL", "MEETING OR CALL"],
      details: [
        "30 minutes FREE real-time translation",
        "2 attendees per call",
        "Pay as you go",
      ],
      yourPlan: user?.plan === "Starter",
      features: [
        { title: "Voice-to-text transcription", enabled: true },
        { title: "Voice-to-text translation", enabled: true },
        { title: "Accent and dialect coverage", enabled: true },
        { title: "Speaker diarization", enabled: true },
        { title: "Auto-language detect", enabled: true },
        { title: "Translation captions edit", enabled: true },
        { title: "Profanity detection", enabled: true },
        { title: "Meeting transcript", enabled: false },
        { title: "Recording", enabled: false },
        { title: "Group calls", enabled: false },
        {
          title: "Voice-to-voice translation (100+ languages and dialects)",
          enabled: false,
        },
      ],
    },
    {
      title: "Pro",
      description: ["FOR ORGANIZATIONS WITH", "MULTILINGUAL", "COMMUNICATION"],
      details: [
        "Minimum 10 hours monthly usage",
        "100 attendees per meeting",
        "Subscription based",
      ],
      yourPlan: user?.plan === "Pro",
      recommended: true,
      features: [
        { title: "Voice-to-text transcription", enabled: true },
        { title: "Voice-to-text translation", enabled: true },
        { title: "Accent and dialect coverage", enabled: true },
        { title: "Speaker diarization", enabled: true },
        { title: "Auto-language detect", enabled: true },
        { title: "Translation captions edit", enabled: true },
        { title: "Profanity detection", enabled: true },
        { title: "Meeting transcript", enabled: true },
        { title: "Recording", enabled: true },
        { title: "Group calls", enabled: true },
        {
          title: "Voice-to-voice translation (100+ languages and dialects)",
          enabled: true,
        },
        {
          title: "A-Z technical support",
          enabled: true,
        },
      ],
    },
    {
      title: "Enterprise",
      description: [
        "FOR MULTILINGUAL",
        "ENTERPRISES, GLOBAL",
        "EVENTS AND CONFERENCES",
      ],
      details: [
        "Unlimited real-time translation",
        "Unlimited attendees",
        "Platform integration available",
      ],
      yourPlan: user?.plan === "Enterprise",
      features: [
        { title: "Unlimited attendees", enabled: true },
        {
          title: "Online, in-person, hybrid events and conferences",
          enabled: true,
        },
        { title: "Customized 3rd party integration", enabled: true },
        { title: "Voice-to-text transcription", enabled: true },
        { title: "Voice-to-text translation", enabled: true },
        { title: "Accent and dialect coverage", enabled: true },
        { title: "Speaker diarization", enabled: true },
        { title: "Translated text chat", enabled: true },
        { title: "Auto-language detect", enabled: true },
        { title: "Translation captions edit", enabled: true },
        { title: "Profanity detection", enabled: true },
        { title: "Meeting transcript", enabled: true },
        { title: "Recording", enabled: true },
        { title: "Group calls", enabled: true },
        {
          title: "Voice-to-voice translation (100+ languages and dialects)",
          enabled: true,
        },
        { title: "A-Z technical support", enabled: true },
        { title: "Call-center & telephony integration", enabled: true },
      ],
    },
  ];

  return (
    <div className="SubscriptionPlans">
      <div className="PricingPlans">
        {pricingPlans.map((plan, index) => (
          <PricingPlan
            key={index}
            description={plan.description}
            details={plan.details}
            title={plan.title}
            yourPlan={plan.yourPlan}
            recommended={plan.recommended}
            features={plan.features}
          />
        ))}
      </div>
    </div>
  );
}

export default SubscriptionPlans;
