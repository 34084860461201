import useCall, {
  useEndScreenShare,
  useLocalOrRemoteScreenShareActive,
  useRemotePeerScreenSharing,
  useRenderHostControls,
  useToggleCamera,
  useToggleMic,
  useUpdateRemotePeer,
} from "../hooks/call";
import Stream from "./Stream";
import "./Participants.css";
import {
  IoChevronBack,
  IoChevronDown,
  IoChevronForward,
  IoChevronUp,
  IoClose,
} from "react-icons/io5";
import {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import agora from "../agora";
import useUser, { useLocalParticipant } from "../hooks/user";
import useLayout, { useTogglePipOff } from "../hooks/layout";
import { useWindowSize } from "react-use";
import { Participant, SessionID, UserID } from "byrdhouse-types";
import { useGetInitials } from "../hooks/utils";
import { speechLanguageLocaleToFlag } from "../utils";
import { IRemoteVideoTrack } from "agora-rtc-sdk-ng";
import useTranscript from "../hooks/transcript";
import { FiShare } from "react-icons/fi";
import { ReactComponent as RecordStatus } from "../assets/record-red.svg";
import { useToggleConfirmRecordingActionModal } from "../hooks/layout";

const Participants = () => {
  const [user] = useUser();
  const [call, setCall] = useCall();
  const [remotePeerIndex, setRemotePeerIndex] = useState<number>(0);

  // Remote peers here are the rendered peers filtered through various logic (not usually an exhaustive list of all connected peers)
  const [shownRemotePeers, setShownRemotePeers] = useState<Participant[]>([]);
  const [hiddenRemotePeers, setHiddenRemotePeers] = useState<Participant[]>([]);

  const [layout] = useLayout();
  const localOrRemoteScreenShareActive = useLocalOrRemoteScreenShareActive();

  // After-effects when remote peer list updates
  const remotePeerScreenSharing = useRemotePeerScreenSharing();
  const localParticipant = useLocalParticipant();

  const pinnedSpeaker = useMemo(() => {
    if (!call.pinnedSpeaker) {
      return;
    }
    return call.remotePeers[call.pinnedSpeaker];
  }, [call.remotePeers, call.pinnedSpeaker]);

  const screenShareOrPinnedSpeaker = useMemo(() => {
    return (
      localOrRemoteScreenShareActive ||
      (pinnedSpeaker && Object.values(call.remotePeers).length > 1)
    );
  }, [localOrRemoteScreenShareActive, pinnedSpeaker, call.remotePeers]);

  const maxShownParticipants = useMemo(() => {
    return screenShareOrPinnedSpeaker ? 4 : 9;
  }, [screenShareOrPinnedSpeaker]);

  useEffect(() => {
    const remotePeers = Object.values(call.remotePeers);
    let shownPeers = remotePeers;

    if (remotePeers.length >= maxShownParticipants) {
      let lastSpeakers = call.lastSpeakers
        .map((id) => call.remotePeers[id])
        .filter((peer) => !!peer);
      shownPeers = [
        ...lastSpeakers,
        ...shownPeers.filter(
          (peer) => !call.lastSpeakers.includes(peer.sessionId)
        ),
      ];
    }

    // Always show local user as first attendee
    if (!localParticipant) {
      return;
    }
    shownPeers = [localParticipant, ...shownPeers];

    let hiddenPeers = shownPeers;

    // Filter remote peer screen sharing
    if (remotePeerScreenSharing) {
      shownPeers = shownPeers.filter(
        (peer) => peer.sessionId !== remotePeerScreenSharing.sessionId
      );
    }

    // Check if remote peer index needs to be adjusted for remote peer size
    if (
      Math.min(
        remotePeerIndex + shownPeers.length,
        remotePeerIndex + maxShownParticipants
      ) > shownPeers.length
    ) {
      setRemotePeerIndex(remotePeerIndex - 1);
    }

    // Only show max peer list with carousel
    shownPeers = shownPeers.slice(
      remotePeerIndex,
      Math.min(
        remotePeerIndex + shownPeers.length,
        remotePeerIndex + maxShownParticipants
      )
    );

    // Filter pinned speaker
    if (
      pinnedSpeaker &&
      remotePeers.length >= 2 &&
      !localOrRemoteScreenShareActive
    ) {
      shownPeers = shownPeers.filter(
        (peer) => peer.sessionId !== pinnedSpeaker.sessionId
      );
    }

    // Get list of hidden peers after slicing
    hiddenPeers = hiddenPeers.filter((peer) => !shownPeers.includes(peer));

    // Subscribe to all rendered peers (if not already)
    shownPeers
      .filter((peer) => peer.sessionId !== localParticipant?.sessionId)
      .forEach(async (peer) => {
        let remoteAgoraUser = agora.remoteUsers.find(
          (u) => u.uid === peer.sessionId
        );
        if (
          remoteAgoraUser &&
          remoteAgoraUser.hasVideo &&
          !remoteAgoraUser.videoTrack
        ) {
          let remoteTrack = await agora.subscribe(remoteAgoraUser, "video");
          setCall((prevState) => {
            // If a tracks already exist, add the track to the object
            // If not create a new tracks object
            let remoteTracks = prevState.remoteTracks[peer.sessionId] || {};
            remoteTracks.videoTrack = remoteTrack;

            return {
              ...prevState,
              remoteTracks: {
                ...prevState.remoteTracks,
                [peer.sessionId]: remoteTracks,
              },
            };
          });
        }
      });

    // Unsubscribe from all hidden peers
    hiddenPeers
      .filter((peer) => peer.sessionId !== localParticipant?.sessionId)
      .filter((peer) => peer.sessionId !== pinnedSpeaker?.sessionId)
      .filter((peer) => peer.sessionId !== remotePeerScreenSharing?.sessionId)
      .forEach(async (peer) => {
        let remoteAgoraUser = agora.remoteUsers.find(
          (u) => u.uid === peer.sessionId
        );
        if (
          remoteAgoraUser &&
          remoteAgoraUser.hasVideo &&
          remoteAgoraUser.videoTrack
        ) {
          await agora.unsubscribe(remoteAgoraUser, "video");
          setCall((prevState) => {
            let remoteTracks = prevState.remoteTracks[peer.sessionId];
            if (remoteTracks) {
              delete remoteTracks.videoTrack;
              return {
                ...prevState,
                remoteTracks: {
                  ...prevState.remoteTracks,
                  [peer.sessionId]: remoteTracks,
                },
              };
            }

            return prevState;
          });
        }
      });

    setShownRemotePeers(shownPeers);
    setHiddenRemotePeers(hiddenPeers);

    return () => {};
  }, [
    user,
    call.remotePeers,
    call.lastSpeakers,
    call.localScreenShareTrack,
    remotePeerIndex,
    remotePeerScreenSharing,
    setCall,
    localParticipant,
    maxShownParticipants,
    pinnedSpeaker,
    localOrRemoteScreenShareActive,
  ]);

  const resubscribeToPinnedSpeaker = useCallback(
    async (pinnedSpeaker: Participant) => {
      const remoteAgoraUser = agora.remoteUsers.find(
        (u) => u.uid === pinnedSpeaker.sessionId
      );
      if (remoteAgoraUser) {
        await agora.unsubscribe(remoteAgoraUser, "video");
        const remoteTrack = await agora.subscribe(remoteAgoraUser, "video");
        setCall((prevState) => {
          // If a tracks already exist, add the track to the object
          // If not create a new tracks object
          const remoteTracks =
            prevState.remoteTracks[pinnedSpeaker.sessionId] || {};
          remoteTracks.videoTrack = remoteTrack;

          return {
            ...prevState,
            remoteTracks: {
              ...prevState.remoteTracks,
              [pinnedSpeaker.sessionId]: remoteTracks,
            },
          };
        });
      }
    },
    [setCall]
  );

  const lastPinnedSpeakerSessionId = useRef<SessionID | undefined>(undefined);
  useEffect(() => {
    if (!pinnedSpeaker || localOrRemoteScreenShareActive) {
      lastPinnedSpeakerSessionId.current = undefined;
      return;
    }
    if (lastPinnedSpeakerSessionId.current !== pinnedSpeaker.sessionId) {
      lastPinnedSpeakerSessionId.current = pinnedSpeaker.sessionId;
      resubscribeToPinnedSpeaker(pinnedSpeaker);
    }
  }, [
    pinnedSpeaker,
    resubscribeToPinnedSpeaker,
    localOrRemoteScreenShareActive,
  ]);

  const numRemotePeers = useMemo(() => {
    return shownRemotePeers.length + hiddenRemotePeers.length;
  }, [shownRemotePeers, hiddenRemotePeers]);

  const onMoveListUp = useCallback(() => {
    setRemotePeerIndex((prevState) => Math.max(prevState - 1, 0));
  }, [setRemotePeerIndex]);

  const onMoveListDown = useCallback(() => {
    setRemotePeerIndex((prevState) =>
      Math.min(prevState + 1, numRemotePeers - 1)
    );
  }, [setRemotePeerIndex, numRemotePeers]);

  // Check if we should disable up/down buttons
  const disableUp = useMemo(() => {
    return numRemotePeers < maxShownParticipants || remotePeerIndex === 0;
  }, [numRemotePeers, maxShownParticipants, remotePeerIndex]);

  const disableDown = useMemo(() => {
    return (
      numRemotePeers < maxShownParticipants ||
      remotePeerIndex === numRemotePeers - maxShownParticipants
    );
  }, [numRemotePeers, maxShownParticipants, remotePeerIndex]);

  const showArrows = useMemo(() => {
    return numRemotePeers > maxShownParticipants;
  }, [numRemotePeers, maxShownParticipants]);

  const totalLinesToRender = 3;
  const pipStyles = useMemo(() => {
    return {
      videoWidth: 640,
      videoHeight: 480,
      padding: 40,
      maxStreams: 3,
      captionsFontSize: 48,
      captionsHeight: 48 * totalLinesToRender + 40,
    };
  }, []);

  const getRemoteVideoTrack = useCallback(
    (participant: Participant) => {
      return call.remoteTracks[participant.sessionId]?.videoTrack;
    },
    [call.remoteTracks]
  );

  const drawNoStream = useCallback(
    (
      context: CanvasRenderingContext2D,
      x: number,
      y: number,
      initials?: string
    ) => {
      context.fillStyle = "black";
      context.fillRect(x, y, pipStyles.videoWidth, pipStyles.videoHeight);
      // Draw initials
      if (initials) {
        context.fillStyle = "#e76399";
        context.beginPath();
        context.arc(
          x + pipStyles.videoWidth / 2,
          y + pipStyles.videoHeight / 2,
          50,
          0,
          2 * Math.PI
        );
        context.fill();
        context.fillStyle = "white";
        context.font = "48px sans-serif";
        context.textAlign = "center";
        context.textBaseline = "middle";
        context.fillText(
          initials,
          x + pipStyles.videoWidth / 2,
          y + pipStyles.videoHeight / 2
        );
      }
    },
    [pipStyles.videoWidth, pipStyles.videoHeight]
  );

  const [transcript] = useTranscript();
  const drawCaptions = useCallback(
    (context: CanvasRenderingContext2D, x: number, y: number) => {
      if (!localParticipant) {
        return;
      }

      // Determine which captions to render
      const lastCaptions = Object.values(transcript.captions)
        .filter(
          (caption) => caption.from.sessionId !== localParticipant.sessionId
        )
        .slice(-totalLinesToRender);
      const lastInterimCaptions = Object.values(transcript.interimCaptions)
        .filter(
          (caption) => caption.from.sessionId !== localParticipant.sessionId
        )
        .slice(-totalLinesToRender);

      const captionsToRender = lastCaptions
        .concat(lastInterimCaptions)
        .slice(-totalLinesToRender)
        .map((caption) => caption.text);

      const fontSize = pipStyles.captionsFontSize;
      context.fillStyle = "white";
      context.font = `${fontSize}px sans-serif`;
      context.textAlign = "left";

      captionsToRender
        .reduce((result: string[], text: string) => {
          let currentLineStart = 0;
          for (let i = 0; i < text.length; i++) {
            const line = text.substring(currentLineStart, i + 1);
            const measure = context.measureText(line);
            if (
              measure.width >= pipStyles.videoWidth - pipStyles.padding ||
              i >= text.length - 1
            ) {
              const lastSpaceIndex = line.lastIndexOf(" ");
              if (lastSpaceIndex > 0 && i < text.length - 1) {
                const lineToLastSpace = line.substring(0, lastSpaceIndex);
                result.push(lineToLastSpace.trim());
                currentLineStart += lastSpaceIndex;
              } else {
                result.push(line.trim());
                currentLineStart = i + 1;
              }
            }
          }
          return result;
        }, [])
        .slice(-totalLinesToRender)
        .forEach((line, index) => {
          context.fillText(line, x, index * fontSize + y);
        });
    },
    [
      localParticipant,
      transcript.captions,
      transcript.interimCaptions,
      pipStyles.padding,
      pipStyles.videoWidth,
      pipStyles.captionsFontSize,
    ]
  );

  const renderHostControls = useRenderHostControls();
  const drawTooltips = useCallback(
    (
      context: CanvasRenderingContext2D,
      x: number,
      y: number,
      participant: Participant
    ) => {
      const padding = 20;
      const textSize = 36;
      context.fillStyle = "white";
      context.font = `${textSize}px sans-serif`;
      context.textAlign = "left";
      context.textBaseline = "hanging";
      context.fillText(
        `${
          participant.autoDetect
            ? "✨"
            : speechLanguageLocaleToFlag(participant.speechLanguageLocale)
        } ${participant.displayName}${
          renderHostControls(participant) ? " (Host)" : ""
        }`,
        x + padding,
        y + padding,
        pipStyles.videoWidth - padding * 2
      );
    },
    [pipStyles.videoWidth, renderHostControls]
  );

  type PipVideoTrack = {
    participant: Participant;
    track?: IRemoteVideoTrack;
  };

  // PIP window rendering
  const canvas = createRef<HTMLCanvasElement>();
  const getInitials = useGetInitials();
  useEffect(() => {
    let animationFrameId: number;
    const render = async () => {
      if (!canvas.current) {
        return;
      }
      const context = canvas.current.getContext("2d");
      if (context) {
        // Clear the last frame
        context.clearRect(0, 0, canvas.current.width, canvas.current.height);
        // Render local user on top (always) if local camera track visible and playing
        const localCameraTrackPlaying =
          call.localCameraTrack && call.localCameraTrack.isPlaying;
        let noStream = true;
        if (localCameraTrackPlaying) {
          const imageData = call.localCameraTrack!.getCurrentFrameData();
          if (imageData.width > 0 && imageData.height > 0) {
            noStream = false;
            const bitmap = await createImageBitmap(imageData);
            context.scale(-1, 1);
            context.drawImage(
              bitmap,
              -pipStyles.padding,
              pipStyles.padding,
              -pipStyles.videoWidth,
              pipStyles.videoHeight
            );
            context.scale(-1, 1);
          }
        }
        if (localParticipant) {
          if (noStream) {
            drawNoStream(
              context,
              pipStyles.padding,
              pipStyles.padding,
              getInitials(localParticipant.displayName)
            );
          }
          drawTooltips(
            context,
            pipStyles.padding,
            pipStyles.padding,
            localParticipant
          );
        }
        // Render 2 remote video tracks
        const remoteTracks: PipVideoTrack[] = shownRemotePeers
          .filter(
            (participant) =>
              participant.sessionId !== localParticipant?.sessionId
          )
          .map((participant) => ({
            participant: participant,
            track: getRemoteVideoTrack(participant),
          }))
          .slice(0, pipStyles.maxStreams - 1);
        await Promise.all(
          remoteTracks.map(async (remoteTrack, index) => {
            const position = index + 1;
            // Get position/size data for frame to render
            const x = pipStyles.padding;
            const y =
              (position + 1) * pipStyles.padding +
              position * pipStyles.videoHeight;
            const width = pipStyles.videoWidth;
            const height = pipStyles.videoHeight;
            // Draw the track to the pip window
            let noStream = true;
            if (remoteTrack.track && remoteTrack.track.isPlaying) {
              const imageData = remoteTrack.track.getCurrentFrameData();
              if (imageData.width > 0 && imageData.height > 0) {
                noStream = false;
                const bitmap = await createImageBitmap(imageData);
                context.drawImage(bitmap, x, y, width, height);
              }
            }
            if (noStream) {
              drawNoStream(
                context,
                x,
                y,
                getInitials(remoteTrack.participant.displayName)
              );
            }
            drawTooltips(context, x, y, remoteTrack.participant);
          })
        );
        drawCaptions(
          context,
          pipStyles.padding,
          (1 + remoteTracks.length) *
            (pipStyles.videoHeight + pipStyles.padding) +
            pipStyles.padding
        );
      }
      animationFrameId = requestAnimationFrame(render);
    };
    render();
    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [
    canvas,
    pipStyles,
    getRemoteVideoTrack,
    shownRemotePeers,
    call.localCameraTrack,
    drawNoStream,
    getInitials,
    localParticipant,
    call.localScreenShareTrack,
    drawTooltips,
    drawCaptions,
  ]);

  const toggleMic = useToggleMic();
  const toggleCamera = useToggleCamera();
  const togglePipOff = useTogglePipOff();

  // Pip enabled
  const pipWindowOpen = useRef(false);
  useEffect(() => {
    let stream: MediaStream;
    let videoElement: HTMLVideoElement;
    if (layout.pipActive && canvas.current && !pipWindowOpen.current) {
      pipWindowOpen.current = true;
      const stream = canvas.current.captureStream();
      if (stream) {
        const videoElement = document.createElement("video");
        videoElement.hidden = true;
        videoElement.muted = true;
        videoElement.autoplay = true;
        videoElement.srcObject = stream;
        videoElement.play();
        videoElement.onloadedmetadata = async () => {
          // @ts-ignore
          navigator.mediaSession.setActionHandler("togglemicrophone", () => {
            toggleMic();
          });
          // @ts-ignore
          navigator.mediaSession.setActionHandler("togglecamera", () => {
            toggleCamera();
          });

          videoElement.onleavepictureinpicture = () => {
            togglePipOff();
          };

          try {
            await videoElement.requestPictureInPicture();
          } catch {
            // Took too long to start screen share
            togglePipOff();
          }
        };
      }
    }

    return () => {
      if (!layout.pipActive) {
        stream?.getTracks().forEach((track) => track.stop());
        videoElement?.remove();
        pipWindowOpen.current = false;
      }
    };
  }, [layout.pipActive, canvas, toggleMic, toggleCamera, togglePipOff]);

  useEffect(() => {
    if (layout.pipActive) {
      // @ts-ignore
      navigator.mediaSession.setMicrophoneActive(!call.micMuted);
      // @ts-ignore
      navigator.mediaSession.setCameraActive(!call.cameraMuted);
    }
  }, [layout.pipActive, call.micMuted, call.cameraMuted]);

  const numParticipants = useMemo(() => {
    return Object.values(call.remotePeers).length + 1;
  }, [call.remotePeers]);

  const renderPip = useMemo(() => {
    if (layout.pipActive) {
      return (
        <canvas
          ref={canvas}
          width={pipStyles.videoWidth + pipStyles.padding * 2}
          height={
            pipStyles.videoHeight *
              Math.min(shownRemotePeers.length, pipStyles.maxStreams) +
            pipStyles.padding *
              (Math.min(shownRemotePeers.length, pipStyles.maxStreams) + 1) +
            pipStyles.captionsHeight
          }
        />
      );
    }
  }, [layout.pipActive, pipStyles, canvas, shownRemotePeers.length]);

  const windowSize = useWindowSize();

  const callPanelRef = useRef<HTMLDivElement>(null);
  const renderOneOnOneCall = useMemo(() => {
    if (
      !localParticipant ||
      !callPanelRef.current ||
      layout.resize.topHeightInPercent < 0
    ) {
      return;
    }
    const mainStreamWidthInPixels =
      (16 / 9) * (callPanelRef.current.clientHeight - 40);
    // Only local user in call
    if (shownRemotePeers.length <= 1) {
      return (
        <div className="Participants">
          <Stream
            audioTrack={call.localMicrophoneTrack}
            videoTrack={call.localCameraTrack}
            user={localParticipant}
            style={{
              fontSize: `${windowSize.width > 1700 ? 16 : 12}px`,
              width: `${mainStreamWidthInPixels}px`,
              height: "calc(100% - 40px)",
            }}
            local={true}
            borderRadius={true}
          />
        </div>
      );
    }
    // 2 users in call
    else {
      const remotePeer = shownRemotePeers.filter(
        (peer) => peer.sessionId !== localParticipant.sessionId
      )[0];
      return (
        <div className="Participants">
          <div className="FloatingLocalParticipant">
            <Stream
              audioTrack={call.localMicrophoneTrack}
              videoTrack={call.localCameraTrack}
              user={localParticipant}
              style={{
                fontSize: `${windowSize.width > 1700 ? 16 : 12}px`,
              }}
              local={true}
              borderRadius={true}
            />
          </div>
          <Stream
            audioTrack={call.remoteTracks[remotePeer.sessionId]?.audioTrack}
            videoTrack={call.remoteTracks[remotePeer.sessionId]?.videoTrack}
            user={remotePeer}
            style={{
              fontSize: `${windowSize.width > 1700 ? 16 : 12}px`,
              width: `${mainStreamWidthInPixels}px`,
              height: "calc(100% - 40px)",
            }}
            borderRadius={true}
          />
        </div>
      );
    }
  }, [
    localParticipant,
    shownRemotePeers,
    call,
    windowSize,
    layout.resize.topHeightInPercent,
  ]);

  const updateRemotePeer = useUpdateRemotePeer();
  useEffect(() => {
    (window as any).addMockParticipant = () => {
      const id = Date.now().toString();
      updateRemotePeer({
        userId: id as UserID,
        sessionId: id as SessionID,
        displayName: `Mock Participant ${id}`,
        speechLanguageLocale: {
          code: "en-US",
          display: "English (United States)",
        },
        voiceGender: "male",
        autoDetect: false,
        timezone: "America/Chicago",
        screenSharing: false,
        muted: true,

        monthlyTranslationSeconds: 0,
        monthlyTranslationSecondsRemaining: 0,
        prepaidTranslationSecondsRemaining: 0,

        groupCallEnabled: false,
        recordingEnabled: false,
        voiceToVoiceEnabled: false,
        maxMembers: 2,

        providerId: null,
        pendingMembers: [],

        plan: "Starter",

        profanityFilter: true,
      });
    };
  }, [updateRemotePeer]);

  // Split participant arrays to groups
  const groupParticipants = useCallback(
    (indices: number[], arr: Participant[]) => {
      let result = [];
      let currentIndex = 0;
      for (let i = 0; i < indices.length; i++) {
        const sliceStart = currentIndex;
        const sliceEnd = currentIndex + indices[i];
        result.push(arr.slice(sliceStart, sliceEnd));
        currentIndex = sliceEnd;
      }
      if (currentIndex < arr.length) {
        result.push(arr.slice(currentIndex));
      }
      return result;
    },
    []
  );

  const participantGridRef = useRef<HTMLDivElement>(null);

  const participantRows = useMemo(() => {
    // Template for group call participant layout
    const participantGroupings: { [numParticipants: number]: number[] } = {
      3: [2, 1],
      4: [2, 2],
      5: [3, 2],
      6: [3, 3],
      7: [3, 3, 1],
      8: [3, 3, 2],
      9: [3, 3, 3],
    };
    if (shownRemotePeers.length < 3) {
      return [];
    }
    return groupParticipants(
      participantGroupings[shownRemotePeers.length],
      shownRemotePeers
    );
  }, [shownRemotePeers, groupParticipants]);

  const mapParticipantsGroup = useCallback(
    (participants: Participant[], column: boolean) => {
      if (layout.resize.topHeightInPercent < 0) {
        return;
      }
      return participants.map((participant, index) => {
        const local = localParticipant?.sessionId === participant.sessionId;
        const audioTrack = local
          ? call.localMicrophoneTrack
          : call.remoteTracks[participant.sessionId]?.audioTrack;
        const videoTrack = local
          ? call.localCameraTrack
          : call.remoteTracks[participant.sessionId]?.videoTrack;

        if (!participantGridRef.current || !participantRows) {
          return [];
        }

        const widthInPixels = !column
          ? ((16 / 9) * participantGridRef.current.clientHeight) /
            participantRows.length
          : ((16 / 9) * participantGridRef.current.clientHeight) /
            participants.length;

        return (
          <Stream
            key={index}
            audioTrack={audioTrack}
            videoTrack={videoTrack}
            user={participant}
            style={{
              fontSize: `${windowSize.width > 1700 ? 16 : 12}px`,
              width: column ? "100%" : `${100 / participants.length}%`,
              maxWidth: widthInPixels ? `${widthInPixels}px` : "100%",
              height: column
                ? `${100 / Math.max(participants.length, 2)}%`
                : "100%",
            }}
            borderRadius={true}
            local={local ? true : false}
          />
        );
      });
    },
    [
      localParticipant,
      participantRows,
      windowSize,
      layout.resize.topHeightInPercent,
      call.localMicrophoneTrack,
      call.localCameraTrack,
      call.remoteTracks,
    ]
  );

  const renderGroupCall = useMemo(() => {
    return (
      <div
        className="ParticipantsWrapper"
        style={{
          flexDirection: screenShareOrPinnedSpeaker ? "column" : "row",
          overflow: "hidden",
        }}
        ref={participantGridRef}
      >
        {/* Up/Left arrow */}
        {showArrows &&
          (screenShareOrPinnedSpeaker ? (
            <IoChevronUp
              style={{
                visibility: disableUp ? "hidden" : "visible",
                width: "100%",
                height: "auto",
              }}
              onClick={onMoveListUp}
            />
          ) : (
            <IoChevronBack
              style={{
                visibility: disableUp ? "hidden" : "visible",
                width: "auto",
                height: "100%",
              }}
              onClick={onMoveListUp}
            />
          ))}
        <div
          className="Participants"
          style={
            screenShareOrPinnedSpeaker
              ? {
                  width: "100%",
                  flexGrow: 1,
                  padding: shownRemotePeers.length < 3 ? "20% 0px" : 0,
                }
              : {}
          }
        >
          {/* Participant grid */}
          {screenShareOrPinnedSpeaker
            ? mapParticipantsGroup(shownRemotePeers, true)
            : participantRows.map((row) => (
                <div
                  className="ParticipantRow"
                  style={{
                    height: `${100 / participantRows.length}%`,
                  }}
                >
                  {mapParticipantsGroup(row, false)}
                </div>
              ))}
        </div>
        {/* Down/Right arrow */}
        {showArrows &&
          (screenShareOrPinnedSpeaker ? (
            <IoChevronDown
              style={{
                visibility: disableDown ? "hidden" : "visible",
                width: "100%",
                height: "auto",
              }}
              onClick={onMoveListDown}
            />
          ) : (
            <IoChevronForward
              style={{
                visibility: disableDown ? "hidden" : "visible",
                width: "auto",
                height: "100%",
              }}
              onClick={onMoveListDown}
            />
          ))}
      </div>
    );
  }, [
    screenShareOrPinnedSpeaker,
    shownRemotePeers,
    showArrows,
    onMoveListDown,
    onMoveListUp,
    mapParticipantsGroup,
    participantRows,
    disableUp,
    disableDown,
  ]);

  const endScreenShare = useEndScreenShare();
  const renderScreenShare = useMemo(() => {
    if (screenShareOrPinnedSpeaker) {
      if (call.localScreenShareTrack && localParticipant) {
        return (
          <div className="ScreenShare">
            <div className="LocalScreenShare">
              <FiShare />
              <span>You are sharing your screen</span>
              <button className="StopScreenShare" onClick={endScreenShare}>
                <IoClose />
                Stop Sharing
              </button>
            </div>
          </div>
        );
      }
      if (remotePeerScreenSharing) {
        const videoTrack =
          call.remoteTracks[remotePeerScreenSharing.sessionId]?.videoTrack;
        return (
          <div className="ScreenShare">
            <Stream
              audioTrack={
                call.remoteTracks[remotePeerScreenSharing.sessionId]?.audioTrack
              }
              videoTrack={
                call.remoteTracks[remotePeerScreenSharing.sessionId]?.videoTrack
              }
              user={remotePeerScreenSharing}
              screenShareLoading={call.screenShareLoading || !videoTrack}
              fit="contain"
              style={{
                fontSize: `${windowSize.width > 1700 ? 16 : 12}px`,
                width: "100%",
                height: "100%",
              }}
            />
          </div>
        );
      }
      if (pinnedSpeaker) {
        return (
          <div className="ScreenShare">
            <Stream
              audioTrack={
                call.remoteTracks[pinnedSpeaker.sessionId]?.audioTrack
              }
              videoTrack={
                call.remoteTracks[pinnedSpeaker.sessionId]?.videoTrack
              }
              user={pinnedSpeaker}
              fit="cover"
              style={{
                fontSize: `${windowSize.width > 1700 ? 16 : 12}px`,
                width: "100%",
                height: "100%",
              }}
            />
          </div>
        );
      }
    }
  }, [
    screenShareOrPinnedSpeaker,
    call.localScreenShareTrack,
    call.screenShareLoading,
    localParticipant,
    call.remoteTracks,
    remotePeerScreenSharing,
    endScreenShare,
    windowSize,
    pinnedSpeaker,
  ]);

  const toggleConfirmRecordingActionModal =
    useToggleConfirmRecordingActionModal();

  return (
    <div className="CallPanel" ref={callPanelRef}>
      <div className="Stars" />
      {renderPip}
      {/* Participants */}
      {numParticipants > 2 || !!screenShareOrPinnedSpeaker
        ? renderGroupCall
        : renderOneOnOneCall}
      {/* ScreenShare */}
      {renderScreenShare}
      {call.recording && (
        <div className="RecordingStatus">
          <RecordStatus />
          Recording in progress
          <span onClick={toggleConfirmRecordingActionModal}>Stop</span>
        </div>
      )}
    </div>
  );
};

export default Participants;
