import { useCallback, useContext, useEffect, useRef } from "react";
import { io } from "socket.io-client";
import { getFirebaseToken } from "../api";
import * as Sentry from "@sentry/react";
import { EncodedToken } from "byrdhouse-types";
import useSession from "./session";
import { LocalStorageKeys } from "../types";
import { GlobalContext } from "../context";

const useSetSocket = () => {
  const context = useContext(GlobalContext);
  return context.setSocket;
};

const useSocket = () => {
  const context = useContext(GlobalContext);
  return context.socket.client;
};

export const useSocketConnectionManager = () => {
  const token = useRef<EncodedToken | undefined>();
  const getToken = useCallback(async () => {
    return (
      localStorage.getItem(LocalStorageKeys.BYRDHOUSE_TOKEN) ||
      (await getFirebaseToken())
    );
  }, []);
  // Get token on interval (bit of a hack to refresh firebase token synchronously)
  useEffect(() => {
    const tokenRefreshInterval = setInterval(async () => {
      token.current = await getToken();
    }, 1000 * 60 * 1);
    return () => {
      clearInterval(tokenRefreshInterval);
    };
  }, [getToken]);
  const [session] = useSession();

  const setSocket = useSetSocket();
  const state = useRef<"disconnected" | "connecting" | "connected">(
    "disconnected"
  );

  const connect = useCallback(async () => {
    token.current = await getToken();
    if (!token.current || !session.id) {
      console.debug("Token exists", !!token.current);
      console.debug("Session id exists", !!session.id);
      throw new Error("No token or session id found!");
    }
    // Already connecting
    if (state.current === "connecting" || state.current === "connected") {
      return;
    }
    state.current = "connecting";
    console.debug("Socket connecting...");

    const client = io(process.env.REACT_APP_BYRDHOUSE_API_URL!, {
      query: { token: token.current, sessionId: session.id },
      reconnection: true,
      reconnectionDelay: 500,
      reconnectionDelayMax: 3000,
      reconnectionAttempts: 100,
    });
    client.on("connect", () => {
      // Connected
      state.current = "connected";
      console.debug("Socket connected", client.id);
      setSocket((prevState) => ({
        ...prevState,
        client: client,
      }));
    });
    client.on("connect_error", (error) => {
      state.current = "disconnected";
      console.error("Socket connect error", error);
      Sentry.captureException(error);
    });
    client.on("disconnect", (reason) => {
      state.current = "disconnected";
      console.debug("Socket disconnected", reason);
    });

    // Listen for reconnection events
    client.io.on("reconnect_attempt", () => {
      console.debug("Socket attempting to reconnect...");
      // Re-auth with new token
      client.io.opts.query = {
        token: token.current,
        sessionId: session.id,
      };
    });

    client.io.on("reconnect_error", (error) => {
      console.error("Socket threw error attempting to reconnect", error);
      Sentry.captureException(error);
    });

    client.io.on("reconnect_failed", () => {
      console.error("Socket failed to reconnect");
    });
  }, [getToken, setSocket, session.id]);

  useEffect(() => {
    connect();
    return () => {
      setSocket((prevState) => {
        prevState.client?.disconnect();
        return {
          ...prevState,
          client: undefined,
        };
      });
    };
  }, [connect, setSocket]);
};

export default useSocket;
