"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRecordingTitle = exports.getSpeechLanguageLocale = void 0;
var languages_1 = require("./languages");
// Get speech language locale from BCP-47 string
var getSpeechLanguageLocale = function (locale) {
    var supported = (0, languages_1.getSupportedSpeechLanguageLocales)();
    for (var _i = 0, supported_1 = supported; _i < supported_1.length; _i++) {
        var speechLanguageLocale = supported_1[_i];
        if (speechLanguageLocale.code === locale) {
            return speechLanguageLocale;
        }
    }
    return supported[0];
};
exports.getSpeechLanguageLocale = getSpeechLanguageLocale;
var getRecordingTitle = function (startUTCString, host) {
    var start = new Date(startUTCString);
    var result = "".concat(start.getFullYear(), "-").concat((start.getMonth() + 1)
        .toString()
        .padStart(2, "0"), "-").concat(start.getDate().toString().padStart(2, "0"));
    if (host) {
        result += " ".concat(host.displayName, "'s Meeting Room");
    }
    else {
        result += " Meeting Room";
    }
    return result;
};
exports.getRecordingTitle = getRecordingTitle;
