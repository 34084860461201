import { ReactNode, useCallback, useRef } from "react";
import useLayout from "../hooks/layout";
import "./ResizePanelVertical.css";

export type ResizePanelVerticalProps = {
  children: ReactNode[];
  minTopHeightInPixels: number;
  minBottomHeightInPixels: number;
};

const ResizePanelVertical = (props: ResizePanelVerticalProps) => {
  const resizePanel = useRef<HTMLDivElement>(null);
  const [layout, setLayout] = useLayout();

  const setTopHeight = useCallback(
    (newTopHeightInPercent: number) => {
      setLayout((prevState) => ({
        ...prevState,
        resize: {
          ...prevState.resize,
          topHeightInPercent: newTopHeightInPercent,
        },
      }));
    },
    [setLayout]
  );

  const resize = useCallback(
    (event: MouseEvent) => {
      let totalHeightInPixels = resizePanel.current?.clientHeight;
      if (totalHeightInPixels) {
        // Make sure we are in minimum heights
        let height = event.clientY;
        if (
          height > props.minTopHeightInPixels &&
          totalHeightInPixels - height > props.minBottomHeightInPixels
        ) {
          let newTopHeight = (height / totalHeightInPixels) * 100;
          setTopHeight(newTopHeight);
        }
      }
    },
    [props.minTopHeightInPixels, props.minBottomHeightInPixels, setTopHeight]
  );

  const endResize = useCallback(() => {
    document.body.style.cursor = "auto";
    window.removeEventListener("mousemove", resize);
  }, [resize]);

  const startResize = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();
      document.body.style.cursor = "row-resize";
      window.addEventListener("mousemove", resize);
      window.addEventListener("mouseup", endResize);
    },
    [endResize, resize]
  );

  const children = props.children.filter((child) => !!child);

  if (!children.length) {
    return null;
  } else if (children.length < 2) {
    return (
      <div ref={resizePanel} className="ResizePanelVertical">
        {children[0]}
      </div>
    );
  } else {
    return (
      <div ref={resizePanel} className="ResizePanelVertical">
        <div style={{ height: `${layout.resize.topHeightInPercent}%` }}>
          {children[0]}
        </div>
        <div className="ResizeGutter" onMouseDown={startResize} />
        <div
          style={{
            height: `${100 - layout.resize.topHeightInPercent}%`,
          }}
        >
          {children[1]}
        </div>
      </div>
    );
  }
};

export default ResizePanelVertical;
