import axios from "axios";
import {
  BEARER_PREFIX,
  CreateRoomResponse,
  CreateUserRequest,
  CreateUserResponse,
  GetWeatherResponse,
  IssueASRTokenRequest,
  IssueASRTokenResponse,
  IssueRTCTokenRequest,
  IssueRTCTokenResponse,
  JoinRoomRequest,
  JoinRoomResponse,
  LoginResponse,
  UpdateUserRequest,
  UpdateUserResponse,
  GetRecordingsListResponse,
  GetRecordingRequest,
  GetRecordingResponse,
  SendFeedbackRequest,
  SendFeedbackResponse,
  SendReferralRequest,
  SendReferralResponse,
  GetLatestRecordingRequest,
  GetLatestRecordingResponse,
  GetPopularLanguagesResponse,
  ReportIncorrectTranslationRequest,
  ReportIncorrectTranslationResponse,
  SendVerificationCodeRequest,
  SendVerificationCodeResponse,
  VerifyCodeRequest,
  VerifyCodeResponse,
  ClaimSessionIdResponse,
  EncodedToken,
  UpdateRecordingRequest,
  UpdateRecordingResponse,
  GetRecordingIsPublicRequest,
  GetRecordingIsPublicResponse,
  GetUserTranslationUsageRequest,
  GetUserTranslationUsageResponse,
  GetProUserListResponse,
  GetUserTimeUsageRequest,
  GetUserTimeUsageRequestBody,
  GetUserTimeUsageResponse,
  GenerateRecordingSummaryRequest,
  GenerateRecordingSummaryResponse,
  GetRecordingSummaryStatusRequest,
  GetRecordingSummaryStatusResponse,
  GetUserAccountResponse,
  PurchaseSubscriptionRequest,
  PurchaseSubscriptionResponse,
  GetSubscriptionResponse,
  InviteMemberRequest,
  RemoveMemberRequest,
  GetUserListResponse,
  GetUserAccountRequest,
} from "byrdhouse-types";
import { auth } from "./firebase";
import { LocalStorageKeys } from "./types";
import { User as FirebaseUser } from "firebase/auth";

let baseURL = process.env.REACT_APP_BYRDHOUSE_API_URL;

export const getFirebaseToken = async (
  refresh?: boolean
): Promise<EncodedToken | undefined> => {
  const firebaseUser: FirebaseUser | null =
    auth.currentUser ||
    (await new Promise((resolve) => {
      const unsubscribe = auth.onAuthStateChanged((currentUser) => {
        if (!localStorage.getItem(LocalStorageKeys.EXPECT_LOGIN)) {
          unsubscribe();
          console.debug("No firebase login expected...");
          return resolve(currentUser);
        }
        if (currentUser) {
          unsubscribe();
          console.debug("Firebase login expected, resolve current user...");
          return resolve(currentUser);
        }
      });
    }));
  const token = firebaseUser?.getIdToken(refresh);
  return token;
};

// Auth token middleware
axios.interceptors.request.use(async (config) => {
  const encodedToken =
    localStorage.getItem(LocalStorageKeys.BYRDHOUSE_TOKEN) ||
    (await getFirebaseToken());

  if (encodedToken && config.headers) {
    config.headers.Authorization = `${BEARER_PREFIX}${encodedToken}`;
  }
  return config;
});

// Auth
const login = async (): Promise<LoginResponse> => {
  let response = await axios.post(`${baseURL}/v1/auth/login`);
  return response.data as LoginResponse;
};

const claimSessionId = async (): Promise<ClaimSessionIdResponse> => {
  const response = await axios.post(`${baseURL}/v1/auth/claimSessionId`);
  return response.data as ClaimSessionIdResponse;
};

const sendVerificationCode = async (request: SendVerificationCodeRequest) => {
  let response = await axios.post(
    `${baseURL}/v1/auth/sendVerificationCode`,
    request
  );
  return response.data as SendVerificationCodeResponse;
};

const verifyCode = async (request: VerifyCodeRequest) => {
  let response = await axios.post(`${baseURL}/v1/auth/verifyCode`, request);
  return response.data as VerifyCodeResponse;
};

const issueRTCToken = async (
  request: IssueRTCTokenRequest
): Promise<IssueRTCTokenResponse> => {
  let response = await axios.post(`${baseURL}/v1/auth/issueRTCToken`, request, {
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
  });
  return response.data as IssueRTCTokenResponse;
};

const issueASRToken = async (
  request: IssueASRTokenRequest
): Promise<IssueASRTokenResponse> => {
  let response = await axios.post(`${baseURL}/v1/auth/issueASRToken`, request, {
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
  });
  return response.data as IssueASRTokenResponse;
};

const createRoom = async (): Promise<CreateRoomResponse> => {
  let response = await axios.post(`${baseURL}/v1/room/create`);
  return response.data;
};

const joinRoom = async (
  request: JoinRoomRequest
): Promise<JoinRoomResponse> => {
  let response = await axios.post(`${baseURL}/v1/room/join`, request);
  return response.data as JoinRoomResponse;
};

// Users
const createUser = async (
  request: CreateUserRequest
): Promise<{
  data: CreateUserResponse;
  status: number;
}> => {
  const response = await axios.post(`${baseURL}/v1/users/create`, request);
  return {
    data: response.data as CreateUserResponse,
    status: response.status,
  };
};

const updateUser = async (
  request: UpdateUserRequest
): Promise<UpdateUserResponse> => {
  let response = await axios.post(`${baseURL}/v1/users/update`, request);
  return response.data as UpdateUserResponse;
};

const getWeather = async (): Promise<GetWeatherResponse> => {
  let response = await axios.get(`${baseURL}/v1/utils/weather`);
  return response.data as GetWeatherResponse;
};

const getRecording = async (
  request: GetRecordingRequest
): Promise<GetRecordingResponse> => {
  let response = await axios.get(`${baseURL}/v1/recordings/${request.id}`);
  return response.data as GetRecordingResponse;
};

const getRecordingsList = async (): Promise<GetRecordingsListResponse> => {
  let response = await axios.get(`${baseURL}/v1/recordings/list`);
  let responseData = response.data as GetRecordingsListResponse;
  for (let recording of responseData.recordings) {
    recording.timestamp = new Date(recording.timestamp);
  }
  return responseData;
};

const getProUserList = async (): Promise<GetProUserListResponse> => {
  let response = await axios.get(`${baseURL}/v1/users/pro/list`);
  return response.data as GetProUserListResponse;
};

const getUserTranslationUsage = async (
  request: GetUserTranslationUsageRequest
): Promise<GetUserTranslationUsageResponse> => {
  let response = await axios.post(`${baseURL}/v1/users/usage`, request);
  return response.data as GetUserTranslationUsageResponse;
};

const getUserTimeUsage = async (
  request: GetUserTimeUsageRequest,
  body: GetUserTimeUsageRequestBody
): Promise<GetUserTimeUsageResponse> => {
  let response = await axios.post(
    `${baseURL}/v1/users/${request.id}/timeUsage`,
    body
  );
  return response.data as GetUserTimeUsageResponse;
};

const sendFeedback = async (
  request: SendFeedbackRequest
): Promise<SendFeedbackResponse> => {
  const response = await axios.post(
    `${baseURL}/v1/feedback/experience`,
    request
  );
  return response.data as SendFeedbackResponse;
};

const sendReferral = async (
  request: SendReferralRequest
): Promise<SendReferralResponse> => {
  const response = await axios.post(`${baseURL}/v1/feedback/referral`, request);
  return response.data as SendReferralResponse;
};

const getLatestRecording = async (
  request: GetLatestRecordingRequest
): Promise<GetLatestRecordingResponse> => {
  const response = await axios.get(
    `${baseURL}/v1/room/${request.id}/latestRecording`
  );
  return response.data as GetLatestRecordingResponse;
};

const getPopularLanguages = async (): Promise<GetPopularLanguagesResponse> => {
  const response = await axios.get(`${baseURL}/v1/utils/popularLanguages`);
  return response.data as GetPopularLanguagesResponse;
};

const reportIncorrectTranslation = async (
  request: ReportIncorrectTranslationRequest
): Promise<ReportIncorrectTranslationResponse> => {
  const response = await axios.post(
    `${baseURL}/v1/utils/reportIncorrectTranslation`,
    request
  );
  return response.data as ReportIncorrectTranslationResponse;
};

const updateRecording = async (
  request: UpdateRecordingRequest
): Promise<UpdateRecordingResponse> => {
  const response = await axios.post(`${baseURL}/v1/recordings/update`, request);
  return response.data as UpdateRecordingResponse;
};

const getRecordingIsPublic = async (
  request: GetRecordingIsPublicRequest
): Promise<GetRecordingIsPublicResponse> => {
  const response = await axios.get(
    `${baseURL}/v1/recordings/${request.id}/isPublic`
  );
  return response.data as GetRecordingIsPublicResponse;
};

const generateRecordingSummary = async (
  request: GenerateRecordingSummaryRequest
): Promise<GenerateRecordingSummaryResponse> => {
  const response = await axios.post(
    `${baseURL}/v1/recordings/generateSummary`,
    request
  );
  return response.data as GenerateRecordingSummaryResponse;
};

const getRecordingSummaryStatus = async (
  request: GetRecordingSummaryStatusRequest
): Promise<GetRecordingSummaryStatusResponse> => {
  const response = await axios.get(
    `${baseURL}/v1/recordings/${request.id}/summaryStatus`
  );
  return response.data as GetRecordingSummaryStatusResponse;
};

const getAccount = async (request: GetUserAccountRequest) => {
  const response = await axios.get(`${baseURL}/v1/users/${request.id}/account`);
  return response.data as GetUserAccountResponse;
};

const inviteMember = async (request: InviteMemberRequest) => {
  await axios.post(`${baseURL}/v1/users/inviteMember`, request);
};

const removeMember = async (request: RemoveMemberRequest) => {
  await axios.post(`${baseURL}/v1/users/removeMember`, request);
};

const purchaseSubscription = async (request: PurchaseSubscriptionRequest) => {
  const response = await axios.post(
    `${baseURL}/v1/users/purchaseSubscription`,
    request
  );
  return response.data as PurchaseSubscriptionResponse;
};

const updateSubscription = async (request: PurchaseSubscriptionRequest) => {
  const response = await axios.post(
    `${baseURL}/v1/subscription/update`,
    request
  );
  return response.data as PurchaseSubscriptionResponse;
};

const purchaseMinutes = async () => {
  const response = await axios.post(`${baseURL}/v1/users/purchaseMinutes`);
  return response.data;
};

const getSubscription = async () => {
  const response = await axios.get(`${baseURL}/v1/subscription`);
  return response.data as GetSubscriptionResponse;
};

const getBilling = async () => {
  const response = await axios.get(`${baseURL}/v1/subscription/billing`);
  return response.data as { redirectStripeUrl: string };
};

// Admin routes

const getUsersList = async () => {
  const response = await axios.get(`${baseURL}/v1/users/list`);
  return response.data as GetUserListResponse;
};

const api = {
  login,
  claimSessionId,
  sendVerificationCode,
  verifyCode,
  issueRTCToken,
  issueASRToken,
  createRoom,
  joinRoom,
  createUser,
  updateUser,
  getWeather,
  getRecording,
  getRecordingsList,
  getProUserList,
  getUserTranslationUsage,
  getUserTimeUsage,
  sendFeedback,
  sendReferral,
  getLatestRecording,
  getPopularLanguages,
  reportIncorrectTranslation,
  updateRecording,
  getRecordingIsPublic,
  generateRecordingSummary,
  getRecordingSummaryStatus,
  getAccount,
  purchaseSubscription,
  updateSubscription,
  purchaseMinutes,
  inviteMember,
  removeMember,
  getSubscription,
  getBilling,
  getUsersList,
};

export default api;
