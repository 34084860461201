import "./Home.css";
import useUser from "../../hooks/user";
import HomeSideNav from "./HomeSideNav";
import { ReactNode, useEffect } from "react";
import Button from "../../components/Button";
import { useToggleJoinNestModal } from "../../hooks/layout";
import { useLeaveCall } from "../../hooks/call";
import { IoAdd } from "react-icons/io5";
import { useWindowSize } from "react-use";
import Welcome from "../Welcome";
import amplitude from "../../amplitude";
import { AmplitudeEvent } from "byrdhouse-types";

type HomeProps = {
  selected: ReactNode;
};

function Home(props: HomeProps) {
  const [user] = useUser();

  const toggleCreateNestModal = useToggleJoinNestModal(true);
  const toggleJoinNestModal = useToggleJoinNestModal(false);

  const leaveCall = useLeaveCall();
  useEffect(() => {
    // Hack for meeting component back button
    // Dismount happens too often to end call (precall and meeting should be separate)
    leaveCall({ intentional: false });
  }, [leaveCall]);

  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 1000;

  useEffect(() => {
    amplitude?.logEvent(AmplitudeEvent.OPEN_HOME);
  }, []);

  if (isMobile) {
    return <Welcome isMobile={true} />;
  } else {
    return (
      <div className="Home">
        <h1>{`Welcome to Byrdhouse, ${user?.displayName}!`}</h1>
        <div className="NestButtons">
          <Button
            text={
              <>
                <IoAdd />
                {"Build a nest"}
              </>
            }
            onClick={toggleCreateNestModal}
            border="pink"
          />
          <Button text="Join a nest" onClick={toggleJoinNestModal} />
        </div>
        <div className="NavAndContent">
          <HomeSideNav />
          {props.selected}
        </div>
      </div>
    );
  }
}

export default Home;
