import { IoMail, IoTrash } from "react-icons/io5";
import Button from "../../components/Button";
import "./Account.css";
import { useEffect } from "react";
import Loader from "../../components/Loader";
import {
  useToggleConfirmRemoveMemberModal,
  useToggleInviteMemberModal,
} from "../../hooks/layout";
import useAccount, { useGetAccount } from "../../hooks/account";
import useUser from "../../hooks/user";

type MemberProps = {
  email: string;
  owner?: boolean;
  pending: boolean;
};

function Member(props: MemberProps) {
  const [user] = useUser();

  const toggleConfirmRemoveMemberModal = useToggleConfirmRemoveMemberModal(
    props.email
  );

  return (
    <div className="Member">
      <div>
        <div>{props.email}</div>
        {props.pending && (
          <div className="Pending">
            <IoMail />
            Pending
          </div>
        )}
      </div>
      <div>
        {props.owner && <div className="Owner">Owner</div>}
        {!user?.providerId && !props.owner && (
          <Button
            text={
              <>
                <IoTrash />
                <span>Remove</span>
              </>
            }
            onClick={toggleConfirmRemoveMemberModal}
          />
        )}
      </div>
    </div>
  );
}

function Account() {
  const [account] = useAccount();
  const getAccount = useGetAccount();

  useEffect(() => {
    getAccount();
  }, [getAccount]);

  const [user] = useUser();
  const toggleInviteMemberModal = useToggleInviteMemberModal();

  return (
    <div className="Account">
      {!account ? (
        <Loader size={30} />
      ) : (
        <>
          <h2>Byrdhouse {user?.plan}</h2>
          <div className="Members">
            <div className="MembersTitle">
              <h3>{`Members (${account?.members.length || 1}/${
                account?.featureFlags.maxMembers || 2
              })`}</h3>
              {!user?.providerId && (
                <Button
                  text={
                    <>
                      <IoMail />
                      <span>Invite Member</span>
                    </>
                  }
                  border="pink"
                  onClick={toggleInviteMemberModal}
                />
              )}
            </div>
            {account?.members
              // Show owner at top
              .sort((m1, m2) =>
                m1.userId === account.owner
                  ? -1
                  : m2.userId === account.owner
                  ? 1
                  : 0
              )
              .map((member) => (
                <Member
                  key={member.email}
                  email={member.email}
                  owner={account.owner === member.userId}
                  pending={member.pending}
                />
              ))}
          </div>
        </>
      )}
    </div>
  );
}

export default Account;
