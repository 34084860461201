import { useCallback } from "react";
import useUser from "../../hooks/user";
import "./HomeSideNav.css";
import { Link, useLocation } from "react-router-dom";
import amplitude from "../../amplitude";
import { AmplitudeEvent } from "byrdhouse-types";

function HomeSideNav() {
  const location = useLocation();

  const [user] = useUser();

  const clickOnAccount = useCallback(() => {
    amplitude?.logEvent(AmplitudeEvent.CLICK_ON_ACCOUNT);
  }, []);

  const clickOnUsage = useCallback(() => {
    amplitude?.logEvent(AmplitudeEvent.CLICK_ON_USAGE);
  }, []);

  return (
    <div className="HomeSideNav">
      <Link
        className={location.pathname === "/" ? "Selected" : undefined}
        to="/"
      >
        Recordings
      </Link>
      {!user?.providerId && (
        <Link
          className={
            location.pathname === "/subscription" ? "Selected" : undefined
          }
          to="/subscription"
        >
          Subscription
        </Link>
      )}
      <Link
        className={location.pathname === "/account" ? "Selected" : undefined}
        to="/account"
        onClick={clickOnAccount}
      >
        Account
      </Link>
      {!user?.providerId &&
        // Hide for specific users
        user?.id !== "dd29b2d4-c899-426d-a382-4b7f19af3eb6" &&
        user?.providerId !== "dd29b2d4-c899-426d-a382-4b7f19af3eb6" && (
          <Link
            className={location.pathname === "/usage" ? "Selected" : undefined}
            to="/usage"
            onClick={clickOnUsage}
          >
            Usage
          </Link>
        )}
      <Link
        className={location.pathname === "/support" ? "Selected" : undefined}
        to="/support"
      >
        Support
      </Link>
    </div>
  );
}

export default HomeSideNav;
