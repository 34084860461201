import useCall, {
  useToggleBlurFilter,
  useToggleNoneFilter,
} from "../hooks/call";
import "./Filters.css";

const Filters = () => {
  const [call] = useCall();

  const toggleNoneFilter = useToggleNoneFilter();
  const toggleBlurFilter = useToggleBlurFilter();

  return (
    <div className="Filters">
      <div className="Presets">
        <div
          onClick={toggleNoneFilter}
          className={!call.virtualBackground ? "Enabled" : ""}
        >
          None
        </div>
        <div
          onClick={() => {
            if (call.localCameraTrack) {
              toggleBlurFilter(call.localCameraTrack);
            }
          }}
          className={call.virtualBackground === "blur" ? "Enabled" : ""}
        >
          Blur
        </div>
        {/* TODO: More backgrounds */}
      </div>
    </div>
  );
};

export default Filters;
