import { CaptionID, RoomID } from "byrdhouse-types";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../api";
import useLayout, { useCloseModal } from "../../hooks/layout";
import useTranscript from "../../hooks/transcript";
import Button from "../Button";
import "./ReportCaptionModal.css";
import useCall from "../../hooks/call";
import { useLocalParticipant } from "../../hooks/user";
import { createRef } from "react";

const ReportCaptionModal = () => {
  const [layout] = useLayout();
  const [transcript] = useTranscript();

  const captionId = layout.modalParams.captionId as CaptionID | undefined;
  const caption = captionId ? transcript.history[captionId] : undefined;

  const originalCaption = captionId
    ? transcript.originalCaptions[captionId]
    : undefined;

  const [correction, setCorrection] = useState<string>(
    caption?.targetLanguageText || ""
  );
  const correct = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setCorrection(event.currentTarget.value);
  }, []);

  const [call] = useCall();
  const localParticipant = useLocalParticipant();

  const closeModal = useCloseModal();

  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false);
  const toggleDisclaimer = useCallback(() => {
    setShowDisclaimer((prevState) => !prevState);
  }, []);

  const reportIncorrectTranslation = useCallback(async () => {
    if (
      caption &&
      call.roomID &&
      originalCaption &&
      localParticipant?.sessionId
    ) {
      await api.reportIncorrectTranslation({
        roomId: call.roomID as RoomID,
        originalCaption: originalCaption,
        report: {
          sourceLanguage: caption.sourceLanguage,
          sourceLanguageText: caption.sourceLanguageText,
          targetLanguage: caption.targetLanguage,
          targetLanguageText: caption.targetLanguageText,
          correctionLanguage: caption.targetLanguage,
          correctionLanguageText: correction,
        },
      });
      toast(
        <div className="ByrdhouseToast FeedbackToast">
          <div>{"Translation feedback submitted!"}</div>
          <div>{"Thank you!"}</div>
        </div>
      );
      closeModal();
    }
  }, [
    caption,
    correction,
    call.roomID,
    originalCaption,
    localParticipant?.sessionId,
    closeModal,
  ]);

  const editBox = createRef<HTMLTextAreaElement>();
  useEffect(() => {
    if (editBox.current) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      editBox.current.style.height = "0px";
      const scrollHeight = editBox.current.scrollHeight;

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will produce an incorrect value
      editBox.current.style.height = scrollHeight + "px";
    }
  }, [editBox, correction]);

  if (!showDisclaimer) {
    return (
      <div className="ReportCaptionModal">
        <div>
          Please help us improve the translation quality by identifying or
          correcting the wrong translation.
        </div>
        <textarea
          ref={editBox}
          value={correction}
          onChange={correct}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              reportIncorrectTranslation();
            }
          }}
        />
        <Button
          background="gradient"
          text="Confirm"
          onClick={reportIncorrectTranslation}
        />
        <div className="DisclaimerText">
          By confirming to submit the translation inaccuracy, you acknowledge
          that you have read and agree to the data collection disclaimer{" "}
          <span onClick={toggleDisclaimer} className="ToggleDisclaimer">
            here
          </span>
          .
        </div>
      </div>
    );
  } else {
    return (
      <div className="ReportCaptionModal">
        <div className="Disclaimer">
          <div onClick={toggleDisclaimer} className="Back">
            ← Back
          </div>
          <div className="DisclaimerText">
            By submitting a report of translation inaccuracy, you acknowledge
            and agree that the data you provide may be used to improve the
            quality and accuracy of our AI algorithms. We will take reasonable
            measures to remove your personal information from the data
            collection process to protect your privacy.
          </div>
          <div className="DisclaimerText">
            Please note that we will only collect and use data in accordance
            with applicable laws and regulations, and we will take reasonable
            measures to protect your data from unauthorized access or use.{" "}
          </div>
          <div className="DisclaimerText">
            If you have any questions or concerns about our data collection and
            use practices, please contact us at snow.huo@byrdhouseapp.com.
            Additionally, if you no longer wish to participate in the
            translation inaccuracy reporting process, you may opt out at any
            time by not submitting any inaccurate translation.
          </div>
          <div className="DisclaimerText">
            By submitting a report of translation inaccuracy, you acknowledge
            that you have read and understood this legal disclaimer, and agree
            to the collection and use of your data as described herein.
          </div>
        </div>
      </div>
    );
  }
};

export default ReportCaptionModal;
