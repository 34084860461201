import { AmplitudeEvent, IUser, RecordingID } from "byrdhouse-types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IoCopyOutline, IoLink } from "react-icons/io5";
import "./ShareRecordingModal.css";
import amplitude from "../../amplitude";
import useUser from "../../hooks/user";
import api from "../../api";
import Button from "../Button";
import React from "react";
import { AiFillLock, AiFillUnlock } from "react-icons/ai";
import { toast } from "react-toastify";
import useLayout from "../../hooks/layout";

export type ShareRecordingModalProps = {
  recordingId: RecordingID;
  members: IUser[];
};

const ShareRecordingModal = (props: ShareRecordingModalProps) => {
  const link = `${window.location.origin}/recordings/${props.recordingId}`;
  const [linkCoped, setLinkCopied] = useState<boolean>(false);
  const copyLink = useCallback(() => {
    amplitude?.logEvent(AmplitudeEvent.SHARE_SPECIFIC_RECORDING_COPY_LINK, {
      link,
    });
    navigator.clipboard.writeText(link);
    setLinkCopied(true);
  }, [link]);

  const [user] = useUser();
  const canUpdate = useMemo(() => {
    if (!user) {
      return false;
    }
    return props.members.map((u) => u.id).includes(user.id);
  }, [props.members, user]);

  const getIsPublic = useCallback(async () => {
    const response = await api.getRecordingIsPublic({ id: props.recordingId });
    setIsPublic(response.public);
  }, [props.recordingId]);

  useEffect(() => {
    getIsPublic();
  }, [getIsPublic]);

  const [showConfirmChangeVisibility, setShowConfirmChangeVisibility] =
    useState(false);
  const toggleConfirmChangeVisibility = useCallback(() => {
    setShowConfirmChangeVisibility((prevState) => !prevState);
  }, []);

  const [isPublic, setIsPublic] = useState(false);

  const [, setLayout] = useLayout();
  const toggleVisibility = useCallback(async () => {
    await api.updateRecording({
      recording: { id: props.recordingId, public: !isPublic },
    });
    toast(
      <div className="ByrdhouseToast RecordingVisibilityToast">
        {isPublic ? <AiFillLock /> : <AiFillUnlock />}
        <div>Your recording is now {isPublic ? "private" : "public"}</div>
      </div>
    );
    setIsPublic((prevState) => !prevState);
    setShowConfirmChangeVisibility(false);

    // Small hack to tell recordings list to update (if applicable)
    setLayout((prevState) => ({
      ...prevState,
      recordingsUpdate: !prevState.recordingsUpdate,
    }));
  }, [props.recordingId, isPublic, setLayout]);

  const makePrivate = (
    <React.Fragment>
      <AiFillLock /> Make Private
    </React.Fragment>
  );

  const makePublic = (
    <React.Fragment>
      <AiFillUnlock /> Make Public
    </React.Fragment>
  );

  if (!showConfirmChangeVisibility) {
    return (
      <div className="ShareRecordingModal">
        {isPublic
          ? "Anyone with the link can view this recording (public)."
          : "Only participants can view this recording (private)."}
        <div className="Link ShareLink">
          <IoLink />
          {link}
        </div>
        {canUpdate && (
          <Button
            text={isPublic ? makePrivate : makePublic}
            background="gradient"
            onClick={toggleConfirmChangeVisibility}
          />
        )}
        <button className="CopyLink ShareLink" onClick={copyLink}>
          <IoCopyOutline />
          {linkCoped ? "Link copied!" : "Copy link"}
        </button>
      </div>
    );
  } else {
    return (
      <div className="ShareRecordingModal">
        <div className="ConfirmVisibilityChange">
          {isPublic ? (
            <React.Fragment>
              <div>
                Are you sure you want to make this recording private? Only
                participants in this call will be able to view and download the
                recording.
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div>
                Are you sure you want to make this recording public? Anyone with
                the link will be able to view and download the recording.
              </div>
            </React.Fragment>
          )}
          <div className="Buttons">
            <Button text="Back" onClick={toggleConfirmChangeVisibility} />
            <Button
              background="gradient"
              text={isPublic ? "Make Private" : "Make Public"}
              onClick={toggleVisibility}
            />
          </div>
        </div>
      </div>
    );
  }
};

export default ShareRecordingModal;
