import "./Button.css";

export type ButtonProps = {
  text: React.ReactNode;
  size?: "small" | "medium" | "large";
  background?: "gradient";
  border?: "pink";
  onClick: () => void;
  disabled?: boolean;
};

const Button = (props: ButtonProps) => {
  let sizeClassName = "";
  switch (props.size) {
    case "small":
      sizeClassName = "SmallButton";
      break;
    case "medium":
      sizeClassName = "MediumButton";
      break;
    case "large":
      sizeClassName = "LargeButton";
      break;
  }
  let backgroundClassName = "";
  switch (props.background) {
    case "gradient":
      backgroundClassName = "Gradient";
      break;
  }
  let borderClassName = "";
  switch (props.border) {
    case "pink":
      backgroundClassName = "PinkBorder";
      break;
  }
  return (
    <button
      disabled={props.disabled}
      onClick={!props.disabled ? props.onClick : undefined}
      className={`CustomButton ${sizeClassName} ${backgroundClassName} ${borderClassName}`}
    >
      {props.text}
    </button>
  );
};

export default Button;
