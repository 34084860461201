import { useStartRecording, useStopRecording } from "../../hooks/call";
import Button from "../Button";
import "./ConfirmRecordingActionModal.css";

export type ConfirmRecordingActionModalProps = {
  action: "start" | "stop";
};

const ConfirmRecordingActionModal = (
  props: ConfirmRecordingActionModalProps
) => {
  const startRecording = useStartRecording();
  const stopRecording = useStopRecording();
  return (
    <div className="ConfirmRecordingActionModal">
      <div>Please confirm to {props.action} the recording.</div>
      <Button
        text="Confirm"
        onClick={props.action === "start" ? startRecording : stopRecording}
        background="gradient"
      />
    </div>
  );
};

export default ConfirmRecordingActionModal;
