import LoginModal from "../components/modals/LoginModal";
import "./Signup.css";
import CustomerTestimonies from "../components/CustomerTestimonies";
import { Link } from "react-router-dom";
import amplitude from "../amplitude";
import { AmplitudeEvent } from "byrdhouse-types";
import { Helmet } from "react-helmet";

type SignupProps = {
  referrer?: "meeting" | "recording";
};

function Signup(props: SignupProps) {
  let title = "Sign Up";
  if (props.referrer === "meeting") {
    title = "Continue to Join the Meeting";
  }
  if (props.referrer === "recording") {
    title = "Continue to View the Recording";
  }

  return (
    <div className="Signup">
      {process.env.NODE_ENV === "production" && (
        <Helmet>
          <link rel="canonical" href="https://byrdhouseapp.com/signup" />
        </Helmet>
      )}
      <Link
        onClick={() => amplitude?.logEvent(AmplitudeEvent.CLICK_ON_SIGNUP_BACK)}
        to={"/"}
        className="SignupBack"
      >
        {"← Back"}
      </Link>
      <div>
        <h1 style={{ fontSize: !props.referrer ? "52px" : "36px" }}>{title}</h1>
        <LoginModal signup={true} />
      </div>
      <div className="SignupTestimoniesWrapper">
        <CustomerTestimonies />
        <CustomerTestimonies />
      </div>
    </div>
  );
}

export default Signup;
