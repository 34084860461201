import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useUser from "../hooks/user";
import { speechLanguageLocaleToFlag } from "../utils";
import amplitude from "../amplitude";
import {
  AmplitudeEvent,
  getAutoDetectSupportedSpeechLanguages,
  getSupportedSpeechLanguageLocales,
  SpeechLanguageLocale,
} from "byrdhouse-types";
import api from "../api";
import "./LanguageSelect.css";
import Dropdown, { DropdownHandle } from "./Dropdown";
import { IoSearch } from "react-icons/io5";
import { Tooltip } from "react-tooltip";
import React from "react";
import useLayout from "../hooks/layout";
import { LocalStorageKeys } from "../types";

const LanguageSelect = () => {
  const [user, setUser] = useUser();
  const dropdownRef = useRef<DropdownHandle>(null);
  const [lastUsedLanguage, setLastUsedLanguage] =
    useState<SpeechLanguageLocale>();
  const [showTooltip, setShowTooltip] = useState(false);
  const [layout, setLayout] = useLayout();

  const onLanguageChanged = useCallback(
    async (speechLanguageLocale: SpeechLanguageLocale) => {
      amplitude?.logEvent(AmplitudeEvent.CHANGE_SPEECH_LANGUAGE, {
        speechLanguageLocale,
      });
      setShowTooltip(false);
      // Update user after request returns
      const response = await api.updateUser({ user: { speechLanguageLocale } });
      setUser((prevState) =>
        prevState
          ? { ...prevState, ...response.user, autoDetect: false }
          : prevState
      );
      localStorage.removeItem(LocalStorageKeys.AUTO_DETECT);
      // Hide dropdown
      dropdownRef.current?.toggleContent();
    },
    [setUser]
  );

  const toggleAutoDetectOn = useCallback(() => {
    amplitude?.logEvent(AmplitudeEvent.CLICK_ON_AUTO_DETECT);
    setShowTooltip(false);
    setUser((prevState) =>
      prevState
        ? {
            ...prevState,
            autoDetect: true,
          }
        : prevState
    );
    localStorage.setItem(LocalStorageKeys.AUTO_DETECT, "true");
    // Hide dropdown
    dropdownRef.current?.toggleContent();
  }, [setUser]);

  const [query, setQuery] = useState<string>("");
  const search = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.currentTarget.value);
  }, []);

  const mapLanguages = useCallback(
    (languages: SpeechLanguageLocale[]) => {
      if (query.length) {
        languages = languages.filter((language) =>
          language.display.toLowerCase().includes(query.toLowerCase())
        );
      }
      return languages.map((language: SpeechLanguageLocale) => (
        <div
          className="LanguageSelectOption"
          key={language.code}
          onClick={() => onLanguageChanged(language)}
        >
          <input
            type="radio"
            checked={
              !user?.autoDetect &&
              user?.speechLanguageLocale.code === language.code
            }
          />
          {speechLanguageLocaleToFlag(language)} {language.display}
        </div>
      ));
    },
    [query, user?.autoDetect, user?.speechLanguageLocale, onLanguageChanged]
  );

  useEffect(() => {
    setLastUsedLanguage(user?.speechLanguageLocale);
  }, [user?.speechLanguageLocale]);

  const getPopularLanguages = useCallback(async () => {
    const { languages } = await api.getPopularLanguages();
    setLayout((prevState) => ({
      ...prevState,
      popularLanguages: languages,
    }));
  }, [setLayout]);
  useEffect(() => {
    getPopularLanguages();
  }, [getPopularLanguages]);

  const topLanguages = useMemo(() => {
    const topLanguageSortOrder = ["en-US", "zh-CN", "es-MX", "ko-KR", "ja-JP"];
    return (
      !!layout.popularLanguages.length &&
      mapLanguages(
        layout.popularLanguages
          .filter(
            (lang) =>
              lang.speechLanguageLocale.code !== user?.speechLanguageLocale.code
          )
          .sort((a, b) => {
            const aIndex = topLanguageSortOrder.indexOf(
              a.speechLanguageLocale.code
            );
            const bIndex = topLanguageSortOrder.indexOf(
              b.speechLanguageLocale.code
            );

            if (aIndex !== -1 && bIndex !== -1) {
              return aIndex - bIndex;
            } else if (aIndex !== -1) {
              return -1;
            } else if (bIndex !== -1) {
              return 1;
            } else {
              return 0;
            }
          })
          .map((lang) => lang.speechLanguageLocale)
      )
    );
  }, [layout.popularLanguages, mapLanguages, user?.speechLanguageLocale]);

  const otherLanguages = useMemo(() => {
    return mapLanguages(
      getSupportedSpeechLanguageLocales().filter(
        (lang) =>
          lang.code !== user?.speechLanguageLocale.code &&
          !layout.popularLanguages
            .map((lang) => lang.speechLanguageLocale.code)
            .includes(lang.code)
      )
    );
  }, [mapLanguages, user?.speechLanguageLocale, layout.popularLanguages]);

  const disableAutoDetect = useMemo(() => {
    const autoDetectSupported = getAutoDetectSupportedSpeechLanguages();
    return !(
      user && autoDetectSupported.includes(user.speechLanguageLocale.code)
    );
  }, [user]);

  return (
    <React.Fragment>
      <Tooltip id="LanguageSelectTooltip" isOpen={showTooltip} />
      {/* eslint-disable-next-line */}
      <a
        data-tooltip-id="LanguageSelectTooltip"
        data-tooltip-content="Please select the language that you speak"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <Dropdown
          ref={dropdownRef}
          title={
            user?.autoDetect
              ? "✨ Auto-detect"
              : user?.speechLanguageLocale.display || navigator.language
          }
          toggleStyle={{
            background: "linear-gradient(180deg, #CB40B5 0%, #EF6F62 100%)",
          }}
        >
          <div className="LanguageSelectContent">
            <div className="LanguageSearch">
              <IoSearch />
              <input
                type="text"
                placeholder="Language Search"
                value={query}
                onChange={search}
              />
            </div>
            <div className="LanguageSelectOptions">
              <div className="LanguageSelectLabel">
                Auto-detect Top Languages
              </div>
              <div
                className={`LanguageSelectOption ${
                  user?.autoDetect ? "DetectingLanguage" : ""
                }`}
                onClick={!disableAutoDetect ? toggleAutoDetectOn : undefined}
              >
                <input
                  type="radio"
                  checked={!!user?.autoDetect}
                  disabled={disableAutoDetect}
                />
                {"✨ Auto-detect"}
              </div>

              <div className="LanguageSelectLabel">Last Selected Language</div>
              {/* From the user's speech locale */}
              {lastUsedLanguage && mapLanguages([lastUsedLanguage])}
              <div className="LanguageSelectLabel">Top Languages</div>
              {/* From the database */}
              {topLanguages}
              <div className="LanguageSelectLabel">Other Languages</div>
              {/* All languages */}
              {otherLanguages}
            </div>
          </div>
        </Dropdown>
      </a>
    </React.Fragment>
  );
};

export default LanguageSelect;
