import {
  RoomID,
  SendMessageArgs,
  speechLanguageLocaleToLanguageLocale,
} from "byrdhouse-types";
import { SyntheticEvent, useCallback, useState } from "react";
import { IoCopyOutline } from "react-icons/io5";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { useParams } from "react-router-dom";
import useCall from "../hooks/call";
import useChat from "../hooks/chat";
import useSocket from "../hooks/socket";
import useTranscript from "../hooks/transcript";
import { useLocalParticipant } from "../hooks/user";
import "./Chat.css";

const Chat = () => {
  const params = useParams();
  const socket = useSocket();
  const [chat] = useChat();
  const [call] = useCall();
  const localParticipant = useLocalParticipant();

  const [transcript] = useTranscript();

  const [draft, setDraft] = useState<string>("");

  // On send message
  const sendMessage = useCallback(
    (event: SyntheticEvent<Element, Event>) => {
      event.stopPropagation();
      if (localParticipant && params.room && draft.length) {
        const targetLanguages = Object.values(call.remotePeers).map(
          (remotePeer) =>
            speechLanguageLocaleToLanguageLocale(
              remotePeer.speechLanguageLocale
            )
        );

        let args: SendMessageArgs = {
          text: draft,
          room: params.room as RoomID,
          sourceLanguage: speechLanguageLocaleToLanguageLocale(
            localParticipant.speechLanguageLocale
          ),
          targetLanguages,
        };
        socket?.emit("send_message", args);
        setDraft("");
      }
    },
    [call.remotePeers, draft, setDraft, socket, params.room, localParticipant]
  );

  const userLanguage = localParticipant
    ? speechLanguageLocaleToLanguageLocale(
        localParticipant.speechLanguageLocale
      )
    : undefined;

  const [copied, setCopied] = useState<{
    [index: number]: boolean | undefined;
  }>({});

  return (
    <div className="Chat">
      {/* Messages */}
      <div className="Messages">
        {/* Hack div to un-reverse (see Chat.css) */}
        <div>
          {chat.messages.map((message, index) => {
            // Show original if no translation found
            const translation =
              userLanguage && message.translations[userLanguage]
                ? message.translations[userLanguage]
                : message.original;

            return (
              <div
                className={`${
                  message.from.userId === localParticipant?.userId
                    ? "OwnMessage Message"
                    : "Message"
                }`}
                key={index}
              >
                <div
                  className="From"
                  style={{ fontSize: transcript.fontSize - 4 }}
                >
                  {message.from.displayName}
                </div>
                <div className="TextWrapper">
                  <div
                    className="Text"
                    style={{ fontSize: transcript.fontSize }}
                  >
                    {translation}
                  </div>
                  {message.from.userId !== localParticipant?.userId &&
                    (copied[index] ? (
                      <AiOutlineCheckCircle className="CopiedChat" />
                    ) : (
                      <IoCopyOutline
                        onClick={() => {
                          navigator.clipboard.writeText(translation);
                          setCopied((prevState) => ({
                            ...prevState,
                            [index]: true,
                          }));
                          setTimeout(() => {
                            setCopied((prevState) => ({
                              ...prevState,
                              [index]: false,
                            }));
                          }, 2000);
                        }}
                      />
                    ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Input */}
      <div className="ChatInput">
        <div className="Sender">
          <input
            type="text"
            placeholder="Send a message to everyone"
            onChange={(event) => {
              setDraft(event.currentTarget.value);
            }}
            onMouseDown={(event) => event.stopPropagation()}
            value={draft}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                sendMessage(event);
              }
            }}
          />
          <button onClick={sendMessage}>Send</button>
        </div>
      </div>
    </div>
  );
};

export default Chat;
