import TranslationUsage from "../../components/TranslationUsage";
import useUser from "../../hooks/user";
import "./Usage.css";

function Usage() {
  const [user] = useUser();

  if (!user) {
    return null;
  }
  return (
    <div className="Usage">
      <TranslationUsage providerId={user.id} />
      <div>
        {`Prepaid Translation Minutes Remaining: ${(
          user.prepaidTranslationSecondsRemaining / 60
        ).toFixed(0)}`}
      </div>
      <div>
        {`Monthly Translation Minutes Remaining: ${(
          user.monthlyTranslationSecondsRemaining / 60
        ).toFixed(0)}`}
      </div>
    </div>
  );
}

export default Usage;
