import { useMount } from "react-use";
import "./Support.css";
import amplitude from "../../amplitude";
import { AmplitudeEvent } from "byrdhouse-types";

type SupportProps = {
  managePlan?: boolean;
};

const Support = (props: SupportProps) => {
  useMount(() => {
    amplitude?.logEvent(AmplitudeEvent.OPEN_SUPPORT);
  });

  const onMailToClicked = () => {
    amplitude?.logEvent(AmplitudeEvent.CLICK_ON_MAILTO_HYPERLINK);
  };

  if (props.managePlan) {
    return (
      <div className="Support">
        <p>
          Please contact{" "}
          <a
            href="mailto:snow.huo@byrdhouseapp.com?subject=Byrdhouse%20Support"
            onClick={onMailToClicked}
          >
            snow.huo@byrdhouseapp.com
          </a>{" "}
          if you would like to manage your subscription. We will respond within
          24 hours!
        </p>
        <p>
          You can also{" "}
          <a
            href="https://calendly.com/snow-huo-byrdhouse/chat-with-snow"
            rel="noreferrer"
            target="_blank"
          >
            schedule a chat
          </a>{" "}
          or call us at <a href="tel:+1-323-364-0815">+1-323-364-0815</a>
        </p>
      </div>
    );
  } else {
    return (
      <div className="Support">
        <p>
          Please contact{" "}
          <a
            href="mailto:jacob.greenway@byrdhouseapp.com?subject=Byrdhouse%20Support"
            onClick={onMailToClicked}
          >
            jacob.greenway@byrdhouseapp.com
          </a>{" "}
          for technical support and{" "}
          <a
            href="mailto:snow.huo@byrdhouseapp.com?subject=Byrdhouse%20Support"
            onClick={onMailToClicked}
          >
            snow.huo@byrdhouseapp.com
          </a>{" "}
          for billing or other questions.
        </p>
        <p>
          Or call us at <a href="tel:+1-323-364-0815">+1-323-364-0815</a>
        </p>
      </div>
    );
  }
};

export default Support;
