import AgoraRTC from "agora-rtc-sdk-ng";
import VirtualBackgroundExtension from "agora-extension-virtual-background";

const retryConfig = {
  timeout: 5000,
  timeoutFactor: 50,
  maxRetryCount: Number.MAX_VALUE,
  maxRetryTimeout: 3000,
};

const agora = AgoraRTC.createClient({
  mode: "rtc",
  codec: "vp8",
  httpRetryConfig: retryConfig,
  websocketRetryConfig: retryConfig,
});
export const screenShare = AgoraRTC.createClient({
  mode: "rtc",
  codec: "vp8",
  httpRetryConfig: retryConfig,
  websocketRetryConfig: retryConfig,
});

export const virtualBackground = new VirtualBackgroundExtension();
AgoraRTC.registerExtensions([virtualBackground]);

export default agora;
