import { useCallback } from "react";
import { useCloseModal } from "../../hooks/layout";
import Button from "../Button";
import "./ConfirmRemoveMemberModal.css";
import api from "../../api";
import { useGetAccount } from "../../hooks/account";
import { toast } from "react-toastify";

type ConfirmRemoveMemberModalProps = {
  email: string;
};

function ConfirmRemoveMemberModal(props: ConfirmRemoveMemberModalProps) {
  const closeModal = useCloseModal();
  const getAccount = useGetAccount();

  const removeMember = useCallback(async () => {
    try {
      await api.removeMember({ email: props.email });
      await getAccount();
      closeModal();
      toast(
        <div className="ByrdhouseToast SuccessToast">
          <div>{`Successfully removed ${props.email} from your account.`}</div>
        </div>
      );
    } catch {
      toast(
        <div className="ByrdhouseToast ErrorToast">
          <div>{`Error removing ${props.email}, please try again.`}</div>
        </div>
      );
    }
  }, [props.email, getAccount, closeModal]);

  return (
    <div className="ConfirmRemoveMemberModal">
      <div>{`Are you sure you want to remove ${props.email} from your account?`}</div>
      <div className="Buttons">
        <Button text={"Cancel"} onClick={closeModal} />
        <Button text={"Confirm"} background="gradient" onClick={removeMember} />
      </div>
    </div>
  );
}

export default ConfirmRemoveMemberModal;
