import "./GlobalModal.css";
import { IoClose } from "react-icons/io5";
import Modal from "react-modal";
import useLayout, { useCloseModal } from "../../hooks/layout";
import ConfirmLeaveMeetingModal from "./ConfirmLeaveMeetingModal";
import InviteModal from "./InviteModal";
import BuyMoreModal from "./BuyMoreModal";
import SettingsModal from "./SettingsModal";
import ShareRecordingModal from "./ShareRecordingModal";
import ConfirmRecordingActionModal from "./ConfirmRecordingActionModal";
import useCall from "../../hooks/call";
import { ReactComponent as StartRecord } from "../../assets/record-white.svg";
import { ReactComponent as StopRecord } from "../../assets/record-red.svg";
import DownloadTranscriptModal from "./DownloadTranscriptModal";
import ReportCaptionModal from "./ReportCaptionModal";
import LoginModal from "./LoginModal";
import InviteMemberModal from "./InviteMemberModal";
import ConfirmRemoveMemberModal from "./ConfirmRemoveMemberModal";
import JoinNestModal from "./JoinNestModal";
import SupportModal from "./SupportModal";

const GlobalModal = () => {
  const [layout] = useLayout();
  const [call] = useCall();
  const closeModal = useCloseModal();

  let modalContent = undefined;
  let modalTitle = undefined;
  switch (layout.modal) {
    case "login":
      modalTitle = "Login";
      modalContent = <LoginModal />;
      break;
    case "invite":
      modalTitle = "Invite Attendees";
      modalContent = <InviteModal />;
      break;
    case "confirm-leave-meeting":
      modalTitle = "Leave Meeting";
      modalContent = <ConfirmLeaveMeetingModal />;
      break;
    case "buy-more":
      modalTitle = "Buy More";
      modalContent = <BuyMoreModal />;
      break;
    case "settings":
      modalTitle = "Settings";
      modalContent = <SettingsModal />;
      break;
    case "share-recording":
      modalTitle = "Share Recording";
      modalContent = (
        <ShareRecordingModal
          recordingId={layout.modalParams.recordingId}
          members={layout.modalParams.members}
        />
      );
      break;
    case "confirm-recording-action":
      const action = call.recording ? "stop" : "start";
      const actionText = call.recording ? "Stop" : "Start";
      modalTitle = (
        <div>
          {call.recording ? <StopRecord /> : <StartRecord />}
          {`${actionText} Recording`}
        </div>
      );
      modalContent = <ConfirmRecordingActionModal action={action} />;
      break;
    case "download-transcript":
      modalTitle = "Download Meeting Notes";
      modalContent = <DownloadTranscriptModal />;
      break;
    case "report-caption":
      modalTitle = "Report Incorrect Translation";
      modalContent = <ReportCaptionModal />;
      break;
    case "invite-member":
      modalTitle = "Invite a New Member";
      modalContent = <InviteMemberModal />;
      break;
    case "confirm-remove-member":
      modalTitle = "Remove Member";
      modalContent = (
        <ConfirmRemoveMemberModal email={layout.modalParams.email} />
      );
      break;
    case "join-nest":
      modalTitle = layout.modalParams.create ? "Build a nest" : "Join a nest";
      modalContent = <JoinNestModal create={layout.modalParams.create} />;
      break;
    case "support":
      modalTitle = layout.modalParams.managePlan ? "Manage Plan" : "Support";
      modalContent = (
        <SupportModal managePlan={layout.modalParams.managePlan} />
      );
      break;
  }

  return (
    <Modal
      isOpen={!!layout.modal}
      className="Modal"
      style={{
        content: {
          position: "absolute",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "10px",
          backgroundColor: "#101010",
          overflow: "visible",
          color: "white",
        },
        overlay: {
          zIndex: 999,
          backgroundColor: "rgba(50, 50, 50, 0.8)",
        },
      }}
    >
      <div className="ModalWrapper">
        <div className="ModalTitle">
          {modalTitle}
          <IoClose className="Close" onClick={closeModal} />
        </div>
        <div className="ModalContent">{modalContent}</div>
      </div>
    </Modal>
  );
};

export default GlobalModal;
