import {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
  UIEvent,
  useMemo,
} from "react";
import "./Captions.css";
import { IoArrowDown, IoFlag } from "react-icons/io5";
import useTranscript, { useGetEditedLocalizedText } from "../hooks/transcript";
import useLayout, { useToggleReportCaptionModal } from "../hooks/layout";
import { useInterval, useWindowSize } from "react-use";
import useCall from "../hooks/call";
import { useLocalParticipant } from "../hooks/user";
import React from "react";

const Captions = () => {
  const [transcript] = useTranscript();

  const captionsElement = createRef<HTMLDivElement>();
  const [showScrollToBottom, setShowScrollToBottom] = useState(false);

  const toggleReportCaptionModal = useToggleReportCaptionModal();
  const getEditedLocalizedText = useGetEditedLocalizedText();

  const checkInAutoScrollThreshold = useCallback(() => {
    if (captionsElement.current) {
      const thresholdInPixels = captionsElement.current.offsetHeight;
      return (
        captionsElement.current.scrollTop +
          captionsElement.current.offsetHeight >
        captionsElement.current.scrollHeight - thresholdInPixels
      );
    } else {
      return true;
    }
  }, [captionsElement]);

  // Auto scroll captions (when in scroll threshold)
  useEffect(() => {
    if (!showScrollToBottom) {
      // If they are in the threshold of auto scroll, continue to auto scroll on changes
      captionsElement.current?.scrollTo({
        top: captionsElement.current.scrollHeight,
      });
    }

    return () => {};
  }, [captionsElement, showScrollToBottom]);

  // Check if user re-focuses tab to restart auto scroll
  useEffect(() => {
    const onTabFocused = (e: FocusEvent) => {
      captionsElement.current?.scrollTo({
        top: captionsElement.current.scrollHeight,
      });
    };
    window.addEventListener("focus", onTabFocused);
    return () => {
      window.removeEventListener("focus", onTabFocused);
    };
  }, [captionsElement]);

  const interimCaptionLengths = useMemo(() => {
    return Object.values(transcript.interimCaptions).reduce((acc, curr) => {
      return acc + curr.text?.length || 0;
    }, 0);
  }, [transcript.interimCaptions]);

  // Check if user scrolls back into auto scroll threshold (show/hide scroll to bottom button)
  const prevScrollTop = useRef<number>(0);
  const prevTranscriptFontSize = useRef<number>(transcript.fontSize);
  const [layout] = useLayout();
  const prevResizeTopHeight = useRef<number>(layout.resize.topHeightInPercent);
  const prevFloatingTranscriptSize = useRef<{ width: number; height: number }>(
    layout.floatingTranscriptSize
  );
  const windowSize = useWindowSize();
  const prevWindowHeight = useRef<number>(windowSize.height);
  const prevInterimCaptionLengths = useRef<number>(interimCaptionLengths);
  const onCaptionsScroll = useCallback(
    (event: UIEvent<HTMLDivElement>) => {
      // User scrolled up to stop auto captions if all of the following are true:
      // 1. Transcript font size didn't change
      // 2. Docked transcript size didn't change
      // 3. Floating transcript size didn't change
      // 4. Window height didn't change
      // 5. Interim caption lengths didn't change
      const upwardScrollDetected =
        prevScrollTop.current > event.currentTarget.scrollTop &&
        transcript.fontSize === prevTranscriptFontSize.current &&
        layout.resize.topHeightInPercent === prevResizeTopHeight.current &&
        layout.floatingTranscriptSize === prevFloatingTranscriptSize.current &&
        windowSize.height === prevWindowHeight.current &&
        interimCaptionLengths === prevInterimCaptionLengths.current;
      prevTranscriptFontSize.current = transcript.fontSize;
      prevResizeTopHeight.current = layout.resize.topHeightInPercent;
      prevFloatingTranscriptSize.current = layout.floatingTranscriptSize;
      prevWindowHeight.current = windowSize.height;
      prevInterimCaptionLengths.current = interimCaptionLengths;
      setShowScrollToBottom(
        upwardScrollDetected || !checkInAutoScrollThreshold()
      );
      prevScrollTop.current = event.currentTarget.scrollTop;
    },
    [
      setShowScrollToBottom,
      transcript.fontSize,
      layout.resize.topHeightInPercent,
      layout.floatingTranscriptSize,
      windowSize.height,
      prevScrollTop,
      checkInAutoScrollThreshold,
      interimCaptionLengths,
    ]
  );

  // Click to scroll to bottom of captions
  const scrollToBottom = useCallback(() => {
    setShowScrollToBottom(false);
    captionsElement.current?.scrollTo({
      top: captionsElement.current.scrollHeight,
      behavior: "smooth",
    });
  }, [setShowScrollToBottom, captionsElement]);

  const [call] = useCall();
  const localParticipant = useLocalParticipant();
  const [, setTranscript] = useTranscript();

  // Prevent stuck interim captions (no update for caption id for more than 5 seconds)
  const lastInterimCaptionsContent = useRef(transcript.interimCaptions);
  useInterval(() => {
    for (const displayCaption of Object.values(
      lastInterimCaptionsContent.current
    )) {
      if (
        displayCaption.text ===
        transcript.interimCaptions[displayCaption.id]?.text
      ) {
        setTranscript((prevState) => {
          const { [displayCaption.id]: omit, ...rest } =
            prevState.interimCaptions;
          return {
            ...prevState,
            interimCaptions: rest,
            captions: {
              ...prevState.captions,
              [displayCaption.id]: displayCaption,
            },
          };
        });
      }
    }
    lastInterimCaptionsContent.current = transcript.interimCaptions;
  }, 5000);

  const translationLoading =
    Object.values(call.remotePeers).length &&
    !Object.values(transcript.captions).length &&
    !Object.values(transcript.interimCaptions).length &&
    call.translationProvider.translationEnabled;

  if (translationLoading) {
    return (
      <div className="CaptionsWrapper">
        <div className="CaptionsLoading">
          {"Byrdhouse translation is starting..."}
        </div>
      </div>
    );
  } else {
    return (
      <div className="CaptionsWrapper">
        <div
          className="Captions"
          ref={captionsElement}
          onScroll={onCaptionsScroll}
          style={{ fontSize: transcript.fontSize }}
        >
          {Object.values(transcript.captions).map((caption, index) => {
            const isOwnCaption =
              caption.from.sessionId === localParticipant?.sessionId;

            return (
              <div
                className={`CaptionWrapper ${
                  isOwnCaption ? "OwnCaptionWrapper" : ""
                }`}
                key={"recognized" + index}
              >
                {isOwnCaption && (
                  <React.Fragment>
                    <IoFlag
                      onClick={() => toggleReportCaptionModal(caption.id)}
                    />
                    {caption.corrected && (
                      <span className="CorrectedCaption">{` (${getEditedLocalizedText()})`}</span>
                    )}
                  </React.Fragment>
                )}
                <span className={`Caption ${isOwnCaption ? "OwnCaption" : ""}`}>
                  <span className="CaptionFrom">
                    {caption.from.displayName + ": "}
                  </span>
                  <span className="RecognizedCaption">{caption.text}</span>
                </span>
                {!isOwnCaption && (
                  <React.Fragment>
                    {caption.corrected && (
                      <span className="CorrectedCaption">{` (${getEditedLocalizedText()})`}</span>
                    )}
                    <IoFlag
                      onClick={() => toggleReportCaptionModal(caption.id)}
                    />
                  </React.Fragment>
                )}
              </div>
            );
          })}
          {Object.values(transcript.interimCaptions).map((caption, index) => {
            const isOwnCaption =
              caption.from.sessionId === localParticipant?.sessionId;

            return (
              <div
                className={`CaptionWrapper ${
                  isOwnCaption ? "OwnCaptionWrapper" : ""
                }`}
                key={"recognizing" + index}
              >
                <span className={`Caption ${isOwnCaption ? "OwnCaption" : ""}`}>
                  <span className="CaptionFrom">
                    {caption.from.displayName + ": "}
                  </span>
                  <span className="RecognizingCaption">{caption.text}</span>
                </span>
              </div>
            );
          })}
        </div>
        {showScrollToBottom ? (
          <div className="ScrollToBottom" onClick={scrollToBottom}>
            <IoArrowDown />
            Show latest captions
          </div>
        ) : null}
      </div>
    );
  }
};

export default Captions;
