import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useRef } from "react";
import useCall, {
  useJoinRoom,
  useLeaveCall,
  useMirrorTrack,
  useReassignHost,
  useRecordingInProgress,
  useRecordingStarted,
  useRecordingStopped,
  useRemotePeerScreenSharing,
  useRemoveRemotePeer,
  useRemoveUser,
  useSortSpeakers,
  useSubscribeToRemotePeerAudio,
  useSubscribeToRemotePeerVideo,
  useUpdateRemotePeer,
} from "../hooks/call";
import useUser, { useLocalParticipant } from "../hooks/user";
import "./Meeting.css";
import {
  SCREEN_SHARE_PREFIX,
  ReassignHostArgs,
  RoomID,
  RemoveParticipantArgs,
  LeaveRoomArgs,
  SendUpdateParticipantArgs,
  ReceiveJoinRoomArgs,
  ReceiveUpdateParticipantArgs,
  SessionID,
  SendDubIdArgs,
  ReceiveDetectedLanguageArgs,
  SendJoinRoomArgs,
  StartDubArgs,
} from "byrdhouse-types";
import api from "../api";
import useSocket, { useSocketConnectionManager } from "../hooks/socket";
import agora, { screenShare } from "../agora";
import {
  ConnectionState,
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
} from "agora-rtc-sdk-ng";
import { IoInformation, IoPeople, IoPersonRemove } from "react-icons/io5";
import Panel from "../components/Panel";
import Toolbar from "../components/Toolbar";
import Captions from "../components/Captions";
import ResizePanelVertical from "../components/ResizePanelVertical";
import useLayout from "../hooks/layout";
import { useWindowSize } from "react-use";
import { LocalStorageKeys, PanelVisibilityState } from "../types";
import Chat from "../components/Chat";
import {
  useListenForCaptionCorrection,
  useListenForRemoteCaptions,
  useListenForTranslationProviderUpdate,
} from "../hooks/transcript";
import { ReactComponent as StartRecord } from "../assets/record-red.svg";
import { ReactComponent as StopRecord } from "../assets/record-white.svg";
import Stream from "../components/Stream";
import { Id as ToastId, toast } from "react-toastify";
import Summarizer from "../components/Summarizer";
import Participants from "../components/Participants";
import CurrentStream from "../components/CurrentStream";

const Meeting = () => {
  const params = useParams();
  const socket = useSocket();
  const [call, setCall] = useCall();
  const navigate = useNavigate();
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 1000;
  const localParticipant = useLocalParticipant();

  const droppedToastContent = useMemo(
    () => (
      <div className="ByrdhouseToast">
        {
          "Sorry you have dropped from the nest. We are flying you back to your nest, please stand by..."
        }
      </div>
    ),
    []
  );

  // Socket reconnection
  const droppedToastRef = useRef<ToastId | undefined>();
  useEffect(() => {
    // Disconnect messaging
    const onDisconnect = () => {
      console.debug("Socket disconnected...");
      // Don't show if intentionally leaving
      if (call.state !== "disconnected" && !droppedToastRef.current) {
        droppedToastRef.current = toast.error(droppedToastContent, {
          autoClose: false,
        });
      }
    };
    socket?.on("disconnect", onDisconnect);

    // Socket reconnect finished, attempt rejoin room and re-publish
    const onConnect = async () => {
      if (droppedToastRef.current) {
        toast.dismiss(droppedToastRef.current);
        droppedToastRef.current = undefined;
      }
      if (params.room && call.state === "connected" && localParticipant) {
        console.debug("Socket reconnected...");
        console.debug("Socket attempting to rejoin room", params.room, "...");

        // Rejoin room
        const joinArgs: SendJoinRoomArgs = {
          room: params.room as RoomID,
          participant: localParticipant,
        };
        socket?.emit("join", joinArgs);

        // Restart dub (if enabled)
        if (call.dub) {
          const startDubArgs: StartDubArgs = {
            roomId: params.room as RoomID,
            participant: localParticipant,
          };
          socket?.emit("start_dub", startDubArgs);
        }
      }
    };
    socket?.on("connect", onConnect);

    return () => {
      socket?.off("disconnect", onDisconnect);
      socket?.off("connect", onConnect);
    };
  }, [
    socket,
    params.room,
    localParticipant,
    call.state,
    call.localScreenShareTrack,
    call.localMicrophoneTrack,
    call.localCameraTrack,
    call.cameraMuted,
    call.micMuted,
    call.dub,
    droppedToastContent,
  ]);

  useSocketConnectionManager();

  useListenForRemoteCaptions();
  useListenForCaptionCorrection();
  useListenForTranslationProviderUpdate();
  useSortSpeakers();

  useRecordingStarted(
    <div className="ByrdhouseToast">
      <StartRecord />
      {"Meeting recording has started"}
    </div>
  );
  useRecordingStopped(
    <div className="ByrdhouseToast">
      <StopRecord />
      {"Meeting recording has stopped"}
    </div>
  );
  useRecordingInProgress(
    <div className="ByrdhouseToast">
      <StartRecord />
      {"Meeting recording in progress"}
    </div>
  );

  // Leave when call idle
  const leaveCall = useLeaveCall();
  useEffect(() => {
    let leaveCallTimeout: NodeJS.Timeout;
    if (!Object.values(call.remotePeers).length && call.state === "connected") {
      leaveCallTimeout = setTimeout(() => {
        // Redirect back to waiting room
        leaveCall({ intentional: false });
        // Notify with a toast
        toast(
          <div className="ByrdhouseToast">
            <IoInformation />
            {"You've timed out from the nest, please invite another user."}
          </div>
        );
      }, 20 * 60 * 1000);
    }

    return () => {
      clearTimeout(leaveCallTimeout);
    };
  }, [call.remotePeers, call.state, leaveCall]);

  const removeRemoteTrack = useCallback(
    (remotePeerID: SessionID, mediaType: "audio" | "video") => {
      let removeScreenShare = false;
      if (remotePeerID.includes(SCREEN_SHARE_PREFIX)) {
        remotePeerID = remotePeerID.replace(
          SCREEN_SHARE_PREFIX,
          ""
        ) as SessionID;
        removeScreenShare = true;
      }
      setCall((prevState) => {
        let remoteTracks = prevState.remoteTracks[remotePeerID];
        if (remoteTracks) {
          // Cleanup/remove track
          if (mediaType === "audio") {
            delete remoteTracks.audioTrack;
          }
          if (mediaType === "video") {
            // If we're removing screen share, add the camera video track back (if exists)
            let remotePeer = agora.remoteUsers.find(
              (peer) => remotePeerID === (peer.uid as SessionID)
            );
            if (removeScreenShare && remotePeer && remotePeer.videoTrack) {
              remoteTracks.videoTrack = remotePeer.videoTrack;
            } else {
              delete remoteTracks.videoTrack;
            }
          }
          if (!remoteTracks.audioTrack && !remoteTracks.videoTrack) {
            // No tracks left, remove from dictionary
            const { [remotePeerID]: omitTracks, ...restTracks } =
              prevState.remoteTracks;
            return {
              ...prevState,
              remoteTracks: restTracks,
            };
          }
        }

        return {
          ...prevState,
          remoteTracks: {
            ...prevState.remoteTracks,
            [remotePeerID]: remoteTracks,
          },
        };
      });
    },
    [setCall]
  );

  const rejoinAgora = useCallback(
    async (client: IAgoraRTCClient, isScreenShare: boolean) => {
      if (!params.room || !localParticipant) {
        return;
      }
      const agoraId = isScreenShare
        ? `${SCREEN_SHARE_PREFIX}${localParticipant.sessionId}`
        : localParticipant.sessionId;

      let response = await api.issueRTCToken({
        roomID: params.room as RoomID,
        agoraId: agoraId,
      });
      await client.join(
        process.env.REACT_APP_AGORA_APP_ID!,
        params.room,
        response.encodedToken,
        agoraId
      );
      // Publish local tracks to the room
      let localTracks = [];
      if (call.localMicrophoneTrack) {
        localTracks.push(call.localMicrophoneTrack);
      }
      const videoTrack = isScreenShare
        ? call.localScreenShareTrack
        : call.localCameraTrack;
      if (videoTrack && videoTrack.enabled) {
        console.debug("Local video track published on rejoin agora room...");
        localTracks.push(videoTrack);
      }
      await agora.publish(localTracks);
    },
    [
      params.room,
      localParticipant,
      call.localMicrophoneTrack,
      call.localScreenShareTrack,
      call.localCameraTrack,
    ]
  );

  // Renew tokens
  useEffect(() => {
    const renewAgoraToken = async () => {
      console.log("Agora token expiring, renew...");
      // Only renew when already connected in a call
      if (
        !params.room ||
        call.state !== "connected" ||
        !agora.channelName ||
        !localParticipant?.sessionId
      ) {
        return;
      }
      let response = await api.issueRTCToken({
        roomID: params.room as RoomID,
        agoraId: localParticipant.sessionId,
      });
      await agora.renewToken(response.encodedToken);
      console.log("Agora token renew done");
    };

    const rejoin = async () => {
      console.log("Agora token expired, rejoin...");
      await rejoinAgora(agora, false);
      console.log("Agora token rejoin done");
    };

    const renewScreenShareToken = async () => {
      console.log("Agora token expiring, renew... (screen share)");
      // Only renew when connected and screen sharing
      if (
        !params.room ||
        call.state !== "connected" ||
        !screenShare.channelName ||
        !localParticipant?.sessionId
      ) {
        return;
      }
      const agoraId = `${SCREEN_SHARE_PREFIX}${localParticipant.sessionId}`;
      let response = await api.issueRTCToken({
        roomID: params.room as RoomID,
        agoraId: agoraId,
      });
      await screenShare.renewToken(response.encodedToken);
      console.log("Agora token renew done (screen share)");
    };

    const rejoinScreenshare = async () => {
      console.log("Agora token expired, rejoin... (screen share)");
      await rejoinAgora(screenShare, true);
      console.log("Agora token rejoin done (screen share)");
    };

    agora.on("token-privilege-will-expire", renewAgoraToken);
    agora.on("token-privilege-did-expire", rejoin);
    screenShare.on("token-privilege-will-expire", renewScreenShareToken);
    screenShare.on("token-privilege-did-expire", rejoinScreenshare);

    return () => {
      agora.off("token-privilege-will-expire", renewAgoraToken);
      agora.off("token-privilege-did-expire", rejoin);
      screenShare.off("token-privilege-will-expire", renewScreenShareToken);
      screenShare.off("token-privilege-did-expire", rejoinScreenshare);
    };
  }, [
    rejoinAgora,
    call.localScreenShareTrack,
    call.state,
    params.room,
    localParticipant?.sessionId,
  ]);

  // Host actions

  // Check if host was reassigned
  const reassignHost = useReassignHost();
  useEffect(() => {
    socket?.on("reassign_host", (args: ReassignHostArgs) => {
      reassignHost(args.participant);
      if (localParticipant?.userId === args.participant.userId) {
        toast(
          <div className="ByrdhouseToast">
            <IoPeople />
            {"You have been assigned as the new host"}
          </div>
        );
      }
    });

    return () => {
      socket?.off("reassign_host");
    };
  }, [socket, localParticipant, reassignHost]);

  // Check if host removed local user
  const removeUser = useRemoveUser();
  useEffect(() => {
    socket?.on("remove_user", (args: RemoveParticipantArgs) => {
      if (localParticipant?.userId === args.participant.userId) {
        // Local user was removed
        leaveCall({ intentional: false });
        navigate("/");
        toast(
          <div className="ByrdhouseToast">
            <IoPersonRemove />
            {"You have been removed from the nest by the host"}
          </div>
        );
      }
      removeUser(args.participant, true);
    });

    return () => {
      socket?.off("remove_user");
    };
  }, [navigate, socket, removeUser, leaveCall, localParticipant]);

  // Send update anytime user state changes
  useEffect(() => {
    if (call.state !== "connected" || !params.room || !localParticipant) {
      return;
    }
    const args: SendUpdateParticipantArgs = {
      room: params.room as RoomID,
      participant: localParticipant,
    };
    socket?.emit("update", args);

    return () => {};
  }, [call.state, socket, params.room, localParticipant]);

  // If remote peer is in call, always play their audio
  useEffect(() => {
    Object.values(call.remotePeers).forEach((peer) => {
      const audioTrack = call.remoteTracks[peer.sessionId]?.audioTrack;
      if (audioTrack && !audioTrack.isPlaying) {
        audioTrack.play();
      }
    });

    return () => {};
  }, [
    call.remotePeers,
    call.remoteTracks,
    localParticipant?.speechLanguageLocale.code,
  ]);

  const userJoined = useCallback(() => {
    if (!localParticipant || !params.room) {
      return;
    }

    const args: SendUpdateParticipantArgs = {
      room: params.room as RoomID,
      participant: localParticipant,
    };
    socket?.emit("update", args);
  }, [params.room, localParticipant, socket]);

  const agoraUserJoined = useCallback(
    (agoraUser: IAgoraRTCRemoteUser) => {
      if (agoraUser.uid.toString().includes("dub-")) {
        if (!params.room) {
          return;
        }
        const roomId = params.room as RoomID;
        // Report uintid to backend for recording to unsubscribe
        const dubId = (
          agoraUser as IAgoraRTCRemoteUser & { _uintid: number }
        )._uintid.toString();
        const args: SendDubIdArgs = { roomId, dubId };
        socket?.emit("send_dub_id", args);
      } else {
        userJoined();
      }
    },
    [params.room, socket, userJoined]
  );

  // Setup listeners for media tracks
  const updateRemotePeer = useUpdateRemotePeer();
  const removeRemotePeer = useRemoveRemotePeer();
  const subscribeToRemotePeerAudio = useSubscribeToRemotePeerAudio();
  const subscribeToRemotePeerVideo = useSubscribeToRemotePeerVideo();
  const remotePeerScreenSharing = useRemotePeerScreenSharing();
  useEffect(() => {
    const userJoinedSocket = (args: ReceiveJoinRoomArgs) => {
      console.debug("Received user joined", args.participant.sessionId);
      userJoined();
      updateRemotePeer(args.participant);
      // Always subscribe to audio when user joined (if not already)
      subscribeToRemotePeerAudio(args.participant.sessionId);
    };

    const userUpdated = (args: ReceiveUpdateParticipantArgs) => {
      console.debug("Received user update", args.participant.sessionId);
      if (args.participant) {
        updateRemotePeer(args.participant);
      }
    };

    const userLeft = (args: LeaveRoomArgs) => {
      console.debug("Received user left", args.from);
      removeRemotePeer(args.from);
    };

    // Subscribe to remote peers publishing tracks
    const userPublished = async (
      remotePeer: IAgoraRTCRemoteUser,
      mediaType: "audio" | "video"
    ) => {
      console.debug("Got user published event", mediaType);
      // Do not subscribe to own tracks (including screen share)
      let remotePeerAgoraID = remotePeer.uid as SessionID;
      if (
        localParticipant?.sessionId !== remotePeerAgoraID &&
        localParticipant?.sessionId !==
          remotePeerAgoraID.replace(SCREEN_SHARE_PREFIX, "")
      ) {
        // Subscribe to all track publishes
        if (mediaType === "audio" && remotePeer.hasAudio) {
          subscribeToRemotePeerAudio(remotePeerAgoraID);
        }
        if (mediaType === "video" && remotePeer.hasVideo) {
          // Check if video track is screen share
          let screenShare = remotePeerAgoraID.includes(SCREEN_SHARE_PREFIX);
          let remotePeerID = remotePeerAgoraID.replace(
            SCREEN_SHARE_PREFIX,
            ""
          ) as SessionID;
          console.debug(
            "Subscribe to remote peer video from published event",
            screenShare
          );
          subscribeToRemotePeerVideo(remotePeerID, screenShare);
        }
      }
    };

    // Subscribe to remote peers un-publishing tracks

    const userUnPublished = async (
      remotePeer: IAgoraRTCRemoteUser,
      mediaType: "audio" | "video"
    ) => {
      // No need to unsubscribe from own user
      if (
        localParticipant?.sessionId !== (remotePeer.uid as SessionID) &&
        localParticipant?.sessionId !==
          (remotePeer.uid as SessionID).replace(SCREEN_SHARE_PREFIX, "")
      ) {
        // If camera unpublished and peer is screen sharing, don't remove track
        if (
          !remotePeer.uid.toString().includes(SCREEN_SHARE_PREFIX) &&
          remotePeerScreenSharing?.sessionId ===
            (remotePeer.uid as SessionID) &&
          mediaType === "video"
        ) {
          return;
        }
        await agora.unsubscribe(remotePeer, mediaType);
        removeRemoteTrack(remotePeer.uid as SessionID, mediaType);
      }
    };

    // Setup listeners
    socket?.on("join", userJoinedSocket);
    socket?.on("update", userUpdated);
    socket?.on("leave", userLeft);
    agora.on("user-joined", agoraUserJoined);
    agora.on("user-published", userPublished);
    agora.on("user-unpublished", userUnPublished);

    return () => {
      socket?.off("join", userJoinedSocket);
      socket?.off("update", userUpdated);
      socket?.off("leave", userLeft);
      agora.off("user-joined", agoraUserJoined);
      agora.off("user-published", userPublished);
      agora.off("user-unpublished", userUnPublished);
    };
  }, [
    localParticipant,
    socket,
    params.room,
    remotePeerScreenSharing,
    subscribeToRemotePeerAudio,
    subscribeToRemotePeerVideo,
    removeRemoteTrack,
    updateRemotePeer,
    removeRemotePeer,
    userJoined,
    agoraUserJoined,
  ]);

  // Change displayed and dubbed language when auto-detecting
  // This is a message from the server that tells us what language has been detected
  const [, setUser] = useUser();
  const onDetectedLanguage = useCallback(
    (args: ReceiveDetectedLanguageArgs) => {
      setUser((prevState) => {
        if (!prevState || prevState.detectedLanguage === args.language) {
          return prevState;
        }
        console.debug(
          "Detected language",
          args.language.display,
          args.language.code
        );
        return { ...prevState, detectedLanguage: args.language };
      });
    },
    [setUser]
  );
  useEffect(() => {
    socket?.on("detected_language", onDetectedLanguage);
    return () => {
      socket?.off("detected_language");
    };
  }, [socket, onDetectedLanguage]);

  const joinRoom = useJoinRoom();
  const joined = useRef(false);
  useEffect(() => {
    if (call.state !== "disconnected" && !joined.current && socket) {
      joined.current = true;
      joinRoom();
    }
  }, [call.state, joinRoom, socket]);

  // Handle agora re-connections
  useEffect(() => {
    const agoraReconnected = async (
      currState: ConnectionState,
      prevState: ConnectionState
    ) => {
      console.debug(
        "Agora connection state changed,",
        "current state:",
        currState,
        "previous state:",
        prevState
      );

      if (
        (currState === "RECONNECTING" || currState === "DISCONNECTED") &&
        !droppedToastRef.current
      ) {
        droppedToastRef.current = toast.error(droppedToastContent, {
          autoClose: false,
        });
      } else if (droppedToastRef.current) {
        toast.dismiss(droppedToastRef.current);
      }

      if (currState === "CONNECTED" && prevState === "RECONNECTING") {
        // Re-publish local tracks
        console.debug(
          "Agora connection re-established, republishing tracks..."
        );
        let localTracks = [];

        if (
          !call.localScreenShareTrack &&
          call.localCameraTrack &&
          !call.cameraMuted &&
          call.localCameraTrack.enabled
        ) {
          console.debug("Local camera track republish");
          localTracks.push(call.localCameraTrack);
        }
        if (call.localMicrophoneTrack && !call.micMuted) {
          localTracks.push(call.localMicrophoneTrack);
        }

        if (localTracks.length) {
          await agora.publish(localTracks);
        }
      }
    };

    const screenShareReconnected = async (
      currState: ConnectionState,
      prevState: ConnectionState
    ) => {
      if (currState === "CONNECTED" && prevState === "RECONNECTING") {
        if (call.localScreenShareTrack) {
          await screenShare.publish(call.localScreenShareTrack);
          console.debug("Republish screen share");
        }
      }
    };

    agora.on("connection-state-change", agoraReconnected);
    screenShare.on("connection-state-change", screenShareReconnected);

    return () => {
      agora.off("connection-state-change", agoraReconnected);
      screenShare.off("connection-state-change", screenShareReconnected);
    };
  }, [
    call.cameraMuted,
    call.localCameraTrack,
    call.localMicrophoneTrack,
    call.localScreenShareTrack,
    call.micMuted,
    droppedToastContent,
  ]);

  // Recording was true during meeting, set local storage to show button in post meeting
  useEffect(() => {
    if (call.recording) {
      localStorage.setItem(LocalStorageKeys.SHOW_RECORDING_BUTTON, "true");
    }
  }, [call.recording]);

  const [layout] = useLayout();
  const mirrorTrack = useMirrorTrack();
  return (
    <div className="Meeting">
      {isMobile &&
        localParticipant &&
        !!Object.values(call.remotePeers).length &&
        !call.cameraMuted && (
          <div className="MobileLocalStream">
            <Stream
              user={localParticipant}
              mirror={true}
              local={true}
              audioTrack={call.localMicrophoneTrack}
              videoTrack={mirrorTrack}
              fit={"cover"}
            />
          </div>
        )}

      {/* Floating panels */}

      {layout.panels.transcript.visible &&
        layout.panels.transcript.visibility === PanelVisibilityState.FLOATING &&
        windowSize.width > 1000 && (
          <Panel type="transcript" floating={true}>
            {!layout.panels.transcript.summaryActive ? (
              <Captions />
            ) : (
              <Summarizer />
            )}
          </Panel>
        )}

      {layout.panels.chat.visible && (
        <Panel type="chat" floating={true}>
          <Chat />
        </Panel>
      )}

      {/* Docked panels */}
      {(layout.panels.speaker.visible || layout.panels.transcript.visible) && (
        <ResizePanelVertical
          minTopHeightInPixels={windowSize.width < 1700 ? 160 : 180}
          minBottomHeightInPixels={windowSize.width < 1700 ? 42 : 50}
        >
          {/* Current Speaker */}
          {isMobile ? (
            <CurrentStream />
          ) : (
            layout.panels.speaker.visible && (
              <Panel type="participants" floating={false}>
                <Participants />
              </Panel>
            )
          )}

          {/* Captions and chat */}
          {((layout.panels.transcript.visible &&
            layout.panels.transcript.visibility ===
              PanelVisibilityState.DOCKED) ||
            windowSize.width <= 1000) && (
            <Panel type="transcript" floating={false}>
              {!layout.panels.transcript.summaryActive ? (
                <Captions />
              ) : (
                <Summarizer />
              )}
            </Panel>
          )}
        </ResizePanelVertical>
      )}
      <Toolbar />
    </div>
  );
};

export default Meeting;
