"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Conflict = exports.Forbidden = exports.Unauthorized = exports.InternalError = exports.BadRequest = exports.NotFound = exports.ByrdhouseError = exports.ErrorMessage = void 0;
var http_1 = require("./http");
// Error Types
var ErrorMessage;
(function (ErrorMessage) {
    // Common HTTP error responses
    ErrorMessage["NOT_FOUND"] = "NOT_FOUND";
    ErrorMessage["BAD_REQUEST"] = "BAD_REQUEST";
    ErrorMessage["INTERNAL_ERROR"] = "INTERNAL_ERROR";
    ErrorMessage["UNAUTHORIZED"] = "UNAUTHORIZED";
    ErrorMessage["FORBIDDEN"] = "FORBIDDEN";
    ErrorMessage["CONFLICT"] = "CONFLICT";
    // Auth
    ErrorMessage["NO_BEARER_TOKEN_SET"] = "NO_BEARER_TOKEN_SET";
    ErrorMessage["NO_BEARER_TOKEN_PREFIX"] = "NO_BEARER_TOKEN_PREFIX";
    ErrorMessage["TOKEN_EXPIRED"] = "TOKEN_EXPIRED";
    ErrorMessage["TOKEN_INVALID"] = "TOKEN_INVALID";
    ErrorMessage["TRIAL_TIMEOUT"] = "TRIAL_TIMEOUT";
    ErrorMessage["TOKEN_REVOKED"] = "TOKEN_REVOKED";
})(ErrorMessage || (exports.ErrorMessage = ErrorMessage = {}));
var ByrdhouseError = /** @class */ (function (_super) {
    __extends(ByrdhouseError, _super);
    function ByrdhouseError(status, message) {
        var _this = _super.call(this, message) || this;
        _this.toString = function () {
            return "".concat(_this.status, ": ").concat(_this.message);
        };
        _this.toJSON = function () {
            return {
                status: _this.status,
                message: _this.message,
            };
        };
        // Fix typescript issue with extending error interface
        Object.setPrototypeOf(_this, ByrdhouseError.prototype);
        _this.status = status;
        _this.message = message;
        return _this;
    }
    return ByrdhouseError;
}(Error));
exports.ByrdhouseError = ByrdhouseError;
var NotFound = /** @class */ (function (_super) {
    __extends(NotFound, _super);
    function NotFound(message) {
        var _this = this;
        if (message) {
            _this = _super.call(this, http_1.StatusCode.NOT_FOUND, message) || this;
        }
        else {
            _this = _super.call(this, http_1.StatusCode.NOT_FOUND, ErrorMessage.NOT_FOUND) || this;
        }
        return _this;
    }
    return NotFound;
}(ByrdhouseError));
exports.NotFound = NotFound;
var BadRequest = /** @class */ (function (_super) {
    __extends(BadRequest, _super);
    function BadRequest(message) {
        var _this = this;
        if (message) {
            _this = _super.call(this, http_1.StatusCode.BAD_REQUEST, message) || this;
        }
        else {
            _this = _super.call(this, http_1.StatusCode.BAD_REQUEST, ErrorMessage.BAD_REQUEST) || this;
        }
        return _this;
    }
    return BadRequest;
}(ByrdhouseError));
exports.BadRequest = BadRequest;
var InternalError = /** @class */ (function (_super) {
    __extends(InternalError, _super);
    function InternalError() {
        return _super.call(this, http_1.StatusCode.INTERNAL_ERROR, ErrorMessage.INTERNAL_ERROR) || this;
    }
    return InternalError;
}(ByrdhouseError));
exports.InternalError = InternalError;
var Unauthorized = /** @class */ (function (_super) {
    __extends(Unauthorized, _super);
    function Unauthorized(message) {
        var _this = this;
        if (message) {
            _this = _super.call(this, http_1.StatusCode.UNAUTHORIZED, message) || this;
        }
        else {
            _this = _super.call(this, http_1.StatusCode.UNAUTHORIZED, ErrorMessage.UNAUTHORIZED) || this;
        }
        return _this;
    }
    return Unauthorized;
}(ByrdhouseError));
exports.Unauthorized = Unauthorized;
var Forbidden = /** @class */ (function (_super) {
    __extends(Forbidden, _super);
    function Forbidden(message) {
        var _this = this;
        if (message) {
            _this = _super.call(this, http_1.StatusCode.FORBIDDEN, message) || this;
        }
        else {
            _this = _super.call(this, http_1.StatusCode.FORBIDDEN, ErrorMessage.FORBIDDEN) || this;
        }
        return _this;
    }
    return Forbidden;
}(ByrdhouseError));
exports.Forbidden = Forbidden;
var Conflict = /** @class */ (function (_super) {
    __extends(Conflict, _super);
    function Conflict(message) {
        var _this = this;
        if (message) {
            _this = _super.call(this, http_1.StatusCode.CONFLICT, message) || this;
        }
        else {
            _this = _super.call(this, http_1.StatusCode.CONFLICT, ErrorMessage.CONFLICT) || this;
        }
        return _this;
    }
    return Conflict;
}(ByrdhouseError));
exports.Conflict = Conflict;
