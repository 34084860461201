import { AmplitudeEvent } from "byrdhouse-types";
import "./Landing.css";
import amplitude from "../amplitude";
import { useCallback, useEffect, useState } from "react";
import Button from "../components/Button";
import circleLeft from "../assets/landing/doodads/circle-left.svg";
import circleRight from "../assets/landing/doodads/circle-right.svg";
import blueStar from "../assets/landing/doodads/blue-star.svg";
import yellowStar from "../assets/landing/doodads/yellow-star.svg";
import pinkStar from "../assets/landing/doodads/pink-star.svg";
import inMeetingExperienceImg from "../assets/landing/meeting.png";
import recordingsExperienceImg from "../assets/landing/recording.png";
import inclusiveCultureImg from "../assets/landing/inclusive.png";
import faqImg from "../assets/landing/faq.svg";
import getStartedImg from "../assets/landing/get-started.svg";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import {
  useOpenGetADemo,
  useOpenSignup,
  useToggleSupportModal,
} from "../hooks/layout";
import SubscriptionPlans from "../components/SubscriptionPlans";
import testimoniesImg from "../assets/landing/testimonies.svg";
import CustomerTestimonies from "../components/CustomerTestimonies";
import howStartedImg from "../assets/landing/how-started.svg";
import meetTheTeamImg from "../assets/landing/meet-the-team.svg";
import jacobImg from "../assets/landing/jacob.jpeg";
import snowImg from "../assets/landing/snow.jpeg";
import logo from "../assets/footer-logo.svg";
import { Helmet } from "react-helmet";
import pricingImg from "../assets/landing/pricing.svg";

type CTAProps = {
  title?: boolean;
};

const CTA = (props: CTAProps) => {
  const openSignup = useOpenSignup();

  const clickTryForFree = useCallback(() => {
    if (props.title) {
      amplitude?.logEvent(AmplitudeEvent.CLICK_ON_TRY_FOR_FREE_LANDING_TITLE);
    } else {
      amplitude?.logEvent(AmplitudeEvent.CLICK_ON_TRY_FOR_FREE_LANDING_FOOTER);
    }
    openSignup();
  }, [props.title, openSignup]);

  const openGetADemo = useOpenGetADemo();

  return (
    <div className="CTA">
      <Button
        text="Try for FREE"
        background="gradient"
        onClick={clickTryForFree}
      />
      <Button border="pink" text="Get a Demo" onClick={openGetADemo} />
    </div>
  );
};

type FAQItemProps = {
  question: string;
  answer: string | string[];
};

const FAQItem = (props: FAQItemProps) => {
  const [collapsed, setCollapsed] = useState(true);

  const toggle = useCallback(() => {
    setCollapsed((prevState) => !prevState);
  }, []);

  return (
    <div className="FAQItem" onClick={toggle}>
      <div className="Toggle">
        <span>{props.question}</span>
        {collapsed ? <IoChevronDown /> : <IoChevronUp />}
      </div>
      {!collapsed && (
        <div>
          {Array.isArray(props.answer)
            ? props.answer.map((a, index) => <div key={index}>{a}</div>)
            : props.answer}
        </div>
      )}
    </div>
  );
};

const Landing = () => {
  useEffect(() => {
    amplitude?.logEvent(AmplitudeEvent.OPEN_LANDING);
  }, []);

  const goToRecordingExperience = useCallback(() => {
    amplitude?.logEvent(AmplitudeEvent.CLICK_ON_LEARN_MORE_LANDING_1);
    const el = document.getElementById("RecordingExperience");
    el?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const goToInclusiveCulture = useCallback(() => {
    amplitude?.logEvent(AmplitudeEvent.CLICK_ON_LEARN_MORE_LANDING_2);
    const el = document.getElementById("InclusiveCulture");
    el?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const goToFAQ = useCallback(() => {
    amplitude?.logEvent(AmplitudeEvent.CLICK_ON_FAQ_LANDING);
    const el = document.getElementById("FAQ");
    el?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const goToPricing = useCallback(() => {
    amplitude?.logEvent(AmplitudeEvent.CLICK_ON_PRICING_LANDING_FOOTER);
    const el = document.getElementById("Pricing");
    el?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const goToOurStory = useCallback(() => {
    amplitude?.logEvent(AmplitudeEvent.CLICK_ON_WHY_STARTED_BYRDHOUSE_LANDING);
    const el = document.getElementById("OurStory");
    el?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const toggleSupportModal = useToggleSupportModal();

  const faqQuestions: FAQItemProps[] = [
    {
      question: "What is Byrdhouse?",
      answer:
        "Byrdhouse is a multilingual video conferencing application that allows teams to seamlessly communicate and collaborate across 100+ languages with AI-powered real-time translation. Participants can see instant translations as subtitles on their screen, and can also enjoy screen sharing, translated text chat, and meeting notes in their preferred languages.",
    },
    {
      question: "What is the best way to use Byrdhouse?",
      answer:
        "We recommend using Byrdhouse with other participants who speak different languages and are from different cultures for instant understanding and collaboration without barriers.",
    },
    {
      question: "What are the technical requirements to use Byrdhouse?",
      answer: "We support all web browsers on desktop and mobile devices.",
    },
    {
      question: "How does Byrdhouse work?",
      answer: [
        "1. Sign up with your email and choose a language.",
        "2. You will be guided to the waiting room to adjust your camera, microphone, language, and background filter.",
        '3. Once you are ready, click on "Build a nest" to start a video call.',
        "4. When you are in your nest, click on the “share the link” button on the bottom of the screen to invite participant(s) to join.",
        "5. Once the participant(s) join, you can start a video call with real-time translation immediately. You can change the language, enable/disable subtitles, enable/disable the camera and microphone, and share your screen with the participant(s).",
        "6. After the call ends, all participants will receive meeting notes and recordings of the call in the language of their preferences.",
      ],
    },
    {
      question: "How to build a nest?",
      answer:
        "Sign up with your email account and you'll be able to build a nest with other participants.",
    },
    {
      question: "How do I invite participants to join the call?",
      answer:
        "Click on the “share the link” button on the toolbar to invite participant(s) to join once you create a nest, or embed our link in your calendar invite so meeting participants can click on it to join.",
    },
    {
      question: "What functionalities does Byrdhouse currently provide?",
      answer: [
        "• Video conferencing with real-time translation of up to 100 different languages and dialects",
        "• Time zone indication",
        "• Screen sharing",
        "• Recording",
        "• Meeting notes in different languages",
        "• Group calls",
        "• Auto-language detection",
        "• Translated text chat",
        "• Voice-to-voice translation in different languages",
        "• Accent and dialect coverage",
        "• Speaker diarization",
        "• Translation captions edit",
        "• Advanced punctuation",
        "• Profanity detection",
      ],
    },
    {
      question: "How are the experiences different from mobile vs. web?",
      answer:
        "Some functionalities are not supported on mobile such as screen sharing, translated text chat, and meeting notes, but the core functionalities should be the same.",
    },
    {
      question: "Can I get meeting notes after the call ends?",
      answer:
        "Yes for sure. After the call ends, we will provide recording and meeting notes for all participants in the language of their preferences.",
    },
  ];

  const openInstallOnChrome = useCallback(() => {
    window
      .open("https://byrdhouseapp.com/ai-event-interpretation/", "_blank")
      ?.focus();
  }, []);

  return (
    <div className="Landing">
      {process.env.NODE_ENV === "production" && (
        <Helmet>
          <link rel="canonical" href="https://byrdhouseapp.com" />
          {/* Demo video structured data */}
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "VideoObject",
              name: "Byrdhouse Demo",
              description:
                "Byrdhouse provides AI-powered, real-time translation in voice and captions for over 100+ languages and dialects.",
              thumbnailUrl: "https://i.ytimg.com/vi/8Q_FjJxZ3Uw/mqdefault.jpg",
              duration: "PT1M31S",
              contentUrl: "https://www.youtube.com/watch?v=8Q_FjJxZ3Uw",
              embedUrl: "https://www.youtube.com/embed/8Q_FjJxZ3Uw",
              uploadDate: "2023-12-08T11:32:53-08:00",
              potentialAction: {
                "@type": "SeekToAction",
                target:
                  "https://youtu.be/8Q_FjJxZ3Uw?t={seek_to_second_number}",
                "startOffset-input": "required name=seek_to_second_number",
              },
            })}
          </script>
          {/* FAQ Structured Data */}
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "FAQPage",
              mainEntity: [
                {
                  "@type": "Question",
                  name: "What is Byrdhouse?",
                  acceptedAnswer: {
                    "@type": "Answer",
                    text: "<p>Byrdhouse is a multilingual video conferencing application that allows teams to seamlessly communicate and collaborate across 100+ languages with AI-powered real-time translation. Participants can see instant translations as subtitles on their screen, and can also enjoy screen sharing, translated text chat, and meeting notes in their preferred languages.</p>",
                  },
                },
                {
                  "@type": "Question",
                  name: "What is the best way to use Byrdhouse?",
                  acceptedAnswer: {
                    "@type": "Answer",
                    text: "<p>We recommend using Byrdhouse with other participants who speak different languages and are from different cultures for instant understanding and collaboration without barriers.</p>",
                  },
                },
                {
                  "@type": "Question",
                  name: "What functionalities does Byrdhouse currently provide?",
                  acceptedAnswer: {
                    "@type": "Answer",
                    text: "<ul><li>Video conferencing with real-time translation of up to 100 different languages and dialects</li><li>Time zone indication</li><li>Screen sharing</li><li>Recording</li><li>Meeting notes in different languages</li><li>Group calls</li><li>Auto-language detection</li><li>Translated text chat</li><li>Voice-to-voice translation in different languages</li><li>Accent and dialect coverage</li><li>Speaker diarization</li><li>Translation captions edit</li><li>Advanced punctuation</li><li>Profanity detection</li></ul>",
                  },
                },
              ],
            })}
          </script>
        </Helmet>
      )}
      {/* Title */}
      <div>
        <div className="Doodads">
          <img
            id="BackgroundCircleLeft"
            alt="Background Left Circle Doodad"
            src={circleLeft}
          />
          <img
            id="BackgroundCircleRight"
            alt="Background Right Circle Doodad"
            src={circleRight}
          />
          <img
            id="TitleBlueStar"
            alt="Background Blue Star Doodad"
            src={blueStar}
          />
          <img
            id="TitleYellowStar"
            alt="Background Yellow Star Doodad"
            src={yellowStar}
          />
        </div>
        <div id="Title" className="LandingSectionContent">
          <div>
            <h1>Connecting the world</h1>
            <h1>one conversation at a time</h1>
          </div>
          <div>
            <h3>
              <span className="Yellow">AI-powered, real-time translation</span>{" "}
              in voice and captions for
            </h3>
            <h3>
              <span className="Green">100+ languages</span> for your{" "}
              <span className="Pink">meetings, calls, and chats</span>
            </h3>
          </div>
          <CTA title={true} />
          <button className="ChromeInstallButton" onClick={openInstallOnChrome}>
            Get AI Event Interpreter
          </button>
        </div>
      </div>
      {/* Demo Video */}
      <div id="Demo" className="LandingSectionContent">
        <iframe
          title="Byrdhouse Demo"
          height="100%"
          src="https://www.youtube.com/embed/8Q_FjJxZ3Uw"
        />
        <a
          href="https://byrdhouse.notion.site/Byrdhouse-Onboarding-4b2712d0cf124e9c95fd37893592d3e7"
          target="_blank"
          rel="noreferrer"
        >
          Learn how it works
        </a>
      </div>
      {/* In-Meeting Experience */}
      <div id="InMeetingExperience" className="LandingSectionContent">
        <h2>
          <span className="YellowUnderline">Instant</span> and{" "}
          <span className="GreenUnderline">accurate</span> AI-powered real-time
          translation that connects people
        </h2>
        <div className="SplitSection">
          <div>
            <p>
              Let us take care of real-time translation so you can focus on
              communication and collaboration with your global teammates and
              international partners
            </p>
            <Button
              border="pink"
              text="Learn More"
              onClick={goToRecordingExperience}
            />
          </div>
          <img src={inMeetingExperienceImg} alt="In-meeting Experience" />
        </div>
      </div>
      {/* Recording Experience */}
      <div id="RecordingExperience" className="LandingSectionContent">
        <h2>
          <span className="YellowUnderline">Engage</span> in conversations and
          meetings with <span className="GreenUnderline">no interruptions</span>{" "}
          from note-taking
        </h2>
        <div className="SplitSection">
          <div>
            <p>
              Automated meeting notes and transcription in different languages
            </p>
            <Button
              border="pink"
              text="Learn More"
              onClick={goToInclusiveCulture}
            />
          </div>
          <img
            src={recordingsExperienceImg}
            alt="Recording and Meeting-notes Experience"
          />
        </div>
      </div>
      {/* Inclusive Culture */}
      <div id="InclusiveCulture" className="LandingSectionContent">
        <img
          style={{ width: "100%" }}
          src={inclusiveCultureImg}
          alt="Inclusive Culture"
        />
        <h2>
          Create an <span className="YellowUnderline">inclusive culture</span>{" "}
          where{" "}
          <span className="GreenUnderline">everyone's voice is heard</span>{" "}
          regardless of what language you speak
        </h2>
        <p>
          Byrdhouse helps build a workplace that's inclusive and reflective of
          diverse languages, cultures, and lived experiences
        </p>
        <Button
          border="pink"
          text="Why we started Byrdhouse"
          onClick={goToOurStory}
        />
      </div>
      <div id="Testimonies" className="LandingSectionContent">
        <img
          style={{ maxWidth: "98px" }}
          src={testimoniesImg}
          alt="Customer Testimonies"
        />
        <h2>Trusted by the best</h2>
        <CustomerTestimonies />
      </div>
      {/* Pricing */}
      <div id="Pricing" className="LandingSectionContent">
        <img src={pricingImg} alt="Pricing" />
        <h2>Pick your plan</h2>
        <SubscriptionPlans />
        <div className="ChromeInstall">
          <div>
            <div>
              For multilingual events, conferences, all hands, and trainings
            </div>
            <div>Get our AI Event Interpreter 👇</div>
          </div>
          <button className="ChromeInstallButton" onClick={openInstallOnChrome}>
            Get AI Event Interpreter
          </button>
        </div>
      </div>
      {/* FAQ */}
      <div id="FAQ" className="LandingSectionContent">
        <div className="Doodads">
          <img
            id="AboutUsPinkStar"
            alt="Background Pink Star Doodad"
            src={pinkStar}
          />
          <img
            id="AboutUsBlueStar"
            alt="Background Blue Star Doodad"
            src={blueStar}
          />
          <img
            id="AboutUsYellowStar"
            alt="Background Yellow Star Doodad"
            src={yellowStar}
          />
        </div>
        <img src={faqImg} alt="FAQ" />
        <h2>FAQ</h2>
        <div>
          {faqQuestions.map((item, index) => (
            <FAQItem
              key={index}
              question={item.question}
              answer={item.answer}
            />
          ))}
        </div>
      </div>
      <div id="OurStory" className="LandingSectionContent">
        <img alt="Our Story" src={howStartedImg} />
        <h2>Our Story</h2>
        <div>
          <p>
            {
              "Our personal and work experience gave us insight into the high cost and inefficiency of hiring interpreters for multilingual meetings, calls, and chats. Not only do our immigrant families have a hard time communicating due to us speaking different languages, but we have also worked for 10 years on multilingual teams that struggled to communicate and collaborate for the same reason."
            }
          </p>
          <p>
            {
              "Communication products aren't built for individuals and teams who speak different languages. Our goal is to change that. Byrdhouse is a video communication application that provides AI-powered, real-time translation for 100+ languages and dialects. Our mission is to help people who speak different languages build deeper trust and connection instead of surface-level understanding."
            }
          </p>
        </div>
      </div>
      {/* About Us */}
      <div id="AboutUs" className="LandingSectionContent">
        <div className="MeetTheTeam">
          <h2>Meet the team</h2>
          <img alt="Meet the team" src={meetTheTeamImg} />
        </div>
        <div className="TeamMembers">
          <div>
            <img alt="Snow Huo" src={snowImg} />
            <div>
              <h4>{"Snow Huo"}</h4>
              <p>{"Co-founder & CEO"}</p>
            </div>
            <p>
              {
                "Byrdhouse was inspired by Snow's experiences as a first-generation immigrant dealing with language barriers. In high school, she was laughed at and picked on for not speaking English fluently. During that time, she envisioned a world where people could instantaneously understand each other, regardless of the languages they speak. Before creating Byrdhouse, she developed products and led product teams at Disney, Groupon, and Chowbus."
              }
            </p>
          </div>
          <div>
            <img alt="Jacob Greenway" src={jacobImg} />
            <div>
              <h4>{"Jacob Greenway"}</h4>
              <p>{"Co-founder & CTO"}</p>
            </div>
            <p>
              {
                "Jacob and Snow were friends before they started working together to build Byrdhouse, driven by their shared passion for helping people worldwide overcome language barriers to foster deeper connections and understanding. He previously built an AI-powered audio application to help immigrants overcome language barriers. Before Byrdhouse, he worked as an engineer and founded a video-sharing platform."
              }
            </p>
          </div>
        </div>
      </div>
      {/* Get Started */}
      <div id="GetStarted" className="LandingSectionContent">
        <img src={getStartedImg} alt="Get Started" />
        <h2>Get started today</h2>
        <CTA />
      </div>
      {/* Footer */}
      <div className="Footer">
        <div>
          <div className="FooterLogo">
            <img src={logo} alt="Byrdhouse Logo" />
            <div>Made with 💜 🐶 🐣 ☕ around the world</div>
          </div>
          <div className="FooterLinks">
            <div>
              <a
                href="https://byrdhouse.notion.site/Byrdhouse-Onboarding-4b2712d0cf124e9c95fd37893592d3e7"
                target="_blank"
                rel="noreferrer"
              >
                How Byrdhouse Works
              </a>
              <span onClick={goToOurStory}>About</span>
              <a
                href="https://byrdhouseapp.com/blog/"
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  amplitude?.logEvent(AmplitudeEvent.CLICK_ON_BLOG_FOOTER);
                }}
              >
                Blog
              </a>
              <span onClick={goToPricing}>Pricing</span>
            </div>
            <div>
              <span onClick={goToFAQ}>FAQ</span>
              <a
                href="https://twitter.com/Byrdhouse_app"
                target="_blank"
                rel="noreferrer"
              >
                Twitter
              </a>
              <a
                href="https://www.linkedin.com/company/byrdhouse"
                target="_blank"
                rel="noreferrer"
              >
                Linkedin
              </a>
              <span onClick={toggleSupportModal}>Support</span>
            </div>
          </div>
        </div>
        <div className="FooterLegal">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://byrdhouse.notion.site/byrdhouse/Terms-of-Service-7d7aa2e356ef4f22bec82a8d885fdc4d"
          >
            TERMS OF SERVICE
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://byrdhouse.notion.site/byrdhouse/Privacy-Policy-c71b7c579187429da97f8aec36b1585e"
          >
            PRIVACY POLICY
          </a>
          <div>© 2023 BYRDHOUSE INC</div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
