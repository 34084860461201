import { useCallback, useContext } from "react";
import { GlobalContext } from "../context";
import api from "../api";
import useUser from "./user";

const useAccount = () => {
  const context = useContext(GlobalContext);
  return [context.account, context.setAccount] as const;
};

export const useGetAccount = () => {
  const [user] = useUser();
  const [, setAccount] = useAccount();
  return useCallback(async () => {
    if (user) {
      setAccount(await api.getAccount({ id: user.id }));
    }
  }, [user, setAccount]);
};

export default useAccount;
