import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Button from "../components/Button";
import "./PostMeeting.css";
import booksSticker from "../assets/stickers/books.png";
import api from "../api";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { LocalStorageKeys } from "../types";
import {
  AmplitudeEvent,
  MAX_FEEDBACK_INPUT_LEN,
  RecordingID,
  RoomID,
} from "byrdhouse-types";
import amplitude from "../amplitude";

const PostMeeting = () => {
  const [rating, setRating] = useState(0);
  const onStarHover = useCallback((rating: number) => {
    setRating(rating);
  }, []);
  const Stars = () => {
    let stars: string[] = rating.toString().split(".");
    let numWholeStars = Number(stars[0]);

    let wholeStar = Array.from(Array(numWholeStars), (_, index) => (
      <AiFillStar
        className="FilledStar"
        onMouseEnter={() => onStarHover(index + 1)}
        key={index}
      />
    ));

    let emptyStars = Array.from(Array(5 - numWholeStars), (_, index) => (
      <AiOutlineStar
        onMouseEnter={() => onStarHover(index + 1 + numWholeStars)}
        key={index}
      />
    ));

    return (
      <div className="FeedbackStars">
        {wholeStar}
        {emptyStars}
      </div>
    );
  };

  // Survey api call
  const [disableSendFeedback, setDisableSendFeedback] = useState(true);
  const [mainBenefit, setMainBenefit] = useState("");
  const inputMainBenefit = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setMainBenefit(event.currentTarget.value);
    },
    []
  );
  const [howToImprove, setHowToImprove] = useState("");
  const inputHowToImprove = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setHowToImprove(event.currentTarget.value);
    },
    []
  );

  useEffect(() => {
    setDisableSendFeedback(rating <= 0 || !howToImprove.length);
  }, [rating, howToImprove.length]);

  const params = useParams();
  const sendFeedback = useCallback(async () => {
    amplitude?.logEvent(AmplitudeEvent.CLICK_ON_SEND_FEEDBACK);
    if (!params.room) {
      return;
    }
    await api.sendFeedback({
      room: params.room as RoomID,
      stars: rating,
      mainBenefit,
      howToImprove,
    });
    setRating(0);
    setMainBenefit("");
    setHowToImprove("");
    toast(
      <div className="ByrdhouseToast FeedbackToast">
        <div>{"Feedback Submitted!"}</div>
        <div>{"Thank you!"}</div>
      </div>
    );
  }, [params.room, rating, mainBenefit, howToImprove]);

  // Referral email api call
  const [disableSendReferralEmail, setDisableSendReferralEmail] =
    useState(true);
  const [referralEmail, setReferralEmail] = useState("");
  const inputReferralEmail = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setReferralEmail(event.currentTarget.value);
    },
    []
  );

  useEffect(() => {
    setDisableSendReferralEmail(!referralEmail.length);
  }, [referralEmail.length]);

  const sendReferralEmail = useCallback(async () => {
    amplitude?.logEvent(AmplitudeEvent.CLICK_ON_SEND_REFERRAL);
    await api.sendReferral({ referEmail: referralEmail });
    setReferralEmail("");
    toast(
      <div className="ByrdhouseToast FeedbackToast">
        <div>{"Referral Sent!"}</div>
        <div>{"Thank you!"}</div>
      </div>
    );
  }, [referralEmail]);

  const showRecordingButton = !!localStorage.getItem(
    LocalStorageKeys.SHOW_RECORDING_BUTTON
  );

  const [recording, setRecording] = useState<RecordingID | undefined>(
    undefined
  );
  const getLatestRecording = useCallback(async () => {
    try {
      if (params.room && showRecordingButton) {
        const response = await api.getLatestRecording({
          id: params.room as RoomID,
        });
        setRecording(response.recording);
      }
    } finally {
      localStorage.removeItem(LocalStorageKeys.SHOW_RECORDING_BUTTON);
    }
  }, [params.room, showRecordingButton]);
  useEffect(() => {
    getLatestRecording();
  }, [getLatestRecording]);

  const navigate = useNavigate();

  return (
    <div className="PostMeeting">
      <div>
        <img src={booksSticker} alt="Books" />
        <h1>You left the nest.</h1>
        {recording && (
          <Button
            text="View Recording"
            background="gradient"
            onClick={() => {
              navigate(`/recordings/${recording}`);
            }}
          />
        )}
      </div>
      <div>
        <div className="FeedbackPanel">
          <h2>{"How was your experience?"}</h2>
          <Stars />
          <div className="FeedbackPanelInput">
            <div className="FeedbackInput">
              <p>
                {
                  "What is the main benefit you receive from Byrdhouse? (Optional)"
                }
              </p>
              <input
                value={mainBenefit}
                onChange={inputMainBenefit}
                placeholder="Answer"
                type="text"
                maxLength={MAX_FEEDBACK_INPUT_LEN}
              />
            </div>
            <div className="FeedbackInput">
              <p>{"Tell us how we can improve"}</p>
              <input
                value={howToImprove}
                onChange={inputHowToImprove}
                placeholder="Answer"
                type="text"
                maxLength={MAX_FEEDBACK_INPUT_LEN}
              />
            </div>
            <Button
              disabled={disableSendFeedback}
              text="Submit Feedback"
              onClick={sendFeedback}
            />
          </div>
        </div>
        <div className="FeedbackPanel">
          <div className="FeedbackPanelInput">
            <h2>{"Refer us and get a month FREE 🎉"}</h2>
            <div className="FeedbackInput">
              <p>{"Email"}</p>
              <input
                value={referralEmail}
                onChange={inputReferralEmail}
                placeholder="Enter emails of recipients for referrals"
                type="text"
                maxLength={MAX_FEEDBACK_INPUT_LEN}
              />
            </div>
            <p className="ReferralDisclaimer">
              {
                "*share Byrdhouse with a friend to get a month free when they join and become a Pro user"
              }
            </p>
            <Button
              disabled={disableSendReferralEmail}
              text="Send Referral Request"
              onClick={sendReferralEmail}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostMeeting;
