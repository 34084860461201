import {
  AmplitudeEvent,
  Caption,
  CaptionID,
  IUser,
  RecordingID,
  UserID,
} from "byrdhouse-types";
import { useCallback, useContext, useMemo } from "react";
import { GlobalContext } from "../context";
import amplitude from "../amplitude";
import { PanelVisibilityState } from "../types";
import useChat from "./chat";
import { isSafari } from "react-device-detect";
import api from "../api";
import { useNavigate } from "react-router-dom";

const useLayout = () => {
  let context = useContext(GlobalContext);
  return [context.layout, context.setLayout] as const;
};

export const useAtLeastTwoPanelsEnabled = () => {
  const [layout] = useLayout();
  return useMemo(() => {
    return (
      [layout.panels.speaker.visible, layout.panels.transcript.visible].filter(
        (visible) => visible
      ).length > 1
    );
  }, [layout]);
};

export const useToggleCaptions = () => {
  const [, setLayout] = useLayout();
  const atLeastTwoPanelsEnabled = useAtLeastTwoPanelsEnabled();
  return useCallback(() => {
    setLayout((prevState) => {
      const visible = atLeastTwoPanelsEnabled
        ? !prevState.panels.transcript.visible
        : true;
      amplitude?.logEvent(AmplitudeEvent.TOGGLE_CAPTIONS_VISIBLE, {
        visible,
      });
      return {
        ...prevState,
        panels: {
          ...prevState.panels,
          transcript: {
            ...prevState.panels.transcript,
            visible,
          },
        },
      };
    });
  }, [setLayout, atLeastTwoPanelsEnabled]);
};

export const useCloseModal = () => {
  const [, setLayout] = useLayout();
  return useCallback(() => {
    setLayout((prevState) => ({
      ...prevState,
      modal: undefined,
      modalParams: undefined,
    }));
  }, [setLayout]);
};

export const useToggleInviteModal = () => {
  const [, setLayout] = useLayout();
  return useCallback(() => {
    setLayout((prevState) => ({
      ...prevState,
      modal: !prevState.modal ? "invite" : undefined,
    }));
  }, [setLayout]);
};

export const useToggleBuyMoreModal = () => {
  const [, setLayout] = useLayout();
  return useCallback(() => {
    amplitude?.logEvent(AmplitudeEvent.CLICK_ON_UPGRADE);
    setLayout((prevState) => ({
      ...prevState,
      modal: !prevState.modal ? "buy-more" : undefined,
    }));
  }, [setLayout]);
};

export const useToggleSettingsModal = () => {
  const [, setLayout] = useLayout();
  return useCallback(() => {
    setLayout((prevState) => ({
      ...prevState,
      modal: !prevState.modal ? "settings" : undefined,
    }));
  }, [setLayout]);
};

export const useOpenConfirmLeaveCall = () => {
  const [, setLayout] = useLayout();
  return useCallback(() => {
    setLayout((prevState) => ({
      ...prevState,
      modal: "confirm-leave-meeting",
    }));
  }, [setLayout]);
};

export const useToggleShareRecordingModal = () => {
  const [, setLayout] = useLayout();
  return useCallback(
    (recordingId: RecordingID, members: IUser[]) => {
      setLayout((prevState) => ({
        ...prevState,
        modal: !prevState.modal ? "share-recording" : undefined,
        modalParams: !prevState.modal ? { recordingId, members } : undefined,
      }));
    },
    [setLayout]
  );
};

export const useToggleConfirmRecordingActionModal = () => {
  const [, setLayout] = useLayout();
  return useCallback(() => {
    setLayout((prevState) => ({
      ...prevState,
      modal: !prevState.modal ? "confirm-recording-action" : undefined,
    }));
  }, [setLayout]);
};

export const useToggleDownloadTranscriptModal = (
  users?: { [id: UserID]: IUser },
  languages?: string[],
  captions?: Caption[]
) => {
  const [, setLayout] = useLayout();
  return useCallback(() => {
    setLayout((prevState) => ({
      ...prevState,
      modal: !prevState.modal ? "download-transcript" : undefined,
      modalParams: !prevState.modal
        ? { users, languages, captions }
        : undefined,
    }));
  }, [setLayout, users, languages, captions]);
};

export const useToggleDubModal = () => {
  const [, setLayout] = useLayout();
  return useCallback(() => {
    setLayout((prevState) => ({
      ...prevState,
      modal: !prevState.modal ? "dub" : undefined,
    }));
  }, [setLayout]);
};

export const useToggleInviteMemberModal = () => {
  const [, setLayout] = useLayout();
  return useCallback(() => {
    setLayout((prevState) => ({
      ...prevState,
      modal: !prevState.modal ? "invite-member" : undefined,
    }));
  }, [setLayout]);
};

export const useToggleChat = () => {
  const [, setLayout] = useLayout();
  const [, setChat] = useChat();
  return useCallback(() => {
    amplitude?.logEvent(AmplitudeEvent.TOGGLE_TEXT_CHAT);
    setLayout((prevState) => ({
      ...prevState,
      panels: {
        ...prevState.panels,
        chat: {
          visible: !prevState.panels.chat.visible,
          visibility: PanelVisibilityState.FLOATING,
        },
      },
    }));
    setChat((prevState) => ({
      ...prevState,
      numUnreadMessages: 0,
    }));
  }, [setLayout, setChat]);
};

export const useToggleMobileLanguageSelect = () => {
  const [, setLayout] = useLayout();
  return useCallback(() => {
    setLayout((prevState) => ({
      ...prevState,
      showMobileLanguageSelect: !prevState.showMobileLanguageSelect,
    }));
  }, [setLayout]);
};

export const useToggleReportCaptionModal = () => {
  const [, setLayout] = useLayout();
  return useCallback(
    (captionId: CaptionID) => {
      setLayout((prevState) => ({
        ...prevState,
        modal: !prevState.modal ? "report-caption" : undefined,
        modalParams: !prevState.modal ? { captionId } : undefined,
      }));
    },
    [setLayout]
  );
};

export const useToggleLoginModal = () => {
  const [, setLayout] = useLayout();
  return useCallback(() => {
    setLayout((prevState) => ({
      ...prevState,
      modal: !prevState.modal ? "login" : undefined,
    }));
  }, [setLayout]);
};

export const useOpenSignup = () => {
  const navigate = useNavigate();
  return useCallback(() => {
    navigate("/signup");
  }, [navigate]);
};

export const useToggleSupportModal = (managePlan?: boolean) => {
  const [, setLayout] = useLayout();
  return useCallback(() => {
    setLayout((prevState) => ({
      ...prevState,
      modal: !prevState.modal ? "support" : undefined,
      modalParams: !prevState.modalParams
        ? { managePlan: managePlan }
        : undefined,
    }));
  }, [setLayout, managePlan]);
};

export const useToggleConfirmRemoveMemberModal = (email: string) => {
  const [, setLayout] = useLayout();
  return useCallback(() => {
    setLayout((prevState) => ({
      ...prevState,
      modal: !prevState.modal ? "confirm-remove-member" : undefined,
      modalParams: !prevState.modal ? { email } : undefined,
    }));
  }, [setLayout, email]);
};

export const useOpenGetADemo = () => {
  return useCallback(() => {
    window.open("https://calendly.com/snow-huo-byrdhouse/30min", "_blank");
  }, []);
};

export const useOpenAddMinutes = () => {
  return useCallback(async () => {
    const response = await api.purchaseMinutes();
    if (response.redirectStripeUrl) {
      window.location.assign(response.redirectStripeUrl);
    }
  }, []);
};

export const usePipSupported = () => {
  return useMemo(() => {
    return document.pictureInPictureEnabled && !isSafari;
  }, []);
};

export const useTogglePipOn = () => {
  const pipSupported = usePipSupported();
  const [, setLayout] = useLayout();
  return useCallback(() => {
    if (pipSupported) {
      setLayout((prevState) => ({
        ...prevState,
        pipActive: true,
      }));
    }
  }, [pipSupported, setLayout]);
};

export const useTogglePipOff = () => {
  const [, setLayout] = useLayout();
  return useCallback(() => {
    setLayout((prevState) => ({
      ...prevState,
      pipActive: false,
    }));
  }, [setLayout]);
};

export const useToggleTranscript = () => {
  const [, setLayout] = useLayout();
  return useCallback(() => {
    setLayout((prevState) => ({
      ...prevState,
      panels: {
        ...prevState.panels,
        transcript: {
          ...prevState.panels.transcript,
          summaryActive: false,
        },
      },
    }));
  }, [setLayout]);
};

export const useToggleSummary = () => {
  const [, setLayout] = useLayout();
  return useCallback(() => {
    amplitude?.logEvent(AmplitudeEvent.CLICK_ON_TOGGLE_AI_MAGICIAN);
    setLayout((prevState) => ({
      ...prevState,
      panels: {
        ...prevState.panels,
        transcript: {
          ...prevState.panels.transcript,
          summaryActive: true,
        },
      },
    }));
  }, [setLayout]);
};

export const useToggleJoinNestModal = (create: boolean) => {
  const [, setLayout] = useLayout();
  return useCallback(() => {
    setLayout((prevState) => ({
      ...prevState,
      modal: !prevState.modal ? "join-nest" : undefined,
      modalParams: !prevState.modal ? { create } : undefined,
    }));
  }, [create, setLayout]);
};

export default useLayout;
