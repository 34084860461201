import "./JoinNestModal.css";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import { useCloseModal } from "../../hooks/layout";
import api from "../../api";
import amplitude from "../../amplitude";
import { AmplitudeEvent } from "byrdhouse-types";

type JoinNestModalProps = {
  create: boolean;
};

function JoinNestModal(props: JoinNestModalProps) {
  useEffect(() => {
    if (props.create) {
      amplitude?.logEvent(AmplitudeEvent.OPEN_BUILD_NEST);
    } else {
      amplitude?.logEvent(AmplitudeEvent.OPEN_JOIN_NEST_MODAL);
    }
  }, [props.create]);

  const [link, setLink] = useState("");

  const createRoom = useCallback(async () => {
    const response = await api.createRoom();
    amplitude?.logEvent(AmplitudeEvent.CLICK_ON_CREATE_NEST, {
      roomId: response.id,
    });
    const url = new URL(`/meeting/${response.id}`, window.location.href);
    setLink(url.toString());
  }, []);

  useEffect(() => {
    if (props.create) {
      createRoom();
    }
  }, [props.create, createRoom]);

  const onLinkChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setLink(event.target.value);
  }, []);

  const [linkCoped, setLinkCopied] = useState<boolean>(false);

  const copyLink = useCallback(() => {
    navigator.clipboard.writeText(link);
    setLinkCopied(true);
    setTimeout(() => {
      setLinkCopied(false);
    }, 2000);
  }, [link]);

  const navigate = useNavigate();
  const closeModal = useCloseModal();
  const joinLink = useCallback(() => {
    amplitude?.logEvent(AmplitudeEvent.CLICK_ON_JOIN_NEST, {
      link: link,
    });
    if (link.includes("/")) {
      const meetingId = link.split("/").pop();
      navigate("/meeting/" + meetingId);
    } else {
      navigate("/meeting/" + link);
    }
    closeModal();
  }, [link, navigate, closeModal]);

  return (
    <div className="JoinNestModal">
      <div className="LinkWrapper">
        <div>
          <div className="Label">{`${
            props.create ? "Build" : "Join"
          } nest with link`}</div>
          <input
            type="text"
            className="Link ShareLink"
            placeholder="Paste a link"
            readOnly={props.create}
            onChange={!props.create ? onLinkChanged : undefined}
            value={link}
          />
        </div>
        {props.create && (
          <button className="CopyLink ShareLink" onClick={copyLink}>
            {linkCoped ? "Link copied!" : "Copy link"}
          </button>
        )}
      </div>
      <Button text="Join now" background="gradient" onClick={joinLink} />
    </div>
  );
}

export default JoinNestModal;
