import "./Recordings.css";
import api from "../api";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import {
  AmplitudeEvent,
  GetRecordingsListResponse,
  getRecordingTitle,
} from "byrdhouse-types";
import { IoSearch } from "react-icons/io5";
import Button from "../components/Button";
import React from "react";
import cupSticker from "../assets/stickers/cup.png";
import Loader from "../components/Loader";
import useLayout, { useToggleShareRecordingModal } from "../hooks/layout";
import { useNavigate } from "react-router";
import amplitude from "../amplitude";
import { AiFillLock, AiFillUnlock } from "react-icons/ai";

type RecordingListItemProps = {
  recording: GetRecordingsListResponse["recordings"][0];
};
const RecordingListItem = (props: RecordingListItemProps) => {
  const toggleShareRecordingModal = useToggleShareRecordingModal();
  const clickOpenShareRecordingModal = useCallback(() => {
    toggleShareRecordingModal(props.recording.id, props.recording.members);
  }, [props.recording, toggleShareRecordingModal]);
  const navigate = useNavigate();
  const viewNotes = useCallback(() => {
    navigate(`/recordings/${props.recording.id}`);
  }, [navigate, props.recording.id]);

  return (
    <div key={props.recording.id} className="RecordingListItem">
      <div className="RecordingListItemText">
        <div>
          {getRecordingTitle(props.recording.timestamp, props.recording.host)}
        </div>
        <div>
          {props.recording.members
            .map((member) => member.displayName)
            .join(", ")}
        </div>
      </div>
      <div className="RecordingListItemButtons">
        <Button text="View Recording" border="pink" onClick={viewNotes} />
        <Button
          text={
            <div className="ShareRecording">
              {props.recording.public ? <AiFillUnlock /> : <AiFillLock />}
              Share
            </div>
          }
          onClick={clickOpenShareRecordingModal}
        />
      </div>
    </div>
  );
};

const Recordings = () => {
  const [loading, setLoading] = useState(true);

  const [availableRecordings, setAvailableRecordings] =
    useState<GetRecordingsListResponse["recordings"]>();

  const [visibleRecordings, setVisibleRecordings] =
    useState<GetRecordingsListResponse["recordings"]>();

  const [query, setQuery] = useState<string>("");

  const getRecordingsList = useCallback(async () => {
    try {
      let availableRecordings = await api.getRecordingsList();
      availableRecordings.recordings = availableRecordings.recordings.sort(
        (a, b) => {
          return b.timestamp.getTime() - a.timestamp.getTime();
        }
      );
      setAvailableRecordings(availableRecordings.recordings);
      setVisibleRecordings(availableRecordings.recordings);
    } finally {
      setLoading(false);
    }
  }, []);

  const search = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    amplitude?.logEvent(AmplitudeEvent.SEARCH_RECORDINGS, {
      query: event.target.value,
    });
    setQuery(event.target.value);
  }, []);

  const dateIsInLastWeek = (date: Date) => {
    const WEEK_IN_MILLISECONDS = 1000 * 60 * 60 * 24 * 7;
    let now = new Date();
    now.setTime(now.getTime() - WEEK_IN_MILLISECONDS);
    return date.getTime() > now.getTime();
  };

  // User loads page or recording visibility modified
  const [layout] = useLayout();
  useEffect(() => {
    getRecordingsList();
  }, [getRecordingsList, layout.recordingsUpdate]);

  useEffect(() => {
    if (query && availableRecordings) {
      const matched = availableRecordings.filter((recording) =>
        (
          getRecordingTitle(recording.timestamp, recording.host) +
          recording.members.map((member) => member.displayName).join("")
        )
          .toLowerCase()
          .includes(query.toLowerCase())
      );

      setVisibleRecordings(matched);
    } else {
      setVisibleRecordings(availableRecordings);
    }
  }, [query, availableRecordings]);

  let content = (
    <React.Fragment>
      <div className="NoRecordings">
        <img src={cupSticker} alt="Coffee Cup" />
        <div>Sorry, you don't have any meeting notes yet.</div>
        <div>
          Please build a nest or join a nest to get your first meeting notes and
          recordings!
        </div>
      </div>
    </React.Fragment>
  );

  if (loading) {
    content = (
      <React.Fragment>
        <Loader size={30} />
      </React.Fragment>
    );
  }

  const recordingsLessThan7Days = useMemo(() => {
    return visibleRecordings
      ?.filter((recording) => dateIsInLastWeek(recording.timestamp))
      .map((recording, index) => (
        <RecordingListItem key={index} recording={recording} />
      ));
  }, [visibleRecordings]);

  const recordingsMoreThan7Days = useMemo(() => {
    return visibleRecordings
      ?.filter((recording) => !dateIsInLastWeek(recording.timestamp))
      .map((recording, index) => (
        <RecordingListItem key={index} recording={recording} />
      ));
  }, [visibleRecordings]);

  if (visibleRecordings && visibleRecordings.length) {
    content = (
      <React.Fragment>
        {!!recordingsLessThan7Days?.length && (
          <div className="RecordingsSection">
            <div>Less than 7 days ago</div>
            <div>{recordingsLessThan7Days}</div>
          </div>
        )}
        {!!recordingsMoreThan7Days?.length && (
          <div className="RecordingsSection">
            <div>More than 7 days ago</div>
            <div>{recordingsMoreThan7Days}</div>
          </div>
        )}
      </React.Fragment>
    );
  }

  return (
    <div className="Recordings">
      <React.Fragment>
        <div className="SearchRecordings">
          <IoSearch />
          <input
            type="text"
            placeholder="Search recordings"
            value={query}
            onChange={search}
          />
        </div>
        {content}
      </React.Fragment>
    </div>
  );
};

export default Recordings;
