import "./CustomerTestimonies.css";
import logo from "../assets/logo.svg";
import img1 from "../assets/testimonies/1.jpeg";
import img2 from "../assets/testimonies/2.jpeg";
import img3 from "../assets/testimonies/3.jpeg";
import img4 from "../assets/testimonies/4.avif";
import img5 from "../assets/testimonies/5.avif";
import img6 from "../assets/testimonies/6.avif";
import img7 from "../assets/testimonies/7.avif";
import img8 from "../assets/testimonies/8.jpeg";
import img9 from "../assets/testimonies/9.jpeg";
import img10 from "../assets/testimonies/10.jpeg";
import img11 from "../assets/testimonies/11.jpeg";
import img12 from "../assets/testimonies/12.jpeg";
import img13 from "../assets/testimonies/13.jpeg";
import img14 from "../assets/testimonies/14.jpeg";
import img15 from "../assets/testimonies/15.jpeg";
import img16 from "../assets/testimonies/16.jpg";
import img17 from "../assets/testimonies/17.jpeg";
import img18 from "../assets/testimonies/18.jpeg";
import img19 from "../assets/testimonies/19.jpeg";
import img20 from "../assets/testimonies/20.jpeg";

type CustomerTestimony = {
  image: string;
  occupation: string;
  quote: string;
};

export const testimonies: CustomerTestimony[] = [
  {
    image: img1,
    occupation: "CEO @ TRACON",
    quote:
      "We work with the Byrdhouse team to improve communication with our global partners.  The translation speed is incredibly fast and promotes greater understanding between meeting participants, and we also enjoy the immediate availability of the meeting transcript. I am amazed by the breadth of languages covered and highly recommend the platform.",
  },
  {
    image: img2,
    occupation: "Director, Clinical Operations @ Promedica International",
    quote:
      "Byrdhouse opens up the possibility for us to virtually 'see' each other even though we are far apart and speak different languages, and by understanding the genuine sentiment behind the translated words, we build a deeper level of trust and working relationship with our international partners. Utilizing Byrdhouse's meeting notes and recording features is a time-saver, freeing up our team to focus on more critical tasks.",
  },
  {
    image: img3,
    occupation: "Program Manager @ RE!NSTITUTE",
    quote:
      "We enjoy using Bydhouse with our team members who speak Spanish as their native language. Listening to them and seeing their full passion and expression is totally different than when they had to translate or code switch in their heads. You're getting the person's full personality when they're able to speak in their native language.",
  },
  {
    image: img4,
    occupation: "Tech Savvy Coffee Enthusiast",
    quote:
      "It's a super nice tool~ one of the greatest AI translation apps that I've ever seen before! It would be helpful for non-English speakers!",
  },
  {
    image: img16,
    occupation: "Analytics Leader @ Office Depot",
    quote:
      "The inability to communicate with my parents-in-law has been a major pain point for me ever since getting married. Byrdhouse not only resolves this issue, but delivers lasting benefits that I didn’t anticipate. Now, instead of sheepishly chiming in a few times as my wife conducts 99% of our long-distance conversations with her parents, I’m able to have full conversations with them on my own without the need for my wife to constantly pause and translate for us. In addition to being able to communicate seamlessly now, I feel closer to my wife's parents than I had ever imagined. Byrdhouse is a truly essential app for multicultural families who have the desire to improve communication and understanding of one another.",
  },
  {
    image: img5,
    occupation: "CEO @ ReVert",
    quote:
      "Byrdhouse is clutch for me, who works with multiple suppliers around the world both from my previous job and current startup. The feature I'm most excited about is the ability to share translated meeting notes after group calls. Big pain if you deal with suppliers, vendors, and partners across borders.",
  },
  {
    image: img6,
    occupation: "CTO @ Veradat",
    quote:
      'My work location has required me to learn Spanish on top of English. Learning a new language "well" is a 5-10 year process. By "well", I mean verbalizing at the speed of thought and writing at the speed of verbalizing. I\'ve always had to find a way around the language requirements of my work for example taking the help of a bilingual team member to ensure we are aligned after a standup or using halirious google translate while interviewing candidates. Using Byrdhouse was the aha moment of 2022 for me. It completely made me forget language requirements which is the seamlessness I only dreamt of!',
  },
  {
    image: img7,
    occupation: "Founder @ Glasp",
    quote:
      "This is super cool! I've been using Byrdhouse for a pretty long time and it works perfectly! Thank you for coming into my life, Byrdhouse 🙏",
  },
  {
    image: img8,
    occupation: "📖 The Future Normal 🌱 Cofounder 3Space 🔮 Trend watcher",
    quote:
      "We loved using Brydhouse for our multilingual virtual webinars and in our SXSW 2023 Future Normal Keynote speech!",
  },
  {
    image: img9,
    occupation: "Co-founder @ Soothcology",
    quote:
      "This is the best video conferencing application with multilingual support. It is crucial for teams with members speaking different languages.",
  },
  {
    image: img10,
    occupation: "Chief of Staff @ First Round Capital",
    quote:
      "As someone from a multicultural family, I am so excited about Byrdhouse and the potential it has to break barriers across borders! I've tried the app with both friends and family and find myself sharing it with those in my family who don't speak a common language or with friends who work internationally. Very excited to keep watching Byrdhouse grow!",
  },
  {
    image: img11,
    occupation:
      "Customer Experience Practice Leader @ The European House-Ambrosetti",
    quote:
      "Before Byrdhouse, our search for interpreters was a time-consuming, expensive affair. Now, not only do we save on time and costs, but we also provide an inclusive experience for diverse voices to interact seamlessly. Byrdhouse’s AI-powered translation technology enhances participants' satisfaction, solidifying our stance as pioneers in embracing AI neural machine translation for comprehensive language inclusivity as an organization.",
  },
  {
    image: img12,
    occupation: "Program Manager @ Grey Swan Guild",
    quote:
      "The implementation of Byrdhouse led to transformative outcomes for Grey Swan Guild's UNESCO i4T Community Consultation. Byrdhouse helped to foster an environment of linguistic diversity and inclusion, ensuring every participant's voice was heard, thereby maximizing the variety of solutions. By enabling a broader, more global perspective, Byrdhouse contributed significantly to the richness of discussions, the depth of problem-solving, and the diversity of the solutions created.",
  },
  {
    image: img13,
    occupation: "President @ SocialCompany.org",
    quote:
      "I have enjoyed and been extremely impressed with the accuracy and speed of Byrdhouse’s real-time translation. It's a dream-come-true service for a person like me who is not a native English speaker, and those who love to communicate with people worldwide!",
  },
  {
    image: img14,
    occupation: "Co-founder @ Forward Labs",
    quote:
      "Byrdhouse is awesome! I have been working with people around the world (in a remote-first company) so I can't stress enough the importance of understanding each other's language & culture.",
  },
  {
    image: img15,
    occupation: "Sales Manager - Enterprise Clients @ LinkedIn",
    quote:
      "I never imagined that I could speak Mandarin perfectly with people until Byrdhouse. I feel that I can talk to the whole world. It is magical. Byrdhouse gives people the opportunity to communicate with the world without feeling embarrassed due to language barriers. Without fear of being judged for your language or accent, you can fully express yourself in your native language and get your messages across. I'm very satisfied using Byrdhouse.",
  },
  {
    image: img17,
    occupation: "CTO @ HiLink",
    quote:
      "Byrdhouse solves a fundamental human need - communicating using different languages. With the AI-powered world, there's so much potential for Byrdhouse to facilitate genuine, human conversations.",
  },
  {
    image: img18,
    occupation: "Customer Success Manager @ Kubecost",
    quote:
      "The translation is seamless. Great UI and it made cross-language communication a breeze. Recommend!",
  },
  {
    image: img19,
    occupation: "Staff Product Designer @ Uber",
    quote:
      "Byrdhouse is a game-changer when it comes to bringing people closer together. Before Byrdhouse, there was no elegant solution for solving the language barrier in video. It doesn't matter if you're talking to remote teammates, learning English, or simply catching up with family members— Byrdhouse has got you covered. The possibilities for connection and collaboration are virtually endless, and I can't wait to see how it will impact people's lives across the globe. 🌏",
  },
  {
    image: img20,
    occupation: "Data Lead @ Google",
    quote: "Byrdhouse provides top-notch real-time translations.",
  },
];

function CustomerTestimonies() {
  return (
    <div className="CustomerTestimonies">
      {testimonies.concat(testimonies.reverse()).map((testimony) => (
        <div>
          <div className="TestimonyHeader">
            <img
              src={testimony.image ? testimony.image : logo}
              alt={testimony.occupation}
            />
            <div className="TestimonyHeaderText">
              <p>{testimony.occupation}</p>
            </div>
          </div>
          <div className="TestimonyQuote">
            <p className="TestimonyQuoteIcon">{"“"}</p>
            <p className="TestimonyQuoteText">{testimony.quote}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default CustomerTestimonies;
