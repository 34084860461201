import {
  CSSProperties,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { IoAdd, IoClose, IoRemove } from "react-icons/io5";
import useTranscript from "../hooks/transcript";
import "./Panel.css";
import { Resizable } from "react-resizable";
import { CgDockBottom } from "react-icons/cg";
import { HiOutlineArrowsPointingOut } from "react-icons/hi2";
import useLayout, { useToggleChat } from "../hooks/layout";
import { PanelVisibilityState } from "../types";
import { useWindowSize } from "react-use";
import useCall from "../hooks/call";
import amplitude from "../amplitude";
import { AmplitudeEvent } from "byrdhouse-types";
import React from "react";
import Button from "./Button";

export type PanelProps = {
  children: ReactNode;
  style?: CSSProperties;
  type: "participants" | "transcript" | "chat";
  floating: boolean;
};

const TextResize = () => {
  const [, setTranscript] = useTranscript();

  const increaseTextSize = useCallback(
    (event: SyntheticEvent<Element, Event>) => {
      event.stopPropagation();
      setTranscript((prevState) => ({
        ...prevState,
        fontSize: Math.min(prevState.fontSize + 2, 64),
      }));
    },
    [setTranscript]
  );

  const decreaseTextSize = useCallback(
    (event: SyntheticEvent<Element, Event>) => {
      event.stopPropagation();
      setTranscript((prevState) => ({
        ...prevState,
        fontSize: Math.max(prevState.fontSize - 2, 12),
      }));
    },
    [setTranscript]
  );

  return (
    <div className="TextResize">
      <IoRemove onClick={decreaseTextSize} />
      <span>Text Size</span>
      <IoAdd onClick={increaseTextSize} />
    </div>
  );
};

const Panel = (props: PanelProps) => {
  const windowSize = useWindowSize();
  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: props.type === "chat" ? windowSize.width - 600 : 200,
    y: 200,
  });

  const [size, setSize] = useState<{
    width: number;
    height: number;
  }>({
    width: 530,
    height: 550,
  });

  const [, setLayout] = useLayout();
  useEffect(() => {
    if (props.type === "transcript") {
      setLayout((prevState) => ({
        ...prevState,
        floatingTranscriptSize: {
          width: size.width,
          height: size.height,
        },
      }));
    }
  }, [props.type, setLayout, size]);

  const dragOffset = useRef<{ x: number; y: number }>({ x: 0, y: 0 });
  const drag = useCallback(
    (event: MouseEvent) => {
      // Make sure panel is in bounds of window
      setPosition(() => ({
        x: Math.min(
          Math.max(0, event.clientX - dragOffset.current.x),
          windowSize.width - size.width
        ),
        y: Math.min(
          Math.max(0, event.clientY - dragOffset.current.y),
          windowSize.height - size.height
        ),
      }));
    },
    [
      setPosition,
      dragOffset,
      windowSize.width,
      windowSize.height,
      size.width,
      size.height,
    ]
  );

  const endDrag = useCallback(() => {
    document.body.style.cursor = "auto";
    window.removeEventListener("mousemove", drag);
  }, [drag]);

  const startDrag = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();
      dragOffset.current = {
        x:
          event.clientX -
          Math.round(event.currentTarget.getBoundingClientRect().left),
        y:
          event.clientY -
          Math.round(event.currentTarget.getBoundingClientRect().top),
      };
      document.body.style.cursor = "grab";
      window.addEventListener("mousemove", drag);
      window.addEventListener("mouseup", endDrag);
    },
    [drag, endDrag]
  );

  const resize = useCallback(
    (
      event: SyntheticEvent<Element, Event>,
      dimensions: { size: { width: number; height: number } }
    ) => {
      setSize((prevState) => {
        if (
          dimensions.size.width + position.x < windowSize.width &&
          dimensions.size.height + position.y < windowSize.height
        ) {
          return {
            width: dimensions.size.width,
            height: dimensions.size.height,
          };
        }
        return prevState;
      });
    },
    [setSize, position.x, windowSize.width, position.y, windowSize.height]
  );

  const dock = useCallback(
    (event: SyntheticEvent<Element, Event>) => {
      event.stopPropagation();
      amplitude?.logEvent(AmplitudeEvent.TOGGLE_CAPTIONS_DOCK);
      setLayout((prevState) => ({
        ...prevState,
        panels: {
          ...prevState.panels,
          transcript: {
            ...prevState.panels.transcript,
            visibility: PanelVisibilityState.DOCKED,
          },
        },
      }));
    },
    [setLayout]
  );

  const float = useCallback(
    (event: SyntheticEvent<Element, Event>) => {
      event.stopPropagation();
      amplitude?.logEvent(AmplitudeEvent.TOGGLE_CAPTIONS_FLOATING);
      setLayout((prevState) => ({
        ...prevState,
        panels: {
          ...prevState.panels,
          transcript: {
            ...prevState.panels.transcript,
            visibility: PanelVisibilityState.FLOATING,
          },
        },
      }));
    },
    [setLayout]
  );

  const toggleChat = useToggleChat();

  const [call] = useCall();
  let title = "";
  switch (props.type) {
    case "participants":
      title = `Participants (${Object.values(call.remotePeers).length + 1})`;
      break;
    case "transcript":
      title = "Captions";
      break;
    case "chat":
      title = "Chat";
      break;
  }

  const isMobile = windowSize.width <= 1000;

  const warning =
    props.type === "participants" &&
    Object.values(call.remotePeers).length >= 1 &&
    !call.translationProvider.translationEnabled ? (
      <div className="Warning">
        {isMobile ? (
          <span>{"No translation seeds."}</span>
        ) : (
          <React.Fragment>
            <span>
              {
                "You and your birds have consumed all translation seeds, please get more to continue"
              }
            </span>
            <Button
              text={"Get More"}
              size="small"
              border="pink"
              onClick={() => window.open("/subscription", "_blank")?.focus()}
            />
          </React.Fragment>
        )}
      </div>
    ) : null;

  const content = (
    <div className="Panel" style={props.style}>
      <div className="PanelControls">
        {/* Panel Title */}
        <div className="PanelTitle">{title}</div>
        {/* Panel Controls */}
        <div className="PanelOptions">
          {warning}
          {(props.type === "transcript" || props.type === "chat") && (
            <TextResize />
          )}
          {props.type === "transcript" && !isMobile ? (
            props.floating ? (
              <CgDockBottom onClick={dock} />
            ) : (
              <HiOutlineArrowsPointingOut onClick={float} />
            )
          ) : null}
          {props.type === "chat" ? <IoClose onClick={toggleChat} /> : null}
        </div>
      </div>
      {props.children}
    </div>
  );

  if (props.floating) {
    return (
      <div
        className="Floating"
        style={{
          ...props.style,
          left: position.x,
          top: position.y,
          width: `${size.width}px`,
          height: `${size.height}px`,
        }}
        onMouseDown={startDrag}
      >
        <Resizable
          width={size.width}
          height={size.height}
          minConstraints={[530, 300]}
          maxConstraints={[windowSize.width - 200, windowSize.height - 200]}
          onResizeStart={(event) => {
            event.stopPropagation();
          }}
          onResize={resize}
        >
          {content}
        </Resizable>
      </div>
    );
  } else {
    return content;
  }
};

export default Panel;
