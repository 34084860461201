import { useCallback, useState } from "react";
import Button from "../Button";
import "./InviteMemberModal.css";
import api from "../../api";
import { useCloseModal } from "../../hooks/layout";
import { useGetAccount } from "../../hooks/account";
import { toast } from "react-toastify";
import { IoMail } from "react-icons/io5";

function InviteMemberModal() {
  const [email, setEmail] = useState("");
  const onChangeEmail = useCallback(
    (event: React.SyntheticEvent<HTMLInputElement>) => {
      setEmail(event.currentTarget.value);
    },
    [setEmail]
  );
  const getAccount = useGetAccount();
  const closeModal = useCloseModal();

  const inviteMember = useCallback(async () => {
    try {
      await api.inviteMember({ email: email });
      await getAccount();
      closeModal();
      toast(
        <div className="ByrdhouseToast SuccessToast">
          <div>{`Successfully invited ${email} to your account.`}</div>
        </div>
      );
    } catch {
      toast(
        <div className="ByrdhouseToast ErrorToast">
          <div>{`Error inviting ${email}, please try again.`}</div>
        </div>
      );
    }
  }, [email, getAccount, closeModal]);

  return (
    <div className="InviteMemberModal">
      <p>Invite a new member by email to join your plan.</p>
      <input
        placeholder="Email Address"
        type="text"
        value={email}
        onChange={onChangeEmail}
      />
      <Button
        text={
          <>
            <IoMail />
            <span>Send Invite</span>
          </>
        }
        background="gradient"
        onClick={inviteMember}
      />
    </div>
  );
}

export default InviteMemberModal;
