import {
  Caption,
  CaptionID,
  ITranslationReport,
  Participant,
} from "byrdhouse-types";

export type DeviceID = string;

export enum LocalStorageKeys {
  EXPECT_LOGIN = "EXPECT_LOGIN",
  AZURE_TOKEN = "AZURE_TOKEN",
  BYRDHOUSE_TOKEN = "BYRDHOUSE_TOKEN",
  SHOW_RECORDING_BUTTON = "SHOW_RECORDING_BUTTON",
  AUTO_DETECT = "AUTO_DETECT",
  VIRTUAL_BACKGROUND = "VIRTUAL_BACKGROUND",
  TEXT_SPEED = "TEXT_SPEED",
}

export type DisplayCaption = Omit<
  Caption,
  "from" | "translations" | "state"
> & {
  from: Pick<Participant, "sessionId" | "displayName">;
  text: string;
  language: string;
  corrected?: boolean;
};

export type HistoryCaption = Omit<
  ITranslationReport,
  "id" | "correctionLanguage" | "correctionLanguageText"
> & { id: CaptionID; from: Pick<Participant, "sessionId" | "displayName"> };

export enum PanelVisibilityState {
  DOCKED = "DOCKED",
  FLOATING = "FLOATING",
}

export type Panel = {
  visible: boolean;
  visibility: PanelVisibilityState;
};
