import { useEffect } from "react";
import { FiMic, FiMicOff, FiVideo, FiVideoOff } from "react-icons/fi";
import useCall, { useToggleCamera, useToggleMic } from "../hooks/call";
import useUser from "../hooks/user";
import useSocket from "../hooks/socket";
import { toast } from "react-toastify";
import React from "react";

export type MediaSelectorProps = {
  precall: boolean;
  mobile?: boolean;
};

const MediaSelector = (props: MediaSelectorProps) => {
  const [call, setCall] = useCall();
  const [, setUser] = useUser();
  const socket = useSocket();

  // On receive host commands
  // On host muted user
  useEffect(() => {
    socket?.on("mute_user", () => {
      let muted = true;
      setCall((prevState) => ({ ...prevState, micMuted: muted }));
      call.localMicrophoneTrack?.setMuted(muted);
      setUser((prevState) => (prevState ? { ...prevState, muted } : prevState));

      toast(
        <div className="ByrdhouseToast">
          <FiMicOff />
          {"Host has muted your audio"}
        </div>
      );
    });

    return () => {
      socket?.off("mute_user");
    };
  }, [socket, call.localMicrophoneTrack, setCall, setUser]);

  const toggleMic = useToggleMic();
  const toggleCamera = useToggleCamera();
  return (
    <React.Fragment>
      <div className="Icon" onClick={toggleMic}>
        {!call.localMicrophoneTrack || call.micMuted ? (
          <FiMicOff className="Toggle" />
        ) : (
          <FiMic className="Toggle" />
        )}
        {!props.precall && !props.mobile ? <div>Mic</div> : null}
      </div>
      <div className="Icon" onClick={toggleCamera}>
        {!call.localCameraTrack ||
        (call.cameraMuted && !call.cameraUnMuting) ? (
          <FiVideoOff className="Toggle" />
        ) : (
          <FiVideo className="Toggle" />
        )}
        {!props.precall && !props.mobile ? <div>Camera</div> : null}
      </div>
    </React.Fragment>
  );
};

export default MediaSelector;
